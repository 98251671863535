// Nyertes sorsjegyek kilistazasa

import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";

import useDebounce from "../../../hooks/useDebounce";
import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import TicketsStyle from "./Tickets.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import Btn from "../../../components/btn/Btn";
import DefaultInput from "../../../components/defaultinput/DefaultInput";
import TicketRow from "../../../components/ticketrow/TicketRow";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Tickets = () => {
    const {
        user,
        stores,
        msg, setMsg,
        loading, setLoading,
        homeRef,
        search, setSearch,
        showSearch, setShowSearch
    } = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [showTickets, setShowTickets] = useState([]);

    const navigate = useNavigate();

    const params = useParams();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        socket.emit("join", ["cid:"+stores.filter(s => s.id === user.store)[0].company]);

        setSearch("");
        setShowSearch("");

        getTickets();

        setLoading(false);
    }, []);

    const getTickets = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/tickets/cashout/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setTickets(data.tickets);
        setShowTickets(data.tickets);
    };

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');
            setShowTickets(tickets.filter(item => rx.test(item.name) || rx.test(item.first_name) || rx.test(item.serial_num)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [showSearch, tickets]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    useEffect(() => {
        socket.on("update_tickets", () => {
            getTickets();
        });
    }, [socket]);

    const handleCashOutTicket = () => {
        setMsg({});
        navigate("/tickets/cashout/"+params.store);
    };

    const handleDeleteRow = async(id) => {
        const res = await fetch(apiUrl+"/ticket/cashout/delete/"+id, {
            method: "DELETE"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        getTickets();

        socket.emit("cashout_tickets", {id: stores.filter(s => s.id === user.store)[0].company});

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet!"
        });
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={12}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={TicketsStyle.tickets_body}>
                                <Btn
                                    text="+ új nyertes sorsjegy kifizetése"
                                    click={handleCashOutTicket}
                                    bg="#2E89CC"
                                    color="#FFFFFF"
                                    hoverBg="#0D2646"
                                    hoverColor="#FFFFFF"
                                />

                                <DefaultInput
                                    val={search}
                                    change={(e) => setSearch(e.target.value)}
                                    ph="Keresés"
                                    width="100%"
                                />

                                <table className={TicketsStyle.ticket_table}>
                                    <thead>
                                        <tr>
                                            <th style={{textAlign: "center"}}>Profil</th>
                                            <th>Dátum</th>
                                            <th>Típus</th>
                                            <th>Sorozatszám</th>
                                            <th style={{textAlign: "center"}}>Nyereménykód</th>
                                            <th>Összeg</th>
                                            <th style={{textAlign: "center"}}>Művelet</th>
                                        </tr>
                                    </thead>
                                    {showTickets.length === 0 ?
                                        <tbody>
                                            <tr>
                                                <td
                                                    className={TicketsStyle.ticket_table_none}
                                                    colSpan={7}
                                                >
                                                    Nem található ilyen sorsjegy
                                                </td>
                                            </tr>
                                        </tbody>
                                    :
                                        <tbody>
                                            {showTickets.map(t => {
                                                return (
                                                    <TicketRow
                                                        key={t.id}
                                                        info={t}
                                                        deleteRow={handleDeleteRow}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Tickets;