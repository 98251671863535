import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import CashOutStyle from "./CashOut.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import CurrencyInput from "../../../components/currencyinput/CurrencyInput";
import Btn from "../../../components/btn/Btn";

const CashOut = () => {
    const {user, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const [cash, setCash] = useState({
        sum: "",
        comment: ""
    });

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        if(location.state?.back) {
            setCash(location.state.cash);
        }

        setLoading(false);
    }, []);

    const handleBack = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/operations/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleNext = () => {
        if(cash.sum === "" || cash.comment === "") {
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező!"
            });
        }

        setMsg({});
        navigate("/cashout/verify/"+params.store, {state: cash});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={9}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={CashOutStyle.ops_cashout_body}>
                                <CurrencyInput
                                    ph="Összeg"
                                    val={cash.sum}
                                    change={(val) => setCash(prevCash => ({...prevCash, sum: val}))}
                                    width="15%"
                                />

                                <span>MEGJEGYZÉS</span>

                                <textarea
                                    className={CashOutStyle.ops_input_comment}
                                    value={cash.comment}
                                    onChange={(e) => setCash(prevCash => ({...prevCash, comment: e.target.value}))}
                                    onKeyDown={(e) => {
                                        if(e.key === "Enter"){
                                            handleNext();
                                        }
                                    }}
                                />

                                <div className={CashOutStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Tovább"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNext}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CashOut;