import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryStyle from "../Summary.module.css";
import CurrencyText from "../../currencytext/CurrencyText";

const SummaryManTickets = ({ log, info }) => {
    const {setMsg} = useContext(DataContext);

    const [logData, setLogData] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log) {
            getLog();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/tickets/manage/log", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ids: info.itemid
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        return setLogData(data.log);
    };

    const renderOperation = () => {
        if(new Date(info.date).toLocaleString("hu-HU") === new Date(logData[0]?.bad_cashout).toLocaleString("hu-HU")) {
            return (
                <>Hibás kifizetések</>
            );
        }
        else if(new Date(info.date).toLocaleString("hu-HU") === new Date(logData[0]?.credit_date).toLocaleString("hu-HU")) {
            return (
                <>Befizetésre vár</>
            );
        }
        else if(new Date(info.date).toLocaleString("hu-HU") === new Date(logData[0]?.delivery_date).toLocaleString("hu-HU")) {
            return (
                <>Leadva SZRT</>
            );
        }
        else if(new Date(info.date).toLocaleString("hu-HU") === new Date(logData[0]?.date_of_transfer).toLocaleString("hu-HU")) {
            return (
                <>Ellenőrzés alatt</>
            );
        }
    };

    return (
        <table className={SummaryStyle.summary_table}>
            <thead>
                <tr>
                    <th
                        colSpan={2}
                        style={{paddingBottom: "32px"}}
                    >
                        {renderOperation()}
                    </th>
                    <th
                        colSpan={2}
                        style={{paddingBottom: "32px"}}
                    >
                        {new Date(info.date).toLocaleString("hu-HU")}
                    </th>
                </tr>
                <tr>
                    <th style={{padding: "10px"}}>Típus</th>
                    <th style={{padding: "10px"}}>Sorozatszám</th>
                    <th style={{padding: "10px"}}>Nyereménykód</th>
                    <th style={{padding: "10px"}}>Nyeremény érték</th>
                </tr>
            </thead>
            <tbody>
                {logData.map(t => {
                    return (
                        <tr
                            key={t.id}
                            style={{textAlign: "center"}}
                        >
                            <td style={{padding: "10px"}}>{t.name}</td>
                            <td style={{padding: "10px"}}>{t.serial_num}</td>
                            <td style={{padding: "10px"}}>{t.prize_code}</td>
                            <td style={{padding: "10px"}}>
                                <CurrencyText
                                    val={t.prize_value}
                                /> HUF
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default SummaryManTickets;