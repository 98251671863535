import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import IncomingTicketsStyle from "./IncomingTickets.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Spinner from "../../../components/spinner/Spinner";
import Feedback from "../../../components/feedback/Feedback";
import TicketItem from "../../../components/ticketitem/TicketItem";
import DefaultInput from "../../../components/defaultinput/DefaultInput";
import Btn from "../../../components/btn/Btn";

const IncomingTickets = () => {
    const {user, loading, setLoading, msg, setMsg, homeRef} = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [newTickets, setNewTickets] = useState({
        id: 0,
        type: 0,
        from: "",
        to: ""
    });
    const [incoming, setIncoming] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getTicketTypes();

        if(location.state?.back) {
            setIncoming(location.state.incoming);
        }

        setLoading(false);
    }, []);

    const getTicketTypes = async() => {
        const res = await fetch(apiUrl+"/tickets", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setTickets(data.tickets);
    };

    const handleAddRow = () => {
        if(newTickets.type === 0 || newTickets.from === "" || newTickets.to === "") {
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező!"
            });
        }

        const ticket = tickets.filter(item => item.id === Number(newTickets.type))[0];

        setIncoming(prevIncoming => [
            ...prevIncoming,
            {
                id: incoming.length,
                type: newTickets.type,
                name: ticket.name,
                from: newTickets.from,
                to: newTickets.to
            }
        ]);

        setNewTickets({
            id: 0,
            type: 0,
            from: "",
            to: ""
        });

        setMsg({
            color: "#C7FFEB",
            message: "Sor hozzáadva!"
        });
    };

    const handleDeleteItem = (item) => {
        setIncoming(prevIncoming => prevIncoming.filter(t => t !== item));
        setMsg({
            color: "#C7FFEB",
            message: "Sor törölve"
        })
    };

    const handleEditItem = (newItem) => {
        const newTickets = incoming.map(ticket => {
            if(ticket.id === newItem.id) {
                return {...newItem}
            }
            return ticket;
        });
        setIncoming(newTickets);

        setMsg({
            color: "#C7FFEB",
            message: "Sor módosítva"
        })
    };

    const handleNext = async() => {
        if(incoming.length === 0) {
            return setMsg({
                color: "#F58E8E",
                message: "Először add hozzá a sorsjegyeket"
            });
        }
        
        setMsg({});
        navigate("/tickets/incoming/verify/"+params.store, {state: incoming});
    };

    const handleBack = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/operations/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={11}
                    store={params.store}
                />

                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={IncomingTicketsStyle.ops_ticket_body}>
                                <span className={IncomingTicketsStyle.ops_ticket_text}>SORSJEGYEK HOZZÁADÁSA</span>
                            
                                <div className={IncomingTicketsStyle.ops_ticket_inputs}>
                                    <select
                                        value={newTickets.type}
                                        onChange={(e) => setNewTickets(prevTick => ({...prevTick, type: e.target.value}))}
                                    >
                                        <option value={0}>Sorsjegy típusa</option>
                                        
                                        {tickets.map(item => {
                                            return (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>

                                    <DefaultInput
                                        ph="Sorozatszám-tól"
                                        val={newTickets.from}
                                        change={(e) => {
                                            if(!isNaN(+e.target.value[e.target.value.length-1]) || e.target.value[e.target.value.length-1] === "-" || e.target.value === "") {
                                                setNewTickets(prevTick => ({...prevTick, from: e.target.value}));
                                            }
                                        }}
                                    />

                                    <DefaultInput
                                        ph="Sorozatszám-ig"
                                        val={newTickets.to}
                                        change={(e) => {
                                            if(!isNaN(+e.target.value[e.target.value.length-1]) || e.target.value[e.target.value.length-1] === "-" || e.target.value === "") {
                                                setNewTickets(prevTick => ({...prevTick, to: e.target.value}));
                                            }
                                        }}
                                        keyDown={(e) => {
                                            if(e.key === "Enter") {
                                                handleAddRow();
                                            }
                                        }}
                                    />

                                    <button onClick={handleAddRow}>+</button>
                                </div>

                                <span className={IncomingTicketsStyle.ops_ticket_text}>HOZZÁADOTT SORSJEGYEK</span>
                            
                                <table className={IncomingTicketsStyle.ops_ticket_table}>
                                    <thead>
                                        <tr>
                                            <th>Típus</th>
                                            <th>Sorozatszám-tól</th>
                                            <th>Sorozatszám-ig</th>
                                            <th>Darabszám</th>
                                            <th>Műveletek</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {incoming.length === 0 ?
                                            <tr>
                                                <td
                                                    colSpan={5}
                                                    style={{textAlign: "center", fontSize: "18px", fontWeight: "600", paddingTop: "16px"}}
                                                >
                                                    Adj hozzá sorsjegyeket
                                                </td>
                                            </tr>
                                        :
                                            incoming.map(item => {
                                                return (
                                                    <TicketItem
                                                        key={item.id}
                                                        item={item}
                                                        tickets={tickets}
                                                        onDelete={handleDeleteItem}
                                                        onEdit={handleEditItem}
                                                    />
                                                );
                                            })}
                                    </tbody>
                                </table>

                                <div className={IncomingTicketsStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Tovább"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNext}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default IncomingTickets;