import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import TrafficTicketsStyle from "../traffictickets/TrafficTickets.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import SummaryTicketTraffic from "../../../components/summary/summarytickettraffic/SummaryTicketTraffic";
import Btn from "../../../components/btn/Btn";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const TrafficTicketsVerify = () => {
    const {user, stores, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const location = useLocation();

    const [traffic] = useState(location.state);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleNext = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/tickets/traffic", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id,
                traffic: traffic
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            socket.emit("traffic_tickets", {id: store.company});
            setMsg({
                color: "#C7FFEB",
                message: "Sikeres művelet!"
            });
            navigate("/operations/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleBack = () => {
        setMsg({});
        navigate("/tickets/traffic/"+params.store, {state: {traffic: traffic, back: true}});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={13}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={TrafficTicketsStyle.ops_ticket_body}>
                                <span className={TrafficTicketsStyle.ops_ticket_question}>
                                    Biztos felvezeted az alábbi sorsjegy forgalmat?
                                </span>
                                
                                <SummaryTicketTraffic
                                    log={false}
                                    info={traffic}
                                />

                                <div className={TrafficTicketsStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Véglegesítés"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNext}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default TrafficTicketsVerify;