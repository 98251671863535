import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as XLSX from "xlsx";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import ChangeFileStyle from "./ChangeFile.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import FileUpImg from "../../../assets/body/ops_file_upload.svg";
import FileExitImg from "../../../assets/body/ops_file_exit.svg";
import SaveImg from "../../../assets/body/ops_file_save.svg";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import DailyProgress from "../../../layouts/dailyprogress/DailyProgress";

const ChangeFile = () => {
    const {user, setTraffic, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const [file, setFile] = useState({name: "Forgalmi adatok feltöltése..."});

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const inputFileRef = useRef();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setTraffic([]);

        setLoading(false);
    }, []);

    const handleFileChange = (e) => {
        if(!e.target.files[0]) {
            setFile({name: "Forgalmi adatok feltöltése..."});
            return;
        }

        setFile(e.target.files[0]);
    };

    const handleFileExit = () => {
        setFile({name: "Forgalmi adatok feltöltése..."})
        inputFileRef.current.value = null
    };

    const ExcelDateToJSDate = (serial) => {
        let utc_days  = Math.floor(serial - 25569);
        let utc_value = utc_days * 86400;                                        
        let date_info = new Date(utc_value * 1000);
     
        let fractional_day = serial - Math.floor(serial) + 0.0000001;
     
        let total_seconds = Math.floor(86400 * fractional_day);
     
        let seconds = total_seconds % 60;
     
        total_seconds -= seconds;
     
        let hours = Math.floor(total_seconds / (60 * 60));
        let minutes = Math.floor(total_seconds / 60) % 60;
     
        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds).toLocaleString("hu-HU");
    };

    const handleUpload = async() => {
        if(file.name === "Forgalmi adatok feltöltése..."){
            return setMsg({
                color: "#F58E8E",
                message: "Válassz ki egy fájlt!"
            });
        }
        
        if(file.name.split(".")[1] !== "XLS"){
            return setMsg({
                color: "#F58E8E",
                message: "A kiválasztott fájl nem Excel fájl!"
            });
        }

        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data);

        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsondata = XLSX.utils.sheet_to_json(worksheet);
        
        if(isExcelFileStructureOk(jsondata)){
            jsondata.map(row => {
                if(row.hasOwnProperty("Kassza")){
                    return setTraffic(prevFile => [
                        ...prevFile,
                        {
                            date: ExcelDateToJSDate(JSON.stringify(row["Ido"])),
                            barcode: JSON.stringify(row["Vonalkód"]),
                            item_name: JSON.stringify(row["Megnevezés"]).replaceAll('"', ""),
                            receipt_num_1: JSON.stringify(row["Kassza"]).replaceAll('"', ""),
                            receipt_num_2: JSON.stringify(row["Bizonylat"])?.replaceAll('"',""),
                            quantity: JSON.stringify(row["Mennyiség"]).replaceAll('"', "").replaceAll(" ", "").replaceAll(/[^\d.-]/g, ""),
                            unit_price: JSON.stringify(row["Egységár"]),
                            unit_value: JSON.stringify(row["Érték"]),
                            purchase_price: JSON.stringify(row["Besz.ár"]),
                            purchase_value: JSON.stringify(row["Besz.Érték"]),
                            vat_rate: JSON.stringify(row["Áfakulcs"])
                        }
                    ]);
                }
            });
        }
        else {
            return setMsg({
                color: "#F58E8E",
                message: "A kiválasztott fájl struktúrája nem megfelelő!"
            })
        }

        setMsg({});
        navigate("/changesum/"+params.store);
    };

    const isExcelFileStructureOk = (arr) => {
        let ok = true;

        arr.map((row, i) => {
            if(i !== (arr.length-1)){
                if(!row.hasOwnProperty("Ido") || !row.hasOwnProperty("Megnevezés") || 
                !row.hasOwnProperty("Kassza") || !row.hasOwnProperty("Mennyiség") || 
                !row.hasOwnProperty("Egységár") || !row.hasOwnProperty("Érték") || 
                !row.hasOwnProperty("Besz.ár") || !row.hasOwnProperty("Besz.Érték") ||
                !row.hasOwnProperty("Áfakulcs")){
                    return ok = false;
                }
            }
        });

        return ok;
    };

    const handleFastChange = () => {
        setMsg({});
        navigate("/changelogin/"+params.store, {state: {fast: true}});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={3}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={ChangeFileStyle.ops_change_body}>
                                <DailyProgress
                                    active={1}
                                />
                                
                                <span>Válaszd ki és töltsd fel az időszakra vonatkozó forgalmi adatokat.</span>

                                <div className={ChangeFileStyle.ops_change_ops}>
                                    <div className={ChangeFileStyle.ops_change_file}>
                                        <div
                                            className={ChangeFileStyle.ops_file_icon}
                                            onClick={() => inputFileRef.current.click()}
                                        >
                                            <img 
                                                src={FileUpImg}
                                                alt="Icon"
                                            />
                                            <input
                                                type="file"
                                                style={{display: "none"}}
                                                ref={inputFileRef}
                                                onChange={handleFileChange}
                                                accept="application/vnd.ms-excel"
                                            />
                                        </div>

                                        <div className={ChangeFileStyle.ops_file_text}>
                                            <span>{file.name}</span>
                                        </div>

                                        {file.name !== "Forgalmi adatok feltöltése..." ?
                                            <div
                                                className={ChangeFileStyle.ops_file_exit}
                                                onClick={handleFileExit}
                                            >
                                                <img
                                                    src={FileExitImg}
                                                    alt="Icon"
                                                />
                                            </div>
                                        :
                                            <></>
                                        }
                                    </div>

                                    <button
                                        className={ChangeFileStyle.ops_file_upload}
                                        onClick={handleUpload}
                                    >
                                        <img
                                            src={SaveImg}
                                            alt="Icon"
                                        />
                                        <span>Feltöltés</span>
                                    </button>
                                </div>

                                <span
                                    onClick={handleFastChange}
                                    style={{cursor: "pointer"}}
                                >
                                    Gyorsváltás
                                </span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ChangeFile;