import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";
import * as TrafficCalc from "../../../services/TrafficCalc";

import ChangePcsStyle from "../../change/changepcs/ChangePcs.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";
import DailyProgress from "../../../layouts/dailyprogress/DailyProgress";

import Spinner from "../../../components/spinner/Spinner";
import Btn from "../../../components/btn/Btn";
import DailyTable from "../../../components/dailytable/DailyTable";
import Feedback from "../../../components/feedback/Feedback";
import CurrencyText from "../../../components/currencytext/CurrencyText";

const ClosePcs = () => {
    const {user, pcs, traffic, stores, loading, setLoading, homeRef, msg, setMsg} = useContext(DataContext);

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleBack = () => {
        navigate("/closeinput/"+params.store, {state: {corrItems: location.state.corrItems}});
    };

    const handleNext = () => {
        if(Object.values(pcs).some(item => item === "")) {
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        navigate("/closeverify/"+params.store, {state: {corrItems: location.state.corrItems}});
    };

    const calcDiff = () => {
        const obj = location.state.corrItems;
        obj.close = 0;

        const arr = Object.values(obj).map((item, i) => ({id: i, value: Number(item)}));

        const sum = arr.reduce((acc, obj) => {
            if(obj.id === 2) {
                return acc -= obj.value; 
            }
            return acc += obj.value;
        }, 0);

        return TrafficCalc.getTrafficSum(traffic) + TrafficCalc.getTrafficCashIn(traffic) - TrafficCalc.getTrafficCashOut(traffic) - sum - pcs.sum;
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                <OpsHeader
                    operation={2}
                    store={params.store}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={ChangePcsStyle.ops_change_body}>
                                <DailyProgress
                                    close={true}
                                    active={5}
                                />

                                <div className={ChangePcsStyle.ops_change_header}>
                                    <span>Számold át a kasszát és add meg a darabszámokat.</span>

                                    <div className={ChangePcsStyle.ops_change_btns}>
                                        <Btn
                                            text="Vissza"
                                            bg="#FFFFFF"
                                            color="#7D7D7D"
                                            hoverBg="#DBDBDB"
                                            hoverColor="#7D7D7D"
                                            click={handleBack}
                                        />

                                        <Btn
                                            text="Tovább"
                                            bg="#2E89CC"
                                            color="#FFFFFF"
                                            hoverBg="#0D2646"
                                            hoverColor="#FFFFFF"
                                            click={handleNext}
                                        />
                                    </div>
                                </div>

                                <div className={ChangePcsStyle.ops_change_pcs}>
                                    <span className={ChangePcsStyle.pcs_title}>Kassza</span>

                                    <DailyTable
                                        colorcode={stores.filter(s => s.name === params.store)[0].color_code_2}
                                        bg="#FFFFFF"
                                        border="1px solid #959595"
                                        textColor="#959595"
                                        onEnter={handleNext}
                                        back={location.state?.back}
                                    />

                                    <span className={ChangePcsStyle.pcs_sum}>
                                        <CurrencyText
                                            val={pcs.sum}
                                        /> HUF
                                    </span>

                                    <span className={ChangePcsStyle.pcs_diff}>
                                        Eltérés: <CurrencyText val={calcDiff()} /> HUF
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ClosePcs;