import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";
import { getStoreStatus } from "../../services/Api";

import HomeStyle from "../home/Home.module.css";
import OperationsStyle from "../operations/Operations.module.css";

import Nav from "../../layouts/nav/Nav";
import OpsHeader from "../../layouts/opsheader/OpsHeader";
import Header from "../../layouts/header/Header";

import Feedback from "../../components/feedback/Feedback";
import Spinner from "../../components/spinner/Spinner";
import Summary from "../../components/summary/Summary";

const Log = () => {
    const {user, msg, loading, setLoading, homeRef} = useContext(DataContext);

    const location = useLocation();

    const [info] = useState(location.state);

    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={info.operation}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <Summary
                                info={info}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Log;