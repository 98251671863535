import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import NewBillPayStyle from "./NewBillPay.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import CashImg from "../../../assets/body/ops_bill_cash_1.svg";
import TransferImg from "../../../assets/body/ops_bill_transfer_1.svg";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import Btn from "../../../components/btn/Btn";

const NewBillPay = () => {
    const {user, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const [pay, setPay] = useState("");

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        if(location.state) {
            setPay(location.state);
        }

        setLoading(false);
    }, []);

    const handleBack = () => {
        navigate("/partners/"+params.store);
    };

    const handleNext = () => {
        if(pay === "") {
            return setMsg({
                color: "#F58E8E",
                message: "Válaszd ki a fizetési módot"
            });
        }

        navigate("/newbill/"+params.store+"/"+pay);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={4}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={NewBillPayStyle.ops_pay_body}>
                                <span className={NewBillPayStyle.ops_pay_text}>Válaszd ki a beérkező számla fizetésének módját</span>

                                <div className={NewBillPayStyle.ops_pay_choose}>
                                    <div
                                        className={pay === "1" ? NewBillPayStyle.cash_choosen : NewBillPayStyle.ops_pay_cash}
                                        onClick={() => setPay("1")}
                                    >
                                        <img
                                            src={CashImg}
                                            alt="Cash"
                                            style={{width: "60px", height: "60px"}}
                                        />
                                        <span>Készpénz</span>
                                    </div>

                                    <div
                                        className={pay === "2" ? NewBillPayStyle.transfer_choosen : NewBillPayStyle.ops_pay_transfer}
                                        onClick={() => setPay("2")}
                                    >
                                        <img
                                            src={TransferImg}
                                            alt="Transfer"
                                            style={{width: "60px", height: "60px"}}
                                        />
                                        <span>Átutalás</span>
                                    </div>
                                </div>

                                <div className={NewBillPayStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Mentés"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNext}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default NewBillPay;