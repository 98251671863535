const CurrencyText = ({ val, style }) => {
    const displayText = () => {
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };

    return (
        <span style={style}>{displayText()}</span>
    );
};

export default CurrencyText;