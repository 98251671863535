import { useState } from "react";

import TrafficItemStyle from "./TrafficItem.module.css";

import OkButton from "../okbutton/OkButton";
import CurrencyText from "../currencytext/CurrencyText";

const TrafficItem = ({ colorCode, value}) => {
    const [isOK, setIsOK] = useState(false);

    return (
        <div className={TrafficItemStyle.traffic_item}>
            <OkButton
                isOK={isOK}
                onOK={() => setIsOK(prevIsOK => !prevIsOK)}
            />

            <span
                className={TrafficItemStyle.traffic_value}
                style={{color: colorCode}}
            >
                <CurrencyText
                    val={value}
                    style={{color: colorCode}}
                /> <span style={{fontSize: "10px"}}>HUF</span>
            </span>
        </div>
    );
};

export default TrafficItem;