import { useEffect, useState } from "react";

import TicketManageStyle from "./TicketManage.module.css";

import ExitImg from "../../assets/body/ops_exit_white.svg";

import Profile from "../profile/Profile";
import StoreBubble from "../storebubble/StoreBubble";
import CheckBox from "../checkbox/CheckBox";
import CurrencyText from "../currencytext/CurrencyText";

const TicketManage = ({ ticket, onRemove, onChoose, onRemoveBad, initial, packed, selectAll, onFilter }) => {
    const [isOK, setIsOK] = useState(initial);

    useEffect(() => {
        if(ticket.bad_cashout) {
            setIsOK(false);
        }
    }, [ticket]);

    useEffect(() => {
        if(selectAll) {
            setIsOK(true);
        }
        else {
            setIsOK(false);
        }
    }, [selectAll]);

    const handleOK = () => {
        if(isOK) {
            onRemove(ticket);
        }
        else {
            onChoose(ticket);
        }

        setIsOK(prevOK => !prevOK);
    };

    const renderRemoveIcon = () => {
        if(!ticket.credit_date) {
            return (
                <img
                    src={ExitImg}
                    alt="Exit"
                    onClick={() => onRemoveBad(ticket)}
                />
            );
        }
        return;
    };

    const renderBadCashout = () => {
        if(packed) {
            if(ticket.bad_cashout) {
                if(Number(ticket.fault_type) === 0) {
                    return (
                        <td>
                            <div className={TicketManageStyle.badcashout}>
                                <span>Sérült sorsjegy</span>

                                {renderRemoveIcon()}
                            </div>
                        </td>
                    );
                }
                else if(Number(ticket.fault_type) === 1) {
                    return (
                        <td>
                            <div className={TicketManageStyle.badcashout}>
                                <span>Téves kifizetés</span>
                                
                                {renderRemoveIcon()}
                            </div>
                        </td>
                    );
                }
                
            }
            else {
                return (<td></td>);
            }
        }
    };

    return (
        <tr className={isOK ? TicketManageStyle.ticket_row_active : TicketManageStyle.ticket_row}>
            <td>
                <Profile
                    color={ticket.profile_color}
                    name={ticket.last_name+" "+ticket.first_name}
                    nameColor={"#939393"}
                    bubble={true}
                    click={() => onFilter(1)}
                />
            </td>

            <td>
                <StoreBubble
                    text={ticket.store}
                    colorCode={ticket.store_color}
                    click={() => onFilter(2)}
                />
            </td>

            <td style={ticket.bad_cashout ? {color: "#F6525C"} : {}}>{ticket.bad_cashout ? ticket.bad_cashout : ticket.date}</td>
            <td style={ticket.bad_cashout ? {color: "#F6525C", fontWeight: "600"} : {fontWeight: "600"}}>{ticket.type}</td>
            <td style={ticket.bad_cashout ? {color: "#F6525C"} : {}}>{ticket.serial_num}</td>
            <td style={ticket.bad_cashout ? {color: "#F6525C", textAlign: "center"} : {textAlign: "center"}}>{ticket.prize_code}</td>
            <td style={ticket.bad_cashout ? {color: "#F6525C"} : {}}>
                <CurrencyText
                    val={ticket.prize_value}
                /> HUF
            </td>
            {renderBadCashout()}
            <td>
                <CheckBox
                    isChecked={isOK}
                    onCheck={handleOK}
                    style={{margin: "0 auto"}}
                />
            </td>
        </tr>
    );
};

export default TicketManage;