import Login from "./pages/login/Login";
import Home from "./pages/home/Home";

import Operations from "./pages/operations/Operations";
import Logs from "./pages/logs/Logs";
import Log from "./pages/log/Log";

import Dayopen from "./pages/dayopen/Dayopen";
import DayopenVerify from "./pages/dayopenverify/DayopenVerify";

import ChangeFile from "./pages/change/changefile/ChangeFile";
import ChangeSum from "./pages/change/changesum/ChangeSum";
import ChangeOps from "./pages/change/changeops/ChangeOps";
import ChangeInput from "./pages/change/changeinput/ChangeInput";
import ChangePcs from "./pages/change/changepcs/ChangePcs";
import ChangeLogin from "./pages/change/changelogin/ChangeLogin";

import CloseFile from "./pages/close/closefile/CloseFile";
import CloseSum from "./pages/close/closesum/CloseSum";
import CloseOps from "./pages/close/closeops/CloseOps";
import CloseInput from "./pages/close/closeinput/CloseInput";
import ClosePcs from "./pages/close/closepcs/ClosePcs";
import CloseVerify from "./pages/close/closeverify/CloseVerify";
import CloseLogout from "./pages/close/closelogout/CloseLogout";

import Bills from "./pages/billoperations/bills/Bills";
import Partners from "./pages/billoperations/partners/Partners";
import NewPartner from "./pages/billoperations/newpartner/NewPartner";
import NewPartnerVerify from "./pages/billoperations/newpartnerverify/NewPartnerVerify";
import NewBill from "./pages/billoperations/newbill/NewBill";
import NewBillPay from "./pages/billoperations/newbillpay/NewBillPay";
import NewBillVerify from "./pages/billoperations/newbillverify/NewBillVerify";
import BillModify from "./pages/billoperations/billmodify/BillModify";

import CashoutSafe from "./pages/safe/cashoutsafe/CashoutSafe";
import CashoutSafeVerify from "./pages/safe/cashoutsafeverify/CashoutSafeVerify";
import TransitSafe from "./pages/safe/transitsafe/TransitSafe";
import TransitSafeVerify from "./pages/safe/transitsafeverify/TransitSafeVerify";
import ManageSafe from "./pages/safe/managesafe/ManageSafe";
import ManageSafeItem from "./pages/safe/managesafeitem/ManageSafeItem";
import ManageSafeVerify from "./pages/safe/managesafeverify/ManageSafeVerify";

import Tickets from "./pages/tickets/tickets/Tickets";
import IncomingTickets from "./pages/tickets/incomingtickets/IncomingTickets";
import IncomingTicketsVerify from "./pages/tickets/incomingticketsverify/IncomingTicketsVerify";
import CashoutTickets from "./pages/tickets/cashouttickets/CashoutTickets";
import CashOutTicketsVerify from "./pages/tickets/cashoutticketsverify/CashOutTicketsVerify";
import TrafficTickets from "./pages/tickets/traffictickets/TrafficTickets";
import TrafficTicketsVerify from "./pages/tickets/trafficticketsverify/TrafficTicketsVerify";
import WinnerTickets from "./pages/tickets/managetickets/winnertickets/WinnerTickets";
import ControlTickets from "./pages/tickets/managetickets/controltickets/ControlTickets";
import PackTickets from "./pages/tickets/packtickets/PackTickets";
import TicketPackage from "./pages/tickets/ticketpackage/TicketPackage";
import DeliveryTickets from "./pages/tickets/managetickets/deliverytickets/DeliveryTickets";
import WaitTickets from "./pages/tickets/managetickets/waittickets/WaitTickets";
import CreditPackTickets from "./pages/tickets/creditpacktickets/CreditPackTickets";
import CreditTickets from "./pages/tickets/managetickets/credittickets/CreditTickets";
import WrongTickets from "./pages/tickets/managetickets/wrongtickets/WrongTickets";
import TicketManage from "./pages/tickets/ticketinfo/ticketmanage/TicketManage";
import TicketStock from "./pages/tickets/ticketinfo/ticketstock/TicketStock";
import Ticket from "./pages/tickets/ticketinfo/ticket/Ticket";
import NewTicket from "./pages/tickets/ticketinfo/newticket/NewTicket";
import NewTicketPrize from "./pages/tickets/ticketinfo/newticketprize/NewTicketPrize";
import NewTicketVerify from "./pages/tickets/ticketinfo/newticketverify/NewTicketVerify";

import CashOut from "./pages/cashregister/cashout/CashOut";
import CashIn from "./pages/cashregister/cashin/CashIn";
import CashOutVerify from "./pages/cashregister/cashoutverify/CashOutVerify";
import CashInVerify from "./pages/cashregister/cashinverify/CashInVerify";

import ManageUser from "./pages/user/manageuser/ManageUser";
import CreateUser from "./pages/user/createuser/CreateUser";
import CreateUserVerify from "./pages/user/createuserverify/CreateUserVerify";
import CreateUserPerm from "./pages/user/createuserperm/CreateUserPerm";
import ModifyUser from "./pages/user/modifyuser/ModifyUser";
import ModifyUserPerm from "./pages/user/modifyuserperm/ModifyUserPerm";

import { StateProvider } from "./context/DataContext";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {
    return (
        <StateProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<><Login /></>} />
                    <Route path="/home" element={<><Home /></>} />

                    <Route path="/operations/:store" element={<><Operations /></>} />
                    <Route path="/logs/:store" element={<><Logs /></>} />
                    <Route path="/log/:store" element={<><Log /></>} />

                    <Route path="/dayopen/:store" element={<><Dayopen /></>} />
                    <Route path="/dayopen/verify/:store" element={<><DayopenVerify /></>} />

                    <Route path="/changefile/:store" element={<><ChangeFile /></>} />
                    <Route path="/changesum/:store" element={<><ChangeSum /></>} />
                    <Route path="/changeops/:store" element={<><ChangeOps /></>} />
                    <Route path="/changeinput/:store" element={<><ChangeInput /></>} />
                    <Route path="/changepcs/:store" element={<><ChangePcs /></>} />
                    <Route path="/changelogin/:store" element={<><ChangeLogin /></>} />

                    <Route path="/closefile/:store" element={<><CloseFile /></>} />
                    <Route path="/closesum/:store" element={<><CloseSum /></>} />
                    <Route path="/closeops/:store" element={<><CloseOps /></>} />
                    <Route path="/closeinput/:store" element={<><CloseInput /></>} />
                    <Route path="/closepcs/:store" element={<><ClosePcs /></>} />
                    <Route path="/closeverify/:store" element={<><CloseVerify /></>} />
                    <Route path="/closelogout/:store" element={<><CloseLogout /></>} />

                    <Route path="/bills/:store" element={<><Bills /></>} />
                    <Route path="/partners/:store" element={<><Partners /></>} />
                    <Route path="/newpartner/:store" element={<><NewPartner /></>} />
                    <Route path="/newpartner/verify/:store" element={<><NewPartnerVerify /></>} />
                    <Route path="/newbill/pay/:store" element={<><NewBillPay /></>} />
                    <Route path="/newbill/:store/:pay" element={<><NewBill /></>} />
                    <Route path="/newbill/verify/:store" element={<><NewBillVerify /></>} />
                    <Route path="/bill/modify/:store/:bill" element={<><BillModify /></>} />

                    <Route path="/safe/cashout/:store" element={<><CashoutSafe /></>} />
                    <Route path="/safe/cashout/verify/:store" element={<><CashoutSafeVerify /></>} />
                    <Route path="/safe/transit/:store" element={<><TransitSafe /></>} />
                    <Route path="/safe/transit/verify/:store" element={<><TransitSafeVerify /></>} />
                    <Route path="/safe/manage/:store" element={<><ManageSafe /></>} />
                    <Route path="/safe/manage/:store/:id" element={<><ManageSafeItem /></>} />
                    <Route path="/safe/manage/verify/:store/:id" element={<><ManageSafeVerify /></>} />

                    <Route path="/tickets/:store" element={<><Tickets /></>} />
                    <Route path="/tickets/incoming/:store" element={<><IncomingTickets /></>} />
                    <Route path="/tickets/incoming/verify/:store" element={<><IncomingTicketsVerify /></>} />
                    <Route path="/tickets/cashout/:store" element={<><CashoutTickets /></>} />
                    <Route path="/tickets/cashout/verify/:store" element={<><CashOutTicketsVerify /></>} />
                    <Route path="/tickets/traffic/:store" element={<><TrafficTickets /></>} />
                    <Route path="/tickets/traffic/verify/:store" element={<><TrafficTicketsVerify /></>} />
                    <Route path="/tickets/manage/winner/:store" element={<><WinnerTickets /></>} />
                    <Route path="/tickets/manage/control/:store" element={<><ControlTickets /></>} />
                    <Route path="/tickets/pack/:store" element={<><PackTickets /></>} />
                    <Route path="/tickets/package/:pack/:store" element={<><TicketPackage /></>} />
                    <Route path="/tickets/manage/delivery/:store" element={<><DeliveryTickets /></>} />
                    <Route path="/tickets/manage/wait/:store" element={<><WaitTickets /></>} />
                    <Route path="/tickets/credit/:store" element={<><CreditPackTickets /></>} />
                    <Route path="/tickets/manage/cashout/:store" element={<><CreditTickets /></>} />
                    <Route path="/tickets/manage/wrong/:store" element={<><WrongTickets /></>} />

                    <Route path="/ticketinfo/manage/:store" element={<><TicketManage /></>} />
                    <Route path="/ticketinfo/stock/:store" element={<><TicketStock /></>} />
                    <Route path="/ticketinfo/ticket/:store" element={<><Ticket /></>} />
                    <Route path="/ticketinfo/new/info/:store" element={<><NewTicket /></>} />
                    <Route path="/ticketinfo/new/prize/:store" element={<><NewTicketPrize /></>} />
                    <Route path="/ticketinfo/new/verify/:store" element={<><NewTicketVerify /></>} />

                    <Route path="/cashout/:store" element={<><CashOut /></>} />
                    <Route path="/cashin/:store" element={<><CashIn /></>} />
                    <Route path="/cashout/verify/:store" element={<><CashOutVerify /></>} />
                    <Route path="/cashin/verify/:store" element={<><CashInVerify /></>} />

                    <Route path="/user/manage/:store" element={<><ManageUser /></>} />
                    <Route path="/user/create/:store" element={<><CreateUser /></>} />
                    <Route path="/user/create/verify/:store" element={<><CreateUserVerify /></>} />
                    <Route path="/user/create/perm/:store" element={<><CreateUserPerm /></>} />
                    <Route path="/user/modify/:store" element={<><ModifyUser /></>} />
                    <Route path="/user/modify/perm/:store" element={<><ModifyUserPerm /></>} />
                </Routes>
            </Router>
        </StateProvider>
    );
};

export default App;