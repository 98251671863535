import { useState } from "react";

import CurrencyInputStyle from "./CurrencyInput.module.css";

const CurrencyInput = ({ style, val, change, ph, width, height }) => {
    const [value, setValue] = useState({
        displayValue: "",
        actualValue: val
    });

    const handleChange = (e) => {
        const strNumber = e.target.value.replace(/[^0-9]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");

        setValue({
            displayValue: strNumber,
            actualValue: strNumber.replace(/ /g, "")
        });

        change(strNumber.replace(/ /g, ""));
    };

    return (
        <div
            className={CurrencyInputStyle.input_container}
            style={{width: width, height: height}}
        >
            <input
                type="text"
                id={ph}
                value={value.displayValue}
                onChange={handleChange}
                style={style}
                required
            />
            
            <label htmlFor={ph}>
                {ph}
            </label>

            <div className={CurrencyInputStyle.suffix}>HUF</div>
        </div>
    );
};

export default CurrencyInput;