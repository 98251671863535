import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import SafeItemStyle from "./SafeItem.module.css";

import MoreImg from "../../assets/body/ops_more.svg";
import CurrencyText from "../currencytext/CurrencyText";

const SafeItem = ({ item }) => {
    const {setMsg} = useContext(DataContext);

    const [log, setLog] = useState({
        date: "-",
        first_name: "-",
        comment: "-"
    });

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getSafeItemLog();
    }, []);

    const getSafeItemLog = async() => {
        const res = await fetch(apiUrl+"/safe/log/"+item.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        if(data.log.length !== 0) {
            setLog({
                date: new Date(data.log[0].date).getFullYear()+". "+new Date(data.log[0].date).toLocaleString("hu-HU", { month: "2-digit" })+". "+new Date(data.log[0].date).toLocaleString("hu-HU", { day: "2-digit"})+".",
                first_name: data.log[0].first_name,
                comment: data.log[0].comment
            });
        }
    };

    const handleManage = () => {
        navigate("/safe/manage/"+params.store+"/"+item.id);
    };

    return (
        <tr className={SafeItemStyle.item_row}>
            <td>{item.serial_num}</td>
            <td>
                <CurrencyText
                    value={item.sum}
                /> HUF
            </td>
            <td>{log.date}</td>
            <td>{log.first_name}</td>
            <td>{log.comment}</td>
            <td>
                <img
                    src={MoreImg}
                    alt="More"
                    onClick={handleManage}
                />
            </td>
        </tr>
    );
};

export default SafeItem;