import OkButton from "../../okbutton/OkButton";
import ModifyFilterStyle from "./ModifyFilter.module.css";

const ModifyFilter = ({ items, onChoose }) => {
    const handleOK = (item) => {
        onChoose(item);
    };

    return (
        <div className={ModifyFilterStyle.items}>
            {items.map(item => {
                return (
                    <div
                        key={item.id}
                        className={ModifyFilterStyle.item}
                    >
                        <span>{item.name}</span>

                        <OkButton
                            isOK={item.ok}
                            onOK={() => handleOK(item)}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default ModifyFilter;