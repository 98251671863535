import OkButtonStyle from "./OkButton.module.css";

import CheckedImg from "../../assets/body/ops_checked.svg";

const OkButton = ({ isOK, onOK, style }) => {
    if(isOK) {
        return (
            <div
                className={OkButtonStyle.ok_checked}
                onClick={onOK}
                style={style}
            >
                <img
                    src={CheckedImg}
                    alt="Icon"
                />
            </div>
        );
    }
    else {
        return (
            <div
                className={OkButtonStyle.ok}
                onClick={onOK}
                style={style}
            >
                <span>OK</span>
            </div>
        );
    }
};

export default OkButton;