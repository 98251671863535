import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus, getUserCompanies } from "../../../services/Api";

import CreateUserStyle from "./CreateUser.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import ProfileImg from "../../../assets/header/profile.svg";

import Header from "../../../layouts/header/Header";
import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import UserColor from "../../../components/usercolor/UserColor";
import DefaultInput from "../../../components/defaultinput/DefaultInput";
import Btn from "../../../components/btn/Btn";

const CreateUser = () => {
    const {
        user,
        msg, setMsg,
        homeRef,
        loading, setLoading,
        profile, setProfile
    } = useContext(DataContext);

    const [colors] = useState([
        "#FF0000",
        "#00FF00",
        "#0000FF",
        "#4287F5",
        "#DDFF00",
        "#FF00E6",
        "#FF7300",
        "#00FFE1",
        "#22FF00"
    ]);
    const [ranks, setRanks] = useState([]);
    const [companies, setCompanies] = useState([]);

    const refs = {
        bank1: useRef(),
        bank2: useRef(),
        bank3: useRef()
    };

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getRanks();
        getUserCompanies(user.id).then(data => {
            setCompanies(data);
        });

        if(!location.state?.back){
            setProfile({
                color: "#FFFFFF",
                rank: 0,
                company: 0,
                lastName: "",
                firstName: "",
                nickName: "",
                birthPlace: "",
                birthDate: "",
                nameDay: "",
                zip: "",
                city: "",
                address: "",
                social: "",
                personalId: "",
                tax: "",
                bank1: "",
                bank2: "",
                bank3: "",
                email: "",
                medical: "",
                lungScreen: "",
                companyIn: ""
            });
        }

        setLoading(false);
    }, []);

    const getRanks = async() => {
        const res = await fetch(apiUrl+"/ranks", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setRanks(data.ranks.filter(r => user.rank >= r.id));
    };

    const handleColorChoose = (color) => {
        setProfile(prevProfile => ({...prevProfile, color: color}));
    };

    const handleNext = async() => {
        if(Object.values(profile).some(prop => prop === "0" || prop === "")) {
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        const res = await fetch(apiUrl+"/user/email/"+profile.email, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        if(data.user.length !== 0) {
            return setMsg({
                color: "#F58E8E",
                message: "Ez az email cím már foglalt"
            });
        }

        setMsg({});
        navigate("/user/create/perm/"+params.store);
    };

    const handleBack = () => {
        setMsg({});
        navigate("/user/manage/"+params.store, {state: {back: true}});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={15}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={CreateUserStyle.ops_create_body}>
                                <span className={CreateUserStyle.ops_create_text}>Személyes adatok</span>
                            
                                <div className={CreateUserStyle.ops_create_stats}>
                                    <div className={CreateUserStyle.ops_stats_profile}>
                                        <div
                                            className={CreateUserStyle.ops_profile_img}
                                            style={{borderColor: profile.color}}
                                        >
                                            <img
                                                src={ProfileImg}
                                                alt="Profile"
                                            />
                                        </div>

                                        <button>Feltöltés</button>

                                        <div className={CreateUserStyle.ops_profile_colors}>
                                            {colors.map((col, i) => {
                                                return (
                                                    <UserColor
                                                        key={i}
                                                        color={col}
                                                        onChoose={handleColorChoose}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className={CreateUserStyle.ops_stats_inputs}>
                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <select
                                                value={profile.rank}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, rank: e.target.value}))}
                                            >
                                                <option value={0}>Rang</option>
                                                
                                                {ranks.map(item => {
                                                    return (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>

                                            <select
                                                value={profile.company}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, company: e.target.value}))}
                                            >
                                                <option value={0}>Szervezet</option>
                                                
                                                {companies.map(item => {
                                                    return (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>

                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <DefaultInput
                                                ph="Vezetéknév"
                                                val={profile.lastName}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, lastName: e.target.value}))}
                                            />

                                            <DefaultInput
                                                ph="Keresztnév"
                                                val={profile.firstName}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, firstName: e.target.value}))}
                                            />

                                            <DefaultInput
                                                ph="Becenév"
                                                val={profile.nickName}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, nickName: e.target.value}))}
                                            />
                                        </div>

                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <DefaultInput
                                                ph="Születési hely"
                                                val={profile.birthPlace}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, birthPlace: e.target.value}))}
                                            />

                                            <DefaultInput
                                                ph="Születési idő"
                                                style={{cursor: "default"}}
                                                focus={(e) => e.target.type = "date"}
                                                blur={(e) => e.target.type = "text"}
                                                val={profile.birthDate}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, birthDate: e.target.value}))}
                                            />

                                            <DefaultInput
                                                ph="Névnap"
                                                style={{cursor: "default"}}
                                                focus={(e) => e.target.type = "date"}
                                                blur={(e) => e.target.type = "text"}
                                                val={profile.nameDay}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, nameDay: e.target.value}))}
                                            />
                                        </div>

                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <DefaultInput
                                                ph="Irányítószám"
                                                val={profile.zip}
                                                change={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setProfile(prevProfile => ({...prevProfile, zip: e.target.value}))
                                                    }
                                                }}
                                            />

                                            <DefaultInput
                                                ph="Város"
                                                val={profile.city}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, city: e.target.value}))}
                                            />

                                            <DefaultInput
                                                ph="Cím"
                                                val={profile.address}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, address: e.target.value}))}
                                            />
                                        </div>

                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <DefaultInput
                                                ph="TAJ szám"
                                                val={profile.social}
                                                change={(e) => {
                                                    if(!e.target.value || !isNaN(+e.target.value[e.target.value.length-1]) || e.target.value[e.target.value.length-1] === "-") {
                                                        setProfile(prevProfile => ({...prevProfile, social: e.target.value}));
                                                    }
                                                }}
                                            />

                                            <DefaultInput
                                                ph="Személyi igazolvány szám"
                                                val={profile.personalId}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, personalId: e.target.value}))}
                                            />

                                            <DefaultInput
                                                ph="Adószám"
                                                val={profile.tax}
                                                change={(e) => {
                                                    if(!e.target.value || !isNaN(+e.target.value[e.target.value.length-1]) || e.target.value[e.target.value.length-1] === "-") {
                                                        setProfile(prevProfile => ({...prevProfile, tax: e.target.value}));
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <DefaultInput
                                                ph="Bankszámlaszám"
                                                val={profile.bank1}
                                                inputRef={refs.bank1}
                                                change={(e) => {
                                                    if(!isNaN(+e.target.value) && e.target.value.length <= 8) {
                                                        if(e.target.value.length === 8){
                                                            refs.bank2.current.focus();
                                                        }
                                                        setProfile(prevProfile => ({...prevProfile, bank1: e.target.value}));
                                                    }
                                                }}
                                            />

                                            <DefaultInput
                                                ph="Bankszámlaszám"
                                                val={profile.bank2}
                                                inputRef={refs.bank2}
                                                change={(e) => {
                                                    if(!isNaN(+e.target.value) && e.target.value.length <= 8) {
                                                        if(e.target.value.length === 8){
                                                            refs.bank3.current.focus();
                                                        }

                                                        if(e.target.value === "") {
                                                            refs.bank1.current.focus();
                                                        }
                                                        setProfile(prevProfile => ({...prevProfile, bank2: e.target.value}));
                                                    }
                                                }}
                                            />

                                            <DefaultInput
                                                ph="Bankszámlaszám"
                                                val={profile.bank3}
                                                inputRef={refs.bank3}
                                                change={(e) => {
                                                    if(!isNaN(+e.target.value) && e.target.value.length <= 8) {
                                                        if(e.target.value === "") {
                                                            refs.bank2.current.focus();
                                                        }
                                                        setProfile(prevProfile => ({...prevProfile, bank3: e.target.value}));
                                                    }
                                                }}
                                            />
                                        </div>

                                        <DefaultInput
                                            ph="Email cím"
                                            width="100%"
                                            val={profile.email}
                                            change={(e) => setProfile(prevProfile => ({...prevProfile, email: e.target.value}))}
                                        />

                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <DefaultInput
                                                ph="Orvosi alkamassági"
                                                style={{cursor: "default"}}
                                                focus={(e) => e.target.type = "date"}
                                                blur={(e) => e.target.type = "text"}
                                                val={profile.medical}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, medical: e.target.value}))}
                                            />

                                            <DefaultInput
                                                ph="Tüdőszűrő"
                                                style={{cursor: "default"}}
                                                focus={(e) => e.target.type = "date"}
                                                blur={(e) => e.target.type = "text"}
                                                val={profile.lungScreen}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, lungScreen: e.target.value}))}
                                            />

                                            <DefaultInput
                                                ph="Munkaszerződés kezdete"
                                                style={{cursor: "default"}}
                                                focus={(e) => e.target.type = "date"}
                                                blur={(e) => e.target.type = "text"}
                                                val={profile.companyIn}
                                                change={(e) => setProfile(prevProfile => ({...prevProfile, companyIn: e.target.value}))}
                                            />
                                        </div>

                                        <div className={CreateUserStyle.btns}>
                                            <Btn
                                                text="Vissza"
                                                bg="#FFFFFF"
                                                color="#7D7D7D"
                                                hoverBg="#DBDBDB"
                                                hoverColor="#7D7D7D"
                                                click={handleBack}
                                            />

                                            <Btn
                                                text="Tovább"
                                                bg="#2E89CC"
                                                color="#FFFFFF"
                                                hoverBg="#0D2646"
                                                hoverColor="#FFFFFF"
                                                click={handleNext}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CreateUser;