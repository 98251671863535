import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";
import * as TrafficCalc from "../../../services/TrafficCalc";

import ChangeSumStyle from "./ChangeSum.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import TrafficTable from "../../../components/traffictable/TrafficTable";
import TrafficItem from "../../../components/trafficitem/TrafficItem";
import Spinner from "../../../components/spinner/Spinner";
import DailyProgress from "../../../layouts/dailyprogress/DailyProgress";
import Btn from "../../../components/btn/Btn";

const ChangeSum = () => {
    const {user, stores, traffic, loading, setLoading, homeRef} = useContext(DataContext);

    const [showTraffic, setShowTraffic] = useState([]);

    const [itemizedNav, setItemizedNav] = useState(1);

    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    useEffect(() => {
        if(itemizedNav === 1) {
            setShowTraffic(traffic);
        }
        else if(itemizedNav === 2) {
            setShowTraffic(traffic.filter(t => Number(t.vat_rate) === 5));
        }
        else if(itemizedNav === 3) {
            setShowTraffic(traffic.filter(t => Number(t.vat_rate) === 18));
        }
        else if(itemizedNav === 4) {
            setShowTraffic(traffic.filter(t => Number(t.vat_rate) === 27));
        }
        else if(itemizedNav === 5) {
            setShowTraffic(traffic.filter(t => Number(t.vat_rate) === 0 && t.barcode));
        }
        else if(itemizedNav === 6) {
            setShowTraffic([]);
        }
    }, [itemizedNav]);

    const handleBack = () => {
        navigate("/changefile/"+params.store, {state: {back: true}});
    };

    const handleNext = () => {
        navigate("/changeops/"+params.store);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                <OpsHeader
                    operation={3}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={ChangeSumStyle.ops_change_body}>
                                <DailyProgress
                                    active={2}
                                />

                                <div className={ChangeSumStyle.ops_change_header}>
                                    <span>Ellenőrizd le a forgalmi adatokat, amennyiben egyeznek hagyd jóvá. Amennyiben szükséges módosítsd</span>

                                    <div className={ChangeSumStyle.ops_change_btns}>
                                        <Btn
                                            text="Vissza"
                                            bg="#FFFFFF"
                                            color="#7D7D7D"
                                            hoverBg="#DBDBDB"
                                            hoverColor="#7D7D7D"
                                            click={handleBack}
                                        />

                                        <Btn
                                            text="Tovább"
                                            bg="#2E89CC"
                                            color="#FFFFFF"
                                            hoverBg="#0D2646"
                                            hoverColor="#FFFFFF"
                                            click={handleNext}
                                        />
                                    </div>
                                </div>

                                <div className={ChangeSumStyle.ops_change_sum}>
                                    <TrafficItem
                                        colorCode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                        value={TrafficCalc.getTrafficSum(traffic)}
                                    />

                                    <TrafficItem
                                        colorCode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                        value={TrafficCalc.getVatRateSum(traffic, 5)}
                                    />

                                    <TrafficItem
                                        colorCode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                        value={TrafficCalc.getVatRateSum(traffic, 18)}
                                    />

                                    <TrafficItem
                                        colorCode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                        value={TrafficCalc.getVatRateSum(traffic, 27)}
                                    />

                                    <TrafficItem
                                        colorCode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                        value={TrafficCalc.getVatRateSum(traffic, 0)}
                                    />

                                    <TrafficItem
                                        colorCode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                        value={0}
                                    />
                                </div>

                                <div className={ChangeSumStyle.nav_itemized}>
                                    <button
                                        onClick={() => setItemizedNav(1)}
                                        style={itemizedNav === 1 ? {borderBottom: "1px solid #959595", fontWeight: "700"} : {}}
                                    >
                                        Napi forgalom összesen
                                    </button>

                                    <button
                                        onClick={() => setItemizedNav(2)}
                                        style={itemizedNav === 2 ? {borderBottom: "1px solid #959595", fontWeight: "700"} : {}}
                                    >
                                        A - 5%-os forgalom
                                    </button>

                                    <button
                                        onClick={() => setItemizedNav(3)}
                                        style={itemizedNav === 3 ? {borderBottom: "1px solid #959595", fontWeight: "700"} : {}}
                                    >
                                        B - 18%-os forgalom
                                    </button>

                                    <button
                                        onClick={() => setItemizedNav(4)}
                                        style={itemizedNav === 4 ? {borderBottom: "1px solid #959595", fontWeight: "700"} : {}}
                                    >
                                        C 27%-os forgalom
                                    </button>

                                    <button
                                        onClick={() => setItemizedNav(5)}
                                        style={itemizedNav === 5 ? {borderBottom: "1px solid #959595", fontWeight: "700"} : {}}
                                    >
                                        D- AJT forgalom
                                    </button>

                                    <button
                                        onClick={() => setItemizedNav(6)}
                                        style={itemizedNav === 6 ? {borderBottom: "1px solid #959595", fontWeight: "700"} : {}}
                                    >
                                        E - AM forgalom
                                    </button>
                                </div>
                                
                                <div className={ChangeSumStyle.table_itemized}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Idő</th>
                                                <th>Vonalkód</th>
                                                <th>Megnevezés</th>
                                                <th>Nyugtaszám</th>
                                                <th>Bizonylatszám</th>
                                                <th>DB</th>
                                                <th>Eladási érték</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {showTraffic.map((row, i) => {
                                                return (
                                                    <TrafficTable
                                                        key={i}
                                                        row={row}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ChangeSum;