import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";

import { getUserOperations } from "../../services/Api";

import OkButton from "../okbutton/OkButton";
import OpIcon from "../opicon/OpIcon";

const UserOperation = ({ op, onChoose, initial, modify }) => {
    const {profile} = useContext(DataContext);

    const [isOK, setIsOK] = useState(initial);

    useEffect(() => {
        if(modify){
            getUserOperations(profile.id).then(data => {
                const ok = data.some(pOp => op.operation === pOp.operation);
                setIsOK(ok);
            });
        }
    }, []);

    const handleSelectOperation = () => {
        onChoose(op, !isOK);
        setIsOK(prevOK => !prevOK);
    };

    return (
        <tr>
            <td>
                <OpIcon
                    op={op}
                />
            </td>

            <td>{op.name}</td>

            <td>{op.description}</td>

            <td>{op.group_name}</td>

            <td>
                <OkButton
                    isOK={isOK}
                    onOK={handleSelectOperation}
                />
            </td>
        </tr>
    );
};

export default UserOperation;