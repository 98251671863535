// Egy muvelet adatainak mejelenitese az Operations.js fajlban

import OperationStyle from "./Operation.module.css";
import OpIcon from "../opicon/OpIcon";

const Operation = ({ oper, onChoose }) => {
    return (
        <div
            className={OperationStyle.ops_list_element}
            onClick={() => onChoose(oper)}
        >
            <OpIcon
                op={oper}
            />

            <span style={{fontWeight: "600"}}>{oper.name}</span>
            <span>{oper.description}</span>
        </div>
    );
};

export default Operation;