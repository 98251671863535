import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useDebounce from "../../../hooks/useDebounce";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import ManageUserStyle from "./ManageUser.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import User from "../../../components/user/User";
import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";

import Header from "../../../layouts/header/Header";
import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import DefaultInput from "../../../components/defaultinput/DefaultInput";

const ManageUser = () => {
    const {
        user,
        msg, setMsg,
        homeRef,
        loading, setLoading,
        search, setSearch,
        showSearch, setShowSearch
    } = useContext(DataContext);

    const [users, setUsers] = useState([]);
    const [showUsers, setShowUsers] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setSearch("");
        setShowSearch("");

        getUsers();

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');
            setShowUsers(users.filter(item => rx.test(item.last_name+" "+item.first_name) || rx.test(item.email)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [showSearch, users]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    const getUsers = async() => {
        const res = await fetch(apiUrl+"/users/"+user.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setUsers(data.users);
    };

    const handleCreateUser = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/user/create/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={14}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={ManageUserStyle.ops_manage_body}>
                                <button
                                    className={ManageUserStyle.ops_manage_btn}
                                    onClick={handleCreateUser}
                                >
                                    + új felhasználó létrehozása
                                </button>

                                <DefaultInput
                                    ph="Keresés"
                                    val={search}
                                    change={(e) => setSearch(e.target.value)}
                                    width="100%"
                                />

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Profil</th>
                                            <th>Név</th>
                                            <th>Szerződés kezdete</th>
                                            <th>Email</th>
                                            <th>Aktív</th>
                                            <th>Adatok és jogosultságok</th>
                                        </tr>
                                    </thead>
                                   <tbody>
                                        {showUsers.length === 0 ?
                                            <tr>
                                                <td colSpan={6}>A keresésnek nincs eredménye.</td>
                                            </tr> 
                                        :
                                            showUsers.map((u, i) => {
                                                return (
                                                    <User
                                                        key={i}
                                                        info={u}
                                                    />
                                                );
                                            })
                                        }
                                   </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ManageUser;