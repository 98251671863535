import SafeLogStyle from "./SafeLog.module.css";

import CurrencyText from "../currencytext/CurrencyText";

const SafeLog = ({ item }) => {
    return (
        <tr className={SafeLogStyle.log_row}>
            <td>{new Date(item.date).getFullYear()+"-"+new Date(item.date).toLocaleString("hu-HU", { month: "2-digit" })+"-"+new Date(item.date).toLocaleString("hu-HU", { day: "2-digit"})+" "+new Date(item.date).getHours()+":"+new Date(item.date).getMinutes()+":"+new Date(item.date).getSeconds({})}</td>
            <td>{item.first_name}</td>
            <td>{item.prev_serial}</td>
            <td>{item.new_serial}</td>
            <td>
                <CurrencyText
                    val={item.prev_sum}
                /> HUF
            </td>
            <td>
                <CurrencyText
                    val={item.new_sum}
                /> HUF
            </td>
            <td>{item.comment}</td>
        </tr>
    );
};

export default SafeLog;