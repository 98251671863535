import DailyProgressStyle from "./DailyProgress.module.css";

const DailyProgress = ({ active, close }) => {
    return (
        <div className={DailyProgressStyle.progress_bar}>
            <div className={active === 1 ? DailyProgressStyle.step_active : active > 1 ? DailyProgressStyle.step_actived : DailyProgressStyle.step}>
                <span>1. lépés</span>
                <span style={active === 1 ? {fontWeight: "700"} : {}}>Adatok beolvasása</span>
            </div>

            <div className={active === 2 ? DailyProgressStyle.step_active : active > 2 ? DailyProgressStyle.step_actived : DailyProgressStyle.step}>
                <span>2. lépés</span>
                <span style={active === 2 ? {fontWeight: "700"} : {}}>Forgalmi adatok</span>
            </div>

            <div className={active === 3 ? DailyProgressStyle.step_active : active > 3 ? DailyProgressStyle.step_actived : DailyProgressStyle.step}>
                <span>3. lépés</span>
                <span style={active === 3 ? {fontWeight: "700"} : {}}>Pénzforgalmak</span>
            </div>

            <div className={active === 4 ? DailyProgressStyle.step_active : active > 4 ? DailyProgressStyle.step_actived : DailyProgressStyle.step}>
                <span>4. lépés</span>
                <span style={active === 4 ? {fontWeight: "700"} : {}}>Korrekciós tételek</span>
            </div>

            <div className={active === 5 ? DailyProgressStyle.step_active : active > 5 ? DailyProgressStyle.step_actived : DailyProgressStyle.step}>
                <span>5. lépés</span>
                <span style={active === 5 ? {fontWeight: "700"} : {}}>Kassza</span>
            </div>

            <div className={active === 6 ? DailyProgressStyle.step_active : active > 6 ? DailyProgressStyle.step_actived : DailyProgressStyle.step}>
                <span>6. lépés</span>
                <span style={active === 6 ? {fontWeight: "700"} : {}}>{close ? <>Zárás</> : <>Váltás</>}</span>
            </div>
        </div>
    );
};

export default DailyProgress;