import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";

import CloseVerifyStyle from "../closeverify/CloseVerify.module.css";
import HomeStyle from "../../home/Home.module.css";

import CloseImg from "../../../assets/body/ops_dayclose.svg";

import Header from "../../../layouts/header/Header";
import Nav from "../../../layouts/nav/Nav";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";

const CloseLogout = () => {
    const {user, setUser, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const [color, setColor] = useState("");

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getCloseOpColor();

        setLoading(false);
    }, []);

    const handleLogout = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            setUser({});
            navigate("/");
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleHome = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/home");
        }, 400);
        return () => clearTimeout(timer);
    };

    const getCloseOpColor = async() => {
        const res = await fetch(apiUrl+"/operation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: 2
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setColor(data.op.color_code_1);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content}>
                <Header 
                    user={user}
                    yearColor={"#FFFFFF"}
                    monthDayColor={"#FFFFFF"}
                    nameColor={"#FFFFFF"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={CloseVerifyStyle.dayclose_content}>
                            <img
                                src={CloseImg}
                                alt="Icon"
                                style={{backgroundColor: color}}
                            />

                            <span>{user.first_name}! Köszönöm a munkádat!</span>

                            <button onClick={handleLogout}>Kijelentkezés</button>
                            <button onClick={handleHome}>Főoldal</button>
                        </div> 
                    </div>
                }
            </div>
        </div>
    );
};

export default CloseLogout;