import { useState } from "react";

import CommentImg from "../../assets/body/comment.svg"

import Bubble from "../bubble/Bubble";

const Comment = ({ text }) => {
    const [hover, setHover] = useState(false);

    return (
        <>
            <img
                src={CommentImg}
                alt="Icon"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                style={text === "" ? {opacity: "0.2"} : {}}
            />
            
            {hover && text !== "" ? 
                <Bubble
                    mode={1}
                    text={text}
                />
            :
                <></>
            }
        </>
    );
};

export default Comment;