import FilterItemStyle from "./FilterItem.module.css";

import ExitImg from "../../assets/body/ops_filter_exit.svg";
import CurrencyText from "../currencytext/CurrencyText";

const FilterItem = ({ type, text, items, onRemove, visible }) => {
    const renderItems = () => {
        if(type === "text") {
            return (
                <span>{items.join(", ")}</span>
            );
        }
        else if(type === "date") {
            return (
                <span>{items.from} - {items.to}</span>
            );
        }
        else if(type === "cash") {
            return (
                <span>
                    <CurrencyText val={items.from} /> HUF - <CurrencyText val={items.to} /> HUF
                </span>
            );
        }
    };

    if(visible) {
        return (
            <div className={FilterItemStyle.filter_item}>
                <span>
                    {text}:
                </span>
                {renderItems()}
                <img
                    src={ExitImg}
                    alt="Icon"
                    onClick={onRemove}
                />
            </div>
        );
    }
    
};

export default FilterItem;