import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";

import { DataContext } from "../../context/DataContext";

import TicketsNavStyle from "./TicketsNav.module.css";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const TicketsNav = ({ active }) => {
    const {user, stores, setMsg} = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [packs, setPacks] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        socket.emit("join", ["cid:"+stores.filter(s => s.id === user.store)[0].company]);

        getTickets();
        getPacks();
    }, []);

    useEffect(() => {
        socket.on("update_ticketnav", () => {
            getTickets();
            getPacks();
        });
    }, [socket]);

    const getTickets = async() => {
        const res = await fetch(apiUrl+"/tickets/cashout/all", {
            method: "GET"
        });
    
        const data = await res.json();
    
        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
    
        setTickets(data.tickets);
    };

    const getPacks = async() => {
        const res = await fetch(apiUrl+"/tickets/packs", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
    
        setPacks(data.packs);
    };

    const calcTicketsLength = (page) => {
        switch(page) {
            case 1:
                return tickets.filter(t => !t.date_of_transfer && !t.bad_cashout).length;
            case 2:
                return tickets.filter(t => t.date_of_transfer && !t.pack && !t.bad_cashout).length;
            case 6:
                return tickets.filter(t => t.bad_cashout).length;
            default:
                return 0;
        }
    };

    const calcPacksLength = (page) => {
        switch(page) {
            case 3:
                return packs.filter(p => p.delivery_date && !p.wait_date).length;
            case 4:
                return packs.filter(p => p.wait_date).length;
            case 5:
                return packs.filter(p => p.credit_date).length;
            default:
                return 0;
        }
    };

    const handleGoToPage = (page) => {
        setMsg({});
        if(page === 1) navigate("/tickets/manage/winner/"+params.store, {state: {back: true}});
        else if(page === 2) navigate("/tickets/manage/control/"+params.store);
        else if(page === 3) navigate("/tickets/manage/delivery/"+params.store);
        else if(page === 4) navigate("/tickets/manage/wait/"+params.store);
        else if(page === 5) navigate("/tickets/manage/cashout/"+params.store);
        else if(page === 6) navigate("/tickets/manage/wrong/"+params.store);
    };

    return (
        <div className={TicketsNavStyle.tickets_nav}>
            <button
                className={active === 1 ? TicketsNavStyle.btn_active : ""}
                onClick={() => handleGoToPage(1)}
            >
                <span>Nyertes sorsjegyek</span>
                <span>{calcTicketsLength(1)} db</span>
            </button>

            <button
                className={active === 2 ? TicketsNavStyle.btn_active : ""}
                onClick={() => handleGoToPage(2)}
            >
                <span>Ellenőrzés alatt</span>
                <span>{calcTicketsLength(2)} db</span>
            </button>

            <button
                className={active === 3 ? TicketsNavStyle.btn_active : ""}
                onClick={() => handleGoToPage(3)}
            >
                <span>Leadva SZRT</span>
                <span>{calcPacksLength(3)} csomag</span>
            </button>

            <button
                className={active === 4 ? TicketsNavStyle.btn_active : ""}
                onClick={() => handleGoToPage(4)}
            >
                <span>Befizetésre vár</span>
                <span>{calcPacksLength(4)} csomag</span>
            </button>

            <button
                className={active === 5 ? TicketsNavStyle.btn_active : ""}
                onClick={() => handleGoToPage(5)}
            >
                <span>Befizetve</span>
                <span>{calcPacksLength(5)} csomag</span>
            </button>

            <button
                className={active === 6 ? TicketsNavStyle.btn_active : ""}
                onClick={() => handleGoToPage(6)}
            >
                <span>Hibás kifizetések</span>
                <span>{calcTicketsLength(6)} db</span>
            </button>
        </div>
    );
};

export default TicketsNav