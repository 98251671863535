import DefaultInputStyle from "./DefaultInput.module.css";

const DefaultInput = ({ type, style, inputRef, val, change, ph, keyDown, focus, blur, width }) => {
    return (
        <div
            className={DefaultInputStyle.input_container}
            style={{width: width}}
        >
            <input
                id={ph}
                type={type ? type : "text"}
                style={style}
                ref={inputRef}
                value={val}
                onChange={change}
                onKeyDown={keyDown}
                onFocus={focus}
                onBlur={blur}
                required
            />
            
            <label htmlFor={ph}>
                {ph}
            </label>
        </div>
    );
};

export default DefaultInput;