import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";
import * as TrafficCalc from "../../../services/TrafficCalc";
import { isPcsEmpty } from "../../../services/Pcs";

import ManageSafeItemStyle from "./ManageSafeItem.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import PcsTable from "../../../components/pcstable/PcsTable";
import SafeLog from "../../../components/safelog/SafeLog";
import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import Btn from "../../../components/btn/Btn";

const ManageSafeItem = () => {
    const {
        user,
        stores,
        pcs, setPcs,
        msg, setMsg,
        loading, setLoading,
        homeRef
    } = useContext(DataContext);

    const params = useParams();

    const [pack, setPack] = useState({});
    const [serialNum, setSerialNum] = useState("");
    const [comment, setComment] = useState("");
    const [log, setLog] = useState([]);

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getPackage();
        getSafeItemLog();
        
        setLoading(false);
    }, []);

    const getPackage = async() => {
        const res = await fetch(apiUrl+"/safe/package/"+params.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPack(data.pack);
        setSerialNum(data.pack.serial_num);

        setPcs({
            huf5: data.pack.huf_5_pcs,
            huf10: data.pack.huf_10_pcs,
            huf20: data.pack.huf_20_pcs,
            huf50: data.pack.huf_50_pcs,
            huf100: data.pack.huf_100_pcs,
            huf200: data.pack.huf_200_pcs,
            huf500: data.pack.huf_500_pcs,
            huf1000: data.pack.huf_1000_pcs,
            huf2000: data.pack.huf_2000_pcs,
            huf5000: data.pack.huf_5000_pcs,
            huf10000: data.pack.huf_10000_pcs,
            huf20000: data.pack.huf_20000_pcs,
            sum: data.pack.sum
        });
    };

    const getSafeItemLog = async() => {
        const res = await fetch(apiUrl+"/safe/log/"+params.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        if(data.log.length !== 0) {
            setLog(data.log);
        }
    };

    const handleNext = async() => {
        if(isPcsEmpty(pcs) || serialNum === "" || comment === ""){
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        setMsg({});
        navigate("/safe/manage/verify/"+params.store+"/"+params.id, {state: {pack: pack, serialNum: serialNum, comment: comment}});
    };

    const handleBack = () => {
        setMsg({});
        navigate("/safe/manage/"+params.store, {state: {back: true}});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={8}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={ManageSafeItemStyle.ops_safe_body}>
                                <span className={ManageSafeItemStyle.ops_safe_text}>CSOMAG AZONOSÍTÓJA</span>
                                
                                <input
                                    type="text"
                                    className={ManageSafeItemStyle.ops_safe_input}
                                    value={serialNum}
                                    onChange={(e) => setSerialNum(e.target.value)}
                                />

                                <span className={ManageSafeItemStyle.ops_safe_text}>CÍMLETJEGYZÉK</span>

                                <PcsTable
                                    sum={0}
                                    colorcode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                    card={0}
                                    text={"Összeg"}
                                    initialValues={true}
                                />

                                <span className={ManageSafeItemStyle.ops_safe_text}>MEGJEGYZÉS</span>

                                <input
                                    type="text"
                                    className={ManageSafeItemStyle.ops_safe_input}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    onKeyDown={(e) => {
                                        if(e.key === "Enter") {
                                            handleNext();
                                        }
                                    }}
                                />

                                <div className={ManageSafeItemStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Tovább"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNext}
                                    />
                                </div>

                                <span className={ManageSafeItemStyle.ops_safe_text}>MÓDOSÍTÁSOK</span>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Dátum</th>
                                            <th>Felhasználó</th>
                                            <th>Előző sorozatszám</th>
                                            <th>Új sorozatszám</th>
                                            <th>Előző összeg</th>
                                            <th>Új összeg</th>
                                            <th>Megjegyzés</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {log.map((item, i) => {
                                            return (
                                                <SafeLog
                                                    key={i}
                                                    item={item}
                                                />
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ManageSafeItem;