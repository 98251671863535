import { useContext, useEffect, useRef } from "react";

import { DataContext } from "../../context/DataContext";

import FeedbackStyle from "./Feedback.module.css";

const Feedback = ({ color, message }) => {
    const {setMsg} = useContext(DataContext);

    const ref = useRef();

    useEffect(() => {
        const timer_1 = setTimeout(() => {
            ref.current.className = FeedbackStyle.feedback_out;

            const timer_2 = setTimeout(() => {
                setMsg({});
            }, 290);
            return () => clearTimeout(timer_2);

        }, 5000);
        return () => clearTimeout(timer_1);
    }, []);

    const handleCloseFeedback = () => {
        ref.current.className = FeedbackStyle.feedback_out;

        const timer_2 = setTimeout(() => {
            setMsg({});
        }, 290);
        return () => clearTimeout(timer_2);
    };

    return (
        <div
            className={FeedbackStyle.feedback}
            style={{background: color}}
            onClick={handleCloseFeedback}
            ref={ref}
        >
            <span>{message}</span>
        </div>
    );
};

export default Feedback;