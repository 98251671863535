import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";
import useDebounce from "../../../hooks/useDebounce";

import BillsStyle from "./Bills.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import Btn from "../../../components/btn/Btn";
import DefaultInput from "../../../components/defaultinput/DefaultInput";
import Bill from "../../../components/bill/Bill";

const Bills = () => {
    const {
        user,
        stores,
        msg, setMsg, 
        loading, setLoading,
        homeRef,
        search, setSearch,
        showSearch, setShowSearch
    } = useContext(DataContext);

    const [bills, setBills] = useState([]);
    const [showBills, setShowBills] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getStoreBills();

        setSearch("");
        setShowSearch("");

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');
            setShowBills(bills.filter(item => rx.test(item.name) || rx.test(item.short_name) || rx.test(item.first_name) || rx.test(item.bank_account) || rx.test(item.bill_serial_number)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [showSearch, bills]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    const getStoreBills = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/bills/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setBills(data.bills);
    };

    const handleNewBill = () => {
        navigate("/partners/"+params.store);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={4}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={BillsStyle.bills_body}>
                                <Btn
                                    text="+ új számla felvezetése"
                                    click={handleNewBill}
                                    bg="#2E89CC"
                                    color="#FFFFFF"
                                    hoverBg="#0D2646"
                                    hoverColor="#FFFFFF"
                                />

                                <DefaultInput
                                    value={search}
                                    change={(e) => setSearch(e.target.value)}
                                    ph="Keresés"
                                    width={"100%"}
                                />

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Profil</th>
                                            <th>Fizetési határidő</th>
                                            <th>Cégnév</th>
                                            <th>Bankszámlaszám</th>
                                            <th>Összeg</th>
                                            <th>Számla sorozatszám</th>
                                            <th>Fizetési mód</th>
                                            <th>Megjegyzés</th>
                                            <th>Műveletek</th>
                                        </tr>
                                    </thead>
                                    
                                    {showBills.length === 0 ?
                                        <tbody>
                                            <tr>
                                                <td
                                                    className={BillsStyle.bills_body_none}
                                                    colSpan={9}
                                                >
                                                    Nem található ilyen számla
                                                </td>
                                            </tr>
                                        </tbody>
                                    :
                                        <tbody>
                                            {showBills.map((item, i) => {
                                                return (
                                                    <Bill
                                                        key={i}
                                                        info={item}
                                                        onDelete={getStoreBills}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Bills;