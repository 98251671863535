import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryStyle from "../Summary.module.css";

import CurrencyText from "../../currencytext/CurrencyText";

const SummaryCashOut = ({ id, log, info }) => {
    const {setMsg} = useContext(DataContext);

    const [logData, setLogData] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log){
            getLog();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/cashregister/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
        return setLogData(data.log);
    };

    return (
        <table className={SummaryStyle.summary_table}>
            <tbody>
                <tr>
                    <td>Dátum</td>
                    <th>{log ? logData.date : new Date().toLocaleString("hu-HU")}</th>
                </tr>
                <tr>
                    <td>Összeg</td>
                    <th>
                        {log ?
                            <>
                                <CurrencyText
                                    val={logData.sum}
                                /> HUF
                            </>
                        :
                            <>
                                <CurrencyText
                                    val={info.sum}
                                /> HUF
                            </>
                        }
                    </th>
                </tr>
                <tr>
                    <td>Megjegyzés</td>
                    <th>{log ? logData.comment : info.comment}</th>
                </tr>
            </tbody>
        </table>
    );
};

export default SummaryCashOut;