import SpinnerStyle from "./Spinner.module.css";

const Spinner = ({ color }) => {
    return (
        <div className={SpinnerStyle.lds_ring}>
            <div style={{borderColor: color+" transparent transparent transparent"}}></div>
            <div style={{borderColor: color+" transparent transparent transparent"}}></div>
            <div style={{borderColor: color+" transparent transparent transparent"}}></div>
            <div style={{borderColor: color+" transparent transparent transparent"}}></div>
        </div>
    );
};

export default Spinner;