import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import NewPartnerStyle from "../newpartner/NewPartner.module.css";
import OperationsStyle from "../../operations/Operations.module.css";
import HomeStyle from "../../home/Home.module.css";

import Header from "../../../layouts/header/Header";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Nav from "../../../layouts/nav/Nav";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import SummaryPartner from "../../../components/summary/summarypartner/SummaryPartner";
import Btn from "../../../components/btn/Btn";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const NewPartnerVerify = () => {
    const {user, stores, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const location = useLocation();

    const [newPart] = useState(location.state);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleNewPartner = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/partners/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id,
                partner: newPart
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        socket.emit("new_partner", {id: store.company});
        navigate("/partners/"+params.store);
    };

    const handleBack = () => {
        navigate("/newpartner/"+params.store, {state: {newPart: newPart, back: true}});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={7}
                    store={params.store}
                />
                
                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={NewPartnerStyle.ops_partner_content}>
                                <span className={NewPartnerStyle.ops_partner_text}>
                                    Biztos felvezeted az alábbi partnert?
                                </span>

                                <SummaryPartner
                                    log={false}
                                    info={newPart}
                                />

                                <div className={NewPartnerStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Véglegesítés"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNewPartner}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default NewPartnerVerify;