export const getTrafficSum = (traffic) => {
    const sellArray = traffic.filter(row => row.receipt_num_1.split("/")[0] === "NY");

    const sum = sellArray.reduce((a, b) => a + Number(b.unit_value), 0);

    return sum;
};

export const getTrafficCashIn = (traffic) => {
    const cashInArray = traffic.filter(row => row.receipt_num_1.split("/")[0] === "BE");
    
    const sum = cashInArray.reduce((a, b) => a + Number(b.unit_value), 0);

    return sum;
};

export const getTrafficCashOut = (traffic) => {
    const cashOutArray = traffic.filter(row => row.receipt_num_1.split("/")[0] === "KI" || row.receipt_num_1.split("/")[0] === "G");
    
    const sum = cashOutArray.reduce((a, b) => a + Number(b.unit_value), 0);

    return -sum;
};

export const getReturnSum = (traffic) => {
    const returnArray = traffic.filter(row => row.receipt_num_1.split("/")[0] === "G");
    
    const sum = returnArray.reduce((a, b) => a + Number(b.unit_value), 0);

    return -sum;
};

export const getVatRateSum = (traffic, vat) => {
    const vatRateArray = traffic.filter(row => Number(row.vat_rate) === vat && row.receipt_num_1.split("/")[0] === "NY");

    const sum = vatRateArray.reduce((a, b) => a + Number(b.unit_value), 0);

    return sum;
};

export const getStoreColorCode = (name, stores) => {
    return stores.filter(s => s.name === name)[0].color_code_2;
};

export const calculateReceipt = (traffic) => {
    return [...new Map(traffic.map((element) => [element.receipt_num_1, element])).values()].length;
};