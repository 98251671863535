import { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";

import useDebounce from "../../../hooks/useDebounce";

import { DataContext } from "../../../context/DataContext";

import { getStoreStatus } from "../../../services/Api";

import PartnersStyle from "./Partners.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import Header from "../../../layouts/header/Header";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";

import Partner from "../../../components/partner/Partner";
import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import Btn from "../../../components/btn/Btn";
import DefaultInput from "../../../components/defaultinput/DefaultInput";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Partners = () => {
    const {
        user,
        stores,
        msg, setMsg, 
        loading, setLoading, 
        homeRef,
        search, setSearch,
        showSearch, setShowSearch
    } = useContext(DataContext);

    const [partners, setPartners] = useState([]);
    const [showPartners, setShowPartners] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        socket.emit("join", ["cid:"+stores.filter(s => s.id === user.store)[0].company]);

        getPartners();

        setSearch("");
        setShowSearch("");

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');
            setShowPartners(partners.filter(item => rx.test(item.name) || rx.test(item.address) || rx.test(item.tax_number) || rx.test(item.bank_account)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [showSearch, partners]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    useEffect(() => {
        socket.on("update_partners", () => {
            getPartners();
        });
    }, [socket]);

    const getPartners = async() => {
        const res = await fetch(apiUrl+"/partners", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPartners(data.partners);
    };

    const handleNewPartner = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/newpartner/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header 
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={4}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <DefaultInput
                                value={search}
                                change={(e) => setSearch(e.target.value)}
                                ph="Keresés"
                                width="100%"
                            />

                            <div className={PartnersStyle.ops_bill_body}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Cégnév</th>
                                            <th>Cím</th>
                                            <th>Bankszámlaszám</th>
                                            <th>Adószám</th>
                                            <th>Művelet</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        {showPartners.length === 0 ?
                                            <>
                                                <tr style={{color: "#747474", textAlign: "center"}}>
                                                    <td colSpan={5}>A keresésnek nincs eredménye.</td>
                                                </tr>

                                                <tr style={{color: "#747474", textAlign: "center"}}>
                                                    <td colSpan={5}>Felszeretnéd vezetni a beszállítót az adatbázisba?</td>
                                                </tr>
                                            </>
                                        :
                                            showPartners.map((p, i) => {
                                                return (
                                                    <Partner
                                                        key={i}
                                                        info={p}
                                                    />
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>

                                <Btn
                                    text="+ új partner létrehozása"
                                    click={handleNewPartner}
                                    bg="#2E89CC"
                                    color="#FFFFFF"
                                    hoverBg="#0D2646"
                                    hoverColor="#FFFFFF"
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Partners;