import { useNavigate, useParams } from "react-router-dom";

import TicketCardStyle from "./TicketCard.module.css";

import Btn from "../btn/Btn";
import CurrencyText from "../currencytext/CurrencyText";

const TicketCard = ({ ticket }) => {
    const params = useParams();

    const navigate = useNavigate();

    const handleGoToTicket = () => {
        navigate("/ticketinfo/ticket/"+params.store, {state: {ticket: ticket}});
    };

    return (
        <div className={TicketCardStyle.ticket_card}>
            {ticket.picture ? 
                <img
                    src={require("../../assets/tickets/"+ticket.picture+".png")}
                    alt="Icon"
                    onClick={handleGoToTicket}
                />
            :
                <div
                    className={TicketCardStyle.no_img}
                    onClick={handleGoToTicket}
                >
                    
                </div>
            }
            

            <span className={TicketCardStyle.card_name}>{ticket.name}</span>
            <div className={TicketCardStyle.card_value}>
                <span>Ár:</span>

                {ticket.value === 0 ?
                    <span>-</span>
                :
                    <>
                        <CurrencyText
                            val={ticket.value}
                        />
                        <span>HUF</span>
                    </>    
                }
            </div>

            <div className={TicketCardStyle.card_value}>
                <span>Főnyeremény:</span>

                {ticket.prize === 0 ?
                    <span>-</span>
                :
                    <>
                        <CurrencyText
                            val={ticket.prize}
                        />
                        <span>HUF</span>
                    </>    
                }
            </div>

            <div className={TicketCardStyle.card_dates}>
                <div className={TicketCardStyle.date}>
                    <span>Értékesítés utolsó napja</span>
                    
                    {ticket.last_sale_date ? 
                        <span>{ticket.last_sale_date}</span>
                    :
                        <span>-</span>
                    }
                </div>
                
                <div className={TicketCardStyle.date}>
                    <span>Beváltás utolsó napja</span>

                    {ticket.last_cashout_date ? 
                        <span>{ticket.last_cashout_date}</span>
                    :
                        <span>-</span>
                    }
                </div>
            </div>

            <Btn
                text="Módosítás / Nyereménykódok"
                bg="#959595"
                color="#FFFFFF"
                click={handleGoToTicket}
            />
        </div>
    );
};

export default TicketCard;