import { useContext, useState } from "react";

import { DataContext } from "../../context/DataContext";

import TicketItemStyle from "./TicketItem.module.css";

import MoreImg from "../../assets/body/ops_more.svg";
import MinusImg from "../../assets/body/ops_minus.svg";

const TicketItem = ({ item, tickets, onDelete, onEdit }) => {
    const {setMsg} = useContext(DataContext);

    const [edit, setEdit] = useState(false);
    const [newItem, setNewItem] = useState(item);

    const handleEdit = () => {
        if(newItem.type === 0 || newItem.from === "" || newItem.to === "") {
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        onEdit(newItem);
        setEdit(false);
    };

    const handleChange = (e) => {
        setNewItem(prevItem => ({
            ...prevItem,
            type: e.target.value,
            name: tickets.filter(t => t.id === Number(e.target.value))[0].name
        }));
    };

    const renderQuantity = () => {
        const from = item.from.split("-");
        const to = item.to.split("-");

        return (Number(to[to.length-1]) - Number(from[from.length-1])) + 1;
    };

    if(edit){
        return (
            <tr className={TicketItemStyle.ticket_row}>
                <td>
                    <select
                        value={newItem.type}
                        onChange={handleChange}
                    >
                        <option value={0}>Sorsjegy típusa</option>
                        
                        {tickets.map(t => {
                            return (
                                <option
                                    key={t.id}
                                    value={t.id}
                                >
                                    {t.name}
                                </option>
                            );
                        })}
                    </select>
                </td>
                <td>
                    <input
                        type="text"
                        value={newItem.from}
                        onChange={(e) => setNewItem(prevItem => ({...prevItem, from: e.target.value}))}
                    />
                </td>
                <td>
                    <input
                        type="text"
                        value={newItem.to}
                        onChange={(e) => setNewItem(prevItem => ({...prevItem, to: e.target.value}))}
                    />
                </td>
                <td>{renderQuantity()}</td>
                <td>
                    <img
                        src={MoreImg}
                        alt="More"
                        onClick={handleEdit}
                    />
                    <img
                        src={MinusImg}
                        alt="Minus"
                        onClick={() => onDelete(item)}
                    />
                </td>
            </tr>
        );
    }
    else {
        return (
            <tr className={TicketItemStyle.ticket_row}>
                <td>{item.name}</td>
                <td>{item.from}</td>
                <td>{item.to}</td>
                <td>{renderQuantity()}</td>
                <td>
                    <img
                        src={MoreImg}
                        alt="More"
                        onClick={() => setEdit(true)}
                    />
                    <img
                        src={MinusImg}
                        alt="Minus"
                        onClick={() => onDelete(item)}
                    />
                </td>
            </tr>
        );
    }
    
};

export default TicketItem;