import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import UserStyle from "./User.module.css";
import HomeStyle from "../../pages/home/Home.module.css";

import OkButton from "../okbutton/OkButton";
import ProfilePic from "../profilepic/ProfilePic";

const User = ({ info }) => {
    const {user, setMsg, setProfile, homeRef} = useContext(DataContext);

    const [isOK, setIsOK] = useState(info.status);

    const params = useParams();
    
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSetUserStatus = async() => {
        const res = await fetch(apiUrl+"/user/status", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: info.id,
                status: !isOK
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setIsOK(prevIsOK => !prevIsOK);
    };

    const handleModifyUser = () => {
        if(Number(info.rank) > Number(user.rank)) {
            return setMsg({
                color: "#F58E8E",
                message: "Ennek a felhasználónak nem tudod módosítani az adatait"
            });
        }

        setProfile({
            id: info.id,
            color: info.profile_color,
            rank: Number(info.rank),
            company: 0,
            lastName: info.last_name,
            firstName: info.first_name,
            nickName: info.nickname,
            birthPlace: info.place_of_birth,
            birthDate: info.birth_date?.split("T")[0],
            nameDay: info.name_day?.split("T")[0],
            zip: info.zip_code,
            city: info.city,
            address: info.address,
            social: info.social_sec_num,
            personalId: info.personal_id_num,
            tax: info.personal_tax_id,
            bank: info.bank_account,
            email: info.email,
            medical: info.medical?.split("T")[0],
            lungScreen: info.lung_screen?.split("T")[0],
            companyIn: info.company_in_date?.split("T")[0]
        });

        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/user/modify/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleModifyUserPerm = () => {
        if(Number(info.rank) > Number(user.rank)) {
            return setMsg({
                color: "#F58E8E",
                message: "Ennek a felhasználónak nem tudod módosítani a jogosultságait"
            });
        }

        setProfile({
            id: info.id,
            color: info.profile_color,
            rank: Number(info.rank),
            company: 0,
            lastName: info.last_name,
            firstName: info.first_name,
            nickName: info.nickname,
            birthPlace: info.place_of_birth,
            birthDate: info.birth_date?.split("T")[0],
            nameDay: info.name_day?.split("T")[0],
            zip: info.zip_code,
            city: info.city,
            address: info.address,
            social: info.social_sec_num,
            personalId: info.personal_id_num,
            tax: info.personal_tax_id,
            bank: info.bank_account,
            email: info.email,
            medical: info.medical?.split("T")[0],
            lungScreen: info.lung_screen?.split("T")[0],
            companyIn: info.company_in_date?.split("T")[0]
        });

        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/user/modify/perm/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    return (
        <tr>
            <td align="center">
                <ProfilePic
                    colorCode={info.profile_color}
                />
            </td>

            <td>
                {info.last_name} {info.first_name}
            </td>

            <td>
                {new Date(info.company_in_date).getFullYear()+". "+new Date(info.company_in_date).toLocaleString("hu-HU", { month: "2-digit" })+". "+new Date(info.company_in_date).toLocaleString("hu-HU", { day: "2-digit"})}
            </td>

            <td>
                {info.email}
            </td>

            <td align="center">
                <OkButton
                    isOK={isOK}
                    onOK={handleSetUserStatus}
                />
            </td>

            <td align="center">
                <div className={UserStyle.user_btns}>
                    <button onClick={handleModifyUser}>Személyes adatok</button>
                    <button onClick={handleModifyUserPerm}>Jogosultságok</button>
                </div>
            </td>
        </tr>
    );
};

export default User;