import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import TicketInfoNavStyle from "./TicketInfoNav.module.css";

const TicketInfoNav = ({ active }) => {
    const {setMsg} = useContext(DataContext);

    const params = useParams();

    const navigate = useNavigate();

    const handleGoToPage = (page) => {
        setMsg({});
        if(page === 1) navigate("/ticketinfo/manage/"+params.store, {state: {back: true}});
        else if(page === 2) navigate("/ticketinfo/stock/"+params.store);
        else if(page === 3) navigate("/ticketinfo/traffic/"+params.store);
        else if(page === 4) navigate("/ticketinfo/orders/"+params.store);
        else if(page === 5) navigate("/ticketinfo/table/"+params.store);
    };

    return (
        <div className={TicketInfoNavStyle.tickets_nav}>
            <button
                className={active === 1 ? TicketInfoNavStyle.btn_active : ""}
                onClick={() => handleGoToPage(1)}
            >
                Sorsjegyek kezelése
            </button>

            <button
                className={active === 2 ? TicketInfoNavStyle.btn_active : ""}
                onClick={() => handleGoToPage(2)}
            >
                Készlet adatok
            </button>

            <button
                className={active === 3 ? TicketInfoNavStyle.btn_active : ""}
                onClick={() => handleGoToPage(3)}
            >
                Forgalmak
            </button>

            <button
                className={active === 4 ? TicketInfoNavStyle.btn_active : ""}
                onClick={() => handleGoToPage(4)}
            >
                Rendelések
            </button>

            <button
                className={active === 5 ? TicketInfoNavStyle.btn_active : ""}
                onClick={() => handleGoToPage(5)}
            >
                Teljes táblázat
            </button>
        </div>
    );
};

export default TicketInfoNav;