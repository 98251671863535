import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import TicketStyle from "./Ticket.module.css";
import HomeStyle from "../../../home/Home.module.css";
import OperationsStyle from "../../../operations/Operations.module.css";

import ArrowImg from "../../../../assets/body/ops_arrow.svg";

import Nav from "../../../../layouts/nav/Nav";
import OpsHeader from "../../../../layouts/opsheader/OpsHeader";
import Header from "../../../../layouts/header/Header";

import Feedback from "../../../../components/feedback/Feedback";
import Spinner from "../../../../components/spinner/Spinner";
import DefaultInput from "../../../../components/defaultinput/DefaultInput";
import Btn from "../../../../components/btn/Btn";
import PrizeRow from "../../../../components/prizerow/PrizeRow";
import TicketStock from "../../../../components/ticketstock/TicketStock";
import CurrencyInput from "../../../../components/currencyinput/CurrencyInput";

const Ticket = () => {
    const {
        user,
        msg, setMsg,
        loading, setLoading,
        homeRef
    } = useContext(DataContext);

    const location = useLocation();

    const [ticket, setTicket] = useState(location.state.ticket);
    const [prizeTable, setPrizeTable] = useState([]);

    const [newPrize, setNewPrize] = useState({
        code1: "",
        code2: "",
        code3: "",
        value: ""
    });
    const prizeRef = {
        code1: useRef(),
        code2: useRef(),
        code3: useRef()
    };

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getPrizeTable();

        setLoading(false);
    }, []);

    const getPrizeTable = async() => {
        const res = await fetch(apiUrl+"/tickets/prize/"+ticket.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPrizeTable(data.prize);
    };

    const handleModifyTicket = async() => {
        const res = await fetch(apiUrl+"/tickets/modify", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ticket: ticket
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet!"
        });
    };

    const handleAddPrize = async() => {
        if(Object.values(newPrize).some(item => item === "")){
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        const res = await fetch(apiUrl+"/tickets/prize/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                ticket: ticket.id,
                prize: newPrize
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet!"
        });

        setNewPrize({
            code1: "",
            code2: "",
            code3: "",
            value: ""
        });

        getPrizeTable();
    };

    const handleModifyPrize = async(id, newRow) => {
        const res = await fetch(apiUrl+"/tickets/prize/modify", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: id,
                newRow: newRow
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet!"
        });

        getPrizeTable();
    };

    const handleDeletePrize = async(id) => {
        const res = await fetch(apiUrl+"/tickets/prize/delete", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                prize: id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet!"
        });

        getPrizeTable();
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={17}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={TicketStyle.ops_ticket}>
                                <div className={TicketStyle.ops_ticket_header}>
                                    <span>{ticket.name}</span>

                                    <img
                                        src={ArrowImg}
                                        alt="Back"
                                        style={{cursor: "pointer"}}
                                        onClick={() => navigate("/ticketinfo/manage/"+params.store, {state: {back: true}})}
                                    />
                                </div>

                                <div className={TicketStyle.ops_ticket_body}>
                                    <div className={TicketStyle.ops_ticket_info}>
                                        <div className={TicketStyle.head_title}>
                                            Alapadatok
                                        </div>

                                        <div className={TicketStyle.info_content}>
                                            {ticket.picture ? 
                                                <img
                                                    src={require("../../../../assets/tickets/"+ticket.picture+".png")}
                                                    alt="Icon"
                                                />
                                            :
                                                <div className={TicketStyle.no_img}>
                                                    
                                                </div>
                                            }

                                            <div className={TicketStyle.info}>
                                                <DefaultInput
                                                    ph="Megnevezés"
                                                    val={ticket.name}
                                                    change={(e) => setTicket(prevTicket => ({...prevTicket, name: e.target.value}))}
                                                    width="70%"
                                                />

                                                <CurrencyInput
                                                    ph="Ár"
                                                    val={ticket.value}
                                                    change={(val) => setTicket(prevTicket => ({...prevTicket, value: val}))}
                                                    width="40%"
                                                />

                                                <DefaultInput
                                                    ph="Rendelési egység"
                                                    val={ticket.order_quantity}
                                                    change={(e) => {
                                                        if(!isNaN(+e.target.value)) {
                                                            setTicket(prevTicket => ({...prevTicket, order_quantity: e.target.value}));
                                                        }
                                                    }}
                                                    width="40%"
                                                />

                                                <CurrencyInput
                                                    ph="Főnyeremény"
                                                    val={ticket.prize}
                                                    change={(val) => setTicket(prevTicket => ({...prevTicket, prize: val}))}
                                                    width="40%"
                                                />

                                                <DefaultInput
                                                    ph="Értékesítés utolsó napja"
                                                    type="date"
                                                    val={ticket.last_sale_date}
                                                    change={(e) => setTicket(prevTicket => ({...prevTicket, last_sale_date: e.target.value}))}
                                                    width="55%"
                                                />
                                                
                                                <DefaultInput
                                                    ph="Nyeremény beváltás utolsó napja"
                                                    type="date"
                                                    val={ticket.last_cashout_date}
                                                    change={(e) => setTicket(prevTicket => ({...prevTicket, last_cashout_date: e.target.value}))}
                                                    width="55%"
                                                />

                                                <Btn
                                                    text="Módosítások mentése"
                                                    color="#FFFFFF"
                                                    bg="#48D7A3"
                                                    style={{width: "10%"}}
                                                    click={handleModifyTicket}
                                                />
                                            </div>
                                        </div>

                                        <div
                                            className={TicketStyle.ops_ticket_table}
                                            style={{width: "100%"}}
                                        >
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th style={{paddingLeft: "8px"}}>Telephely</th>
                                                        <th>Típus</th>
                                                        <th>Érték / db</th>
                                                        <th>Mennyiség</th>
                                                        <th>Összérték</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {user.stores.map((item, i) => {
                                                        return (
                                                            <TicketStock
                                                                key={i}
                                                                store={item}
                                                                ticket={ticket.id}
                                                            />
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div
                                        className={TicketStyle.ops_ticket_table}
                                        style={{width: "30%"}}
                                    >
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{paddingLeft: "8px"}}>Nyereménykód</th>
                                                    <th>Összeg</th>
                                                    <th>Műveletek</th>
                                                </tr>
                                            </thead>
                                            
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <input
                                                            type="text"
                                                            className={TicketStyle.prize_code}
                                                            ref={prizeRef.code1}
                                                            value={newPrize.code1}
                                                            onChange={(e) => {
                                                                if(newPrize.code1.length === 0){
                                                                    setNewPrize(prevPrize => ({...prevPrize, code1: e.target.value.toUpperCase()}));
                                                                    prizeRef.code2.current.focus();
                                                                }
                                                                else if(e.target.value === ""){
                                                                    setNewPrize(prevPrize => ({...prevPrize, code1: e.target.value.toUpperCase()}));
                                                                }
                                                            }}
                                                        />
                                        
                                                        <input
                                                            type="text"
                                                            className={TicketStyle.prize_code}
                                                            ref={prizeRef.code2}
                                                            value={newPrize.code2}
                                                            onChange={(e) => {
                                                                if(newPrize.code2.length === 0){
                                                                    setNewPrize(prevPrize => ({...prevPrize, code2: e.target.value.toUpperCase()}));
                                                                    prizeRef.code3.current.focus();
                                                                }
                                                                else if(e.target.value === ""){
                                                                    setNewPrize(prevPrize => ({...prevPrize, code2: e.target.value.toUpperCase()}));
                                                                    prizeRef.code1.current.focus();
                                                                }
                                                            }}
                                                        />
                                        
                                                        <input
                                                            type="text"
                                                            className={TicketStyle.prize_code}
                                                            ref={prizeRef.code3}
                                                            value={newPrize.code3}
                                                            onChange={(e) => {
                                                                if(newPrize.code3.length === 0){
                                                                    setNewPrize(prevPrize => ({...prevPrize, code3: e.target.value.toUpperCase()}));
                                                                }
                                                                else if(e.target.value === ""){
                                                                    setNewPrize(prevPrize => ({...prevPrize, code3: e.target.value.toUpperCase()}));
                                                                    prizeRef.code2.current.focus();
                                                                }
                                                            }}
                                                        />
                                                    </td>

                                                    <td style={{padding: "10px"}}>
                                                        <CurrencyInput
                                                            ph="Összeg"
                                                            val={newPrize.value}
                                                            change={(val) => setNewPrize(prevPrize => ({...prevPrize, value: val}))}
                                                            width="7vw"
                                                        />
                                                    </td>

                                                    <td>
                                                        <Btn
                                                            text="Hozzáadás"
                                                            color="#FFFFFF"
                                                            bg="#48D7A3"
                                                            click={handleAddPrize}
                                                        />
                                                    </td>
                                                </tr>

                                                {prizeTable.map(item => {
                                                    return (
                                                        <PrizeRow
                                                            key={item.id}
                                                            prize={item}
                                                            onModify={handleModifyPrize}
                                                            onDelete={handleDeletePrize}
                                                        />
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Ticket;