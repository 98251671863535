import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";
import { getUserStores } from "../../services/Api";

import UserStoreStyle from "./UserStore.module.css";

import StorePic from "../storepic/StorePic";
import OkButton from "../okbutton/OkButton";

const UserStore = ({ store, onChoose, initial, modify }) => {
    const {profile} = useContext(DataContext);

    const [isOK, setIsOK] = useState(initial);

    useEffect(() => {
        if(modify) {
            getUserStores(profile.id).then(data => {
                const ok = data.some(s => store.id === s.id);
                setIsOK(ok);
            });
        }
    }, []);

    const handleSetOk = () => {
        onChoose(store, !isOK);
        setIsOK(prevOK => !prevOK);
    };

    return (
        <div
            className={UserStoreStyle.user_store}
            onClick={handleSetOk}
        >
            <StorePic
                store={store}
            />

            <OkButton
                isOK={isOK}
            />
        </div>
    );
};

export default UserStore;