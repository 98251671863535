import StorePicStyle from "./StorePic.module.css";

const StorePic = ({ store, click, filter, choosen }) => {
    if(filter) {
        return (
            <div
                className={StorePicStyle.store_icon_small}
                style={choosen ? {background: store.color_code_1, borderColor: store.color_code_2, userSelect: "none"} : {background: store.color_code_1, borderColor: store.color_code_2, opacity: "0.3", userSelect: "none"}}
                onClick={click}
            >
                <span style={{color: store.color_code_2}}>{store.name[0]}</span>
            </div>
        );
    }
    else {
        return (
            <div
                className={StorePicStyle.store_icon}
                style={{background: store.color_code_1, borderColor: store.color_code_2}}
                onClick={click}
            >
                <span style={{color: store.color_code_2}}>{store.name[0]}</span>
            </div>
        );
    }
};

export default StorePic;