import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import ChangeInputStyle from "./ChangeInput.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";
import DailyProgress from "../../../layouts/dailyprogress/DailyProgress";

import Spinner from "../../../components/spinner/Spinner";
import Btn from "../../../components/btn/Btn";
import DefaultInput from "../../../components/defaultinput/DefaultInput";
import CurrencyInput from "../../../components/currencyinput/CurrencyInput";
import Feedback from "../../../components/feedback/Feedback";

const ChangeInput = () => {
    const {user, loading, setLoading, homeRef, msg, setMsg} = useContext(DataContext);

    const [corrItems, setCorrItems] = useState({
        close: "",
        card: "",
        rounding: "",
        return18: "",
        return27: "",
        returnAJT: ""
    });

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        if(location.state?.corrItems) {
            setCorrItems(location.state.corrItems);
        }

        setLoading(false);
    }, []);

    const handleBack = () => {
        navigate("/changeops/"+params.store);
    };

    const handleNext = () => {
        if(Object.values(corrItems).some(item => item === "")) {
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        navigate("/changepcs/"+params.store, {state: {corrItems: corrItems}});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                <OpsHeader
                    operation={3}
                    store={params.store}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={ChangeInputStyle.ops_change_body}>
                                <DailyProgress
                                    active={4}
                                />

                                <div className={ChangeInputStyle.ops_change_header}>
                                    <span>Add meg a korrekciós műveleteket illetve a zárás sorszámát.</span>

                                    <div className={ChangeInputStyle.ops_change_btns}>
                                        <Btn
                                            text="Vissza"
                                            bg="#FFFFFF"
                                            color="#7D7D7D"
                                            hoverBg="#DBDBDB"
                                            hoverColor="#7D7D7D"
                                            click={handleBack}
                                        />

                                        <Btn
                                            text="Tovább"
                                            bg="#2E89CC"
                                            color="#FFFFFF"
                                            hoverBg="#0D2646"
                                            hoverColor="#FFFFFF"
                                            click={handleNext}
                                        />
                                    </div>
                                </div>

                                <div className={ChangeInputStyle.ops_change_correction}>
                                    <span className={ChangeInputStyle.correction_title}>Korrekciós tételek</span>

                                    <div className={ChangeInputStyle.corrections}>
                                        <div className={ChangeInputStyle.correction_item}>
                                            <span>Zárás sorszáma</span>

                                            <DefaultInput
                                                val={corrItems.close}
                                                change={(e) => {
                                                    if(!isNaN(+e.target.value)){
                                                        setCorrItems(prevCorr => ({...prevCorr, close: e.target.value}));
                                                    }
                                                }}
                                            />
                                        </div>

                                        <div className={ChangeInputStyle.correction_item}>
                                            <span>Bankkártyás forgalom</span>

                                            <CurrencyInput
                                                val={corrItems.card}
                                                change={(val) => setCorrItems(prevCorr => ({...prevCorr, card: val}))}
                                            />
                                        </div>

                                        <div className={ChangeInputStyle.correction_item}>
                                            <span>Kerekítés összege</span>

                                            <CurrencyInput
                                                val={corrItems.rounding}
                                                change={(val) => setCorrItems(prevCorr => ({...prevCorr, rounding: val}))}
                                            />
                                        </div>

                                        <div className={ChangeInputStyle.correction_item}>
                                            <span>Sztornó 18%-os</span>

                                            <CurrencyInput
                                                val={corrItems.return18}
                                                change={(val) => setCorrItems(prevCorr => ({...prevCorr, return18: val}))}
                                            />
                                        </div>

                                        <div className={ChangeInputStyle.correction_item}>
                                            <span>Sztornó 27%-os</span>

                                            <CurrencyInput
                                                val={corrItems.return27}
                                                change={(val) => setCorrItems(prevCorr => ({...prevCorr, return27: val}))}
                                            />
                                        </div>

                                        <div className={ChangeInputStyle.correction_item}>
                                            <span>Sztornó AJT</span>

                                            <CurrencyInput
                                                val={corrItems.returnAJT}
                                                change={(val) => setCorrItems(prevCorr => ({...prevCorr, returnAJT: val}))}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ChangeInput;