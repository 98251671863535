// Napnyitas cimletjegyzek komponens

import { useContext, useEffect, useRef } from "react";
import { DataContext } from "../../context/DataContext";

import DailyTableStyle from "./DailyTable.module.css";

const DailyTable = ({ colorcode, bg, border, textColor, onEnter, back }) => {
    const {pcs, setPcs} = useContext(DataContext);

    const refs = {
        ref20000: useRef(), 
        ref10000: useRef(), 
        ref5000: useRef(), 
        ref2000: useRef(), 
        ref1000: useRef(), 
        ref500: useRef(), 
        ref200: useRef(), 
        ref100: useRef(), 
        ref50: useRef(), 
        ref20: useRef(), 
        ref10: useRef(), 
        ref5: useRef(), 
    };

    useEffect(() => {
        if(!back){
            setPcs({
                huf5: "0",
                huf10: "0",
                huf20: "0",
                huf50: "0",
                huf100: "0",
                huf200: "0",
                huf500: "0",
                huf1000: "0",
                huf2000: "0",
                huf5000: "0",
                huf10000: "0",
                huf20000: "0",
                sum: 0
            });
        }

        // refs.ref20000.current.focus();
        // Ha ezt hasznaljuk akkor nem mukodik jol a slide animacio
    }, []);

    useEffect(() => {
        calculateSum();
    }, [pcs.huf5, pcs.huf10, pcs.huf20, pcs.huf50, pcs.huf100, pcs.huf200, pcs.huf500, pcs.huf1000,
        pcs.huf2000, pcs.huf5000, pcs.huf10000, pcs.huf20000]);

    const calculateSum = () => {
        const sum = ((20000*pcs.huf20000) + (10000*pcs.huf10000) + (5000*pcs.huf5000) + (2000*pcs.huf2000) + 
        (1000*pcs.huf1000) + (500*pcs.huf500) + (200*pcs.huf200) + (100*pcs.huf100) + 
        (50*pcs.huf50) + (20*pcs.huf20) + (10*pcs.huf10) + (5*pcs.huf5));

        setPcs(prevPcs => ({...prevPcs, sum: sum}));
    };

    return (
        <div className={DailyTableStyle.pcs_content}>
            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    20.000
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf20000}
                    ref={refs.ref20000}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            refs.ref10000.current.focus();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf20000: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>

            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    10.000
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf10000}
                    ref={refs.ref10000}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            refs.ref5000.current.focus();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf10000: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>

            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    5.000
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf5000}
                    ref={refs.ref5000}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            refs.ref2000.current.focus();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf5000: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>

            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    2.000
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf2000}
                    ref={refs.ref2000}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            refs.ref1000.current.focus();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf2000: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>

            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    1.000
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf1000}
                    ref={refs.ref1000}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            refs.ref500.current.focus();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf1000: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>

            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    500
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf500}
                    ref={refs.ref500}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            refs.ref200.current.focus();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf500: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>

            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    200
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf200}
                    ref={refs.ref200}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            refs.ref100.current.focus();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf200: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>

            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    100
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf100}
                    ref={refs.ref100}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            refs.ref50.current.focus();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf100: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>

            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    50
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf50}
                    ref={refs.ref50}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            refs.ref20.current.focus();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf50: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>

            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    20
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf20}
                    ref={refs.ref20}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            refs.ref10.current.focus();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf20: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>

            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    10
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf10}
                    ref={refs.ref10}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            refs.ref5.current.focus();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf10: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>

            <div className={DailyTableStyle.pc_item}>
                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    5
                </span>

                <input
                    type="text"
                    style={{color: colorcode, background: bg, border: border}}
                    value={pcs.huf5}
                    ref={refs.ref5}
                    onKeyDown={(e) => {
                        if(e.key === "Enter") {
                            onEnter();
                        }
                    }}
                    onChange={(e) => {
                        if(!isNaN(+e.target.value)) {
                            setPcs(prevPcs => ({...prevPcs, huf5: e.target.value}));
                        }
                    }}
                    onFocus={(e) => e.target.select()}
                />

                <span
                    className={DailyTableStyle.pc}
                    style={{color: textColor}}
                >
                    DB
                </span>
            </div>
        </div>
    );
};

export default DailyTable;