import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import BillModifyStyle from "./BillModify.module.css";
import PartnersStyle from "../partners/Partners.module.css";
import PartnerStyle from "../../../components/partner/Partner.module.css";
import NewBillStyle from "../newbill/NewBill.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import CashImg from "../../../assets/body/ops_bill_cash_1.svg";
import TransferImg from "../../../assets/body/ops_bill_transfer_1.svg";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import Btn from "../../../components/btn/Btn";
import CurrencyInput from "../../../components/currencyinput/CurrencyInput";

const BillModify = () => {
    const {user, stores, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const location = useLocation();

    const [bill, setBill] = useState(location.state);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleBack = () => {
        navigate("/bills/"+params.store, {state: {back: true}});
    };

    const handleSaveBill = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/bill/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id,
                bill: bill
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            })
        }

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet!"
        });
        navigate("/bills/"+params.store, {state: {back: true}});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={4}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={PartnersStyle.ops_bill_body}>
                                <div className={NewBillStyle.ops_bill_choosen}>
                                    <div className={NewBillStyle.ops_partner_active}>
                                        <div
                                            className={PartnerStyle.ops_partner_icon}
                                            style={{color: bill.color_code, borderColor: bill.color_code}}
                                        >
                                            <span>{bill.name[0]}</span>
                                        </div>

                                        <div className={NewBillStyle.ops_partner_texts}>
                                            <span>Cégnév</span>
                                            <span>{bill.name}</span>
                                        </div>

                                        <div className={NewBillStyle.ops_partner_texts}>
                                            <span>Kedvezményezett számlaszáma</span>
                                            <span>{bill.bank_account}</span>
                                        </div>

                                        <div className={BillModifyStyle.ops_modify_pay}>
                                            <img
                                                src={CashImg}
                                                className={Number(bill.cash) === 1 ? PartnerStyle.ops_bill_cash_choosen : PartnerStyle.ops_bill_cash}
                                                alt="Cash"
                                                onClick={() => setBill(prevBill => ({...prevBill, cash: 1}))}
                                            />

                                            <img
                                                src={TransferImg}
                                                className={Number(bill.cash) === 0 ? PartnerStyle.ops_bill_transfer_choosen : PartnerStyle.ops_bill_transfer}
                                                alt="Transfer"
                                                onClick={() => setBill(prevBill => ({...prevBill, cash: 0}))}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={NewBillStyle.ops_bill_stats}>
                                    <div className={NewBillStyle.ops_bill_stat}>
                                        <span>Teljesítés dátuma:</span>

                                        <input
                                            type="date"
                                            className={NewBillStyle.ops_bill_stat_input}
                                            value={bill.completion_date}
                                            onChange={(e) => setBill(prevBill => ({...prevBill, completion_date: e.target.value}))}
                                        />
                                    </div>

                                    <div className={NewBillStyle.ops_bill_stat}>
                                        <span>Fizetési határidő:</span>

                                        <input
                                            type="date"
                                            className={NewBillStyle.ops_bill_stat_input}
                                            value={bill.payment_deadline}
                                            onChange={(e) => setBill(prevBill => ({...prevBill, payment_deadline: e.target.value}))}
                                        />
                                    </div>

                                    <div className={NewBillStyle.ops_bill_stat}>
                                        <span>Számla sorszáma:</span>

                                        <input
                                            type="text"
                                            className={NewBillStyle.ops_bill_stat_input}
                                            value={bill.bill_serial_number}
                                            onChange={(e) => setBill(prevBill => ({...prevBill, bill_serial_number: e.target.value}))}
                                        />
                                    </div>

                                    <div className={NewBillStyle.ops_bill_stat}>
                                        <span>Fizetendő összeg:</span>

                                        <CurrencyInput
                                            val={bill.amount}
                                            change={(val) => {
                                                if(!isNaN(+val)) {
                                                    setBill(prevBill => ({...prevBill, amount: val}))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <textarea
                                    className={NewBillStyle.ops_bill_comment}
                                    placeholder="Megjegyzés"
                                    value={bill.comment}
                                    onChange={(e) => setBill(prevBill => ({...prevBill, comment: e.target.value}))}
                                />

                                <div className={BillModifyStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Mentés"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleSaveBill}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default BillModify;