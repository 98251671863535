import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryStyle from "../Summary.module.css";

const SummaryUser = ({ id, log, info }) => {
    const {setMsg} = useContext(DataContext);

    const [logData, setLogData] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log) {
            getLog();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/user/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
        return setLogData(data.user);
    };

    return (
        <table className={SummaryStyle.summary_table}>
            <tbody>
                <tr>
                    <td>Email</td>
                    <th>{log ? logData.email : info.email}</th>
                </tr>
                <tr>
                    <td>Vezetéknév</td>
                    <th>{log ? logData.last_name : info.lastName}</th>
                </tr>
                <tr>
                    <td>Keresztnév</td>
                    <th>{log ? logData.first_name : info.firstName}</th>
                </tr>
                <tr>
                    <td>Születési dátum</td>
                    <th>{log ? logData.birth_date : info.birthDate}</th>
                </tr>
                <tr>
                    <td>Születési hely</td>
                    <th>{log ? logData.place_of_birth : info.birthPlace}</th>
                </tr>
                <tr>
                    <td>Város</td>
                    <th>{log ? logData.city : info.city}</th>
                </tr>
                <tr>
                    <td>Cím</td>
                    <th>{log ? logData.address : info.address}</th>
                </tr>
                <tr>
                    <td>Bankszámlaszám</td>
                    <th>{log ? logData.bank_account : info.bank}</th>
                </tr>
                <tr>
                    <td>Személyi igazolványszám</td>
                    <th>{log ? logData.personal_id_num : info.personalId}</th>
                </tr>
                <tr>
                    <td>TAJ szám</td>
                    <th>{log ? logData.social_sec_num : info.social}</th>
                </tr>
                <tr>
                    <td>Adószám</td>
                    <th>{log ? logData.personal_tax_id : info.tax}</th>
                </tr>
                <tr>
                    <td>Orvosi alkalmassági</td>
                    <th>{log ? logData.medical : info.medical}</th>
                </tr>
                <tr>
                    <td>Tüdőszűrő</td>
                    <th>{log ? logData.lung_screen : info.lungScreen}</th>
                </tr>
            </tbody>
        </table>
    );
};

export default SummaryUser;