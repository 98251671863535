import TrafficTableStyle from "./TrafficTable.module.css";

import CurrencyText from "../currencytext/CurrencyText";

const TrafficTable = ({ row }) => {
    return (
        <tr className={TrafficTableStyle.row}>
            <td>{row.date.split(" ")[3].split(":")[0]+":"+row.date.split(" ")[3].split(":")[1]}</td>
            <td>{row.barcode}</td>
            <td>{row.item_name}</td>
            <td>{row.receipt_num_1}</td>
            <td>{row.receipt_num_2}</td>
            <td>{row.quantity}</td>
            <td style={{fontWeight: 600}}>
                <CurrencyText
                    val={row.unit_value}
                    style={{fontWeight: 600}}
                /> HUF
            </td>
        </tr>
    );
};

export default TrafficTable;