import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import OpsHeaderStyle from "./OpsHeader.module.css";
import HomeStyle from "../../pages/home/Home.module.css";

import ExitImg from "../../assets/body/ops_exit.svg";
import OpIcon from "../../components/opicon/OpIcon";

const OpsHeader = ({ operation, store }) => {
    const {homeRef, setMsg} = useContext(DataContext);

    const [op, setOp] = useState({});

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getOperationData();
    }, []);

    const getOperationData = async() => {
        const res = await fetch(apiUrl+"/operation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: operation
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setOp(data.op);
    };

    const handleExitOperation = () => {
        homeRef.current.className = HomeStyle.content_body_exit

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/operations/"+store);
        }, 400);
        return () => clearTimeout(timer);
    };

    return (
        <div className={OpsHeaderStyle.ops_header}>
            <div className={OpsHeaderStyle.ops_card}>
                <div className={OpsHeaderStyle.ops_card_details}>
                    <OpIcon
                        op={op}
                    />

                    <div className={OpsHeaderStyle.ops_card_details_text}>
                        <span className={OpsHeaderStyle.ops_card_name}>{op.name}</span>
                        <span className={OpsHeaderStyle.ops_card_desc}>{op.description}</span>
                    </div>
                </div>

                <div
                    className={OpsHeaderStyle.ops_card_back}
                    onClick={handleExitOperation}
                >
                    <img
                        src={ExitImg}
                        alt="Icon"
                    />

                    <span>Bezárás</span>
                </div>
            </div>
        </div>
    );
};

export default OpsHeader;