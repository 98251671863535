// Egy sor nyertes sorsjegy (page: Tickets.js -> /tickets)

import TicketRowStyle from "./TicketRow.module.css";

import Profile from "../profile/Profile";
import CurrencyText from "../currencytext/CurrencyText";

const TicketRow = ({ info, deleteRow }) => {
    return (
        <tr className={TicketRowStyle.ticket_row}>
            <td>
                <Profile
                    color={info.profile_color}
                    name={info.last_name+" "+info.first_name}
                    nameColor={"#A6A0A0"}
                    bubble={true}
                />
            </td>

            <td>
                {new Date(info.date_of_payment).toLocaleString("hu-HU", { year: "numeric", month: "2-digit", day: "2-digit" })}
            </td>

            <td>
                {info.name}
            </td>

            <td>
                {info.serial_num}
            </td>

            <td style={{textAlign: "center"}}>
                {info.prize_code}
            </td>

            <td>
                <CurrencyText
                    val={info.prize_value}
                /> <span>HUF</span>
            </td>

            <td style={{textAlign: "center"}}>
                <button onClick={() => deleteRow(info.id)}>Törlés</button>
            </td>
        </tr>
    );
};

export default TicketRow;