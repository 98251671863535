import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";

import TrafficLogStyle from "./TrafficLogTable.module.css";

import OpIcon from "../opicon/OpIcon";
import CurrencyText from "../currencytext/CurrencyText";

const TrafficLogTable = ({ item }) => {
    const {setMsg} = useContext(DataContext);

    const [op, setOp] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getOperation();
    }, [item]);

    const getOperation = async() => {
        const res = await fetch(apiUrl+"/operation", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: item.operation
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setOp(data.op);
    };

    return (
        <div className={TrafficLogStyle.item}>
            <div className={TrafficLogStyle.item_value}>
                <CurrencyText
                    val={item.sum}
                />
                <span>HUF</span>
            </div>

            <span className={TrafficLogStyle.item_date}>
                {item.date.split(" ")[1]}
            </span>

            <div className={TrafficLogStyle.item_op}>
                <OpIcon
                    op={op}
                />
                <span>{op.name}</span>
            </div>
        </div>
    );
};

export default TrafficLogTable;