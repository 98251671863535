import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import TransitSafeStyle from "./TransitSafe.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import TransitItem from "../../../components/transititem/TransitItem";
import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import DefaultInput from "../../../components/defaultinput/DefaultInput";
import Btn from "../../../components/btn/Btn";

const TransitSafe = () => {
    const {user, stores, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const [packeges, setPackeges] = useState([]);
    const [serialNum, setSerialNum] = useState("");
    const [choosen, setChoosen] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getPackeges();

        setLoading(false);
    }, []);

    const getPackeges = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/safe/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPackeges(data.safe.filter(item => item.transit_id === null));
    };

    const handleAddItem = (p) => {
        setChoosen(prevChoosen => [...prevChoosen, p]);
    };

    const handleRemoveItem = (p) => {
        setChoosen(prevChoosen => prevChoosen.filter(item => item.id !== p.id));
    };

    const handleTransit = async() => {
        if(choosen.length === 0){
            return setMsg({
                color: "#F58E8E",
                message: "Válaszd ki a szállítandó csomagokat"
            });
        }

        if(serialNum === ""){
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        setMsg({});
        navigate("/safe/transit/verify/"+params.store, {state: {choosen: choosen, serialNum: serialNum}});
    };

    const handleBack = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/operations/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={6}
                    store={params.store}
                />

                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={TransitSafeStyle.ops_safe_body}>
                                <span className={TransitSafeStyle.ops_safe_text}>CSOMAGOK</span>

                                {packeges.map(p => {
                                    return (
                                        <TransitItem
                                            key={p.id}
                                            serialNum={p.serial_num}
                                            onChoose={() => handleAddItem(p)}
                                            onRemove={() => handleRemoveItem(p)}
                                        />
                                    );
                                })}

                                <span className={TransitSafeStyle.ops_safe_text}>ÉRTÉKSZÁLLÍTÓ AZONOSÍTÓ KÓDJA</span>
                            
                                <DefaultInput
                                    ph="Add meg az értékszállító azonosítókodját"
                                    val={serialNum}
                                    change={(e) => setSerialNum(e.target.value)}
                                    keyDown={(e) => {
                                        if(e.key === "Enter") {
                                            handleTransit();
                                        }
                                    }}
                                    width="100%"
                                />

                                <div className={TransitSafeStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Tovább"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleTransit}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default TransitSafe;