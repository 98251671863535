import { useContext, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import ValueFilterStyle from "./ValueFilter.module.css";

import CurrencyInput from "../../currencyinput/CurrencyInput";
import Btn from "../../btn/Btn";

const ValueFilter = ({ onFilter }) => {
    const {setMsg} = useContext(DataContext);

    const [val, setVal] = useState({
        from: "",
        to: ""
    });

    const handleClick = () => {
        if(Object.values(val).some(prop => prop === "")) {
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        onFilter(val)
        setVal({
            from: "",
            to: ""
        })
    };

    return (
        <div className={ValueFilterStyle.value_form}>
            <CurrencyInput
                ph={"Ettől"}
                val={val.from}
                change={(val) => {
                    if(!isNaN(+val)) {
                        setVal(prevVal => ({...prevVal, from: val}));
                    }
                }}
            />

            <CurrencyInput
                ph={"Eddig"}
                val={val.to}
                change={(val) => {
                    if(!isNaN(+val)) {
                        setVal(prevVal => ({...prevVal, to: val}));
                    }
                }}
            />
            
            <Btn
                text="Alkalmaz"
                bg="#2E89CC"
                color="#FFFFFF"
                click={handleClick}
            />
        </div>
    );
};

export default ValueFilter;