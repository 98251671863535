import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../context/DataContext";

import TicketStockInfoStyle from "./TicketStockInfo.module.css";

import StoreBubble from "../storebubble/StoreBubble";
import CurrencyText from "../currencytext/CurrencyText";

const TicketStockInfo = ({ row, onInfo }) => {
    const {setMsg} = useContext(DataContext);

    const [stockInfo, setStockInfo] = useState({
        store: "",
        type: "",
        value: "",
        quantity: "",
        sum: ""
    });

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getStore();
    }, []);

    const getStore = async() => {
        const res = await fetch(apiUrl+"/store/"+row[0].store, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        getStock(data.store);
    };

    const getStock = async(store) => {
        const res = await fetch(apiUrl+"/tickets/stock/"+store.id+"/"+row[0].ticket, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        if(data.stock.length !== 0) {
            if(data.traffic) {
                const minusQuantity = data.traffic?.reduce((acc, obj) => {
                    return acc + obj.quantity;
                }, 0);

                const info = {
                    store: store.name,
                    colorCode: store.color_code_2,
                    type: data.stock[0].name,
                    value: data.stock[0].value,
                    quantity: Number(data.stock.length) - minusQuantity,
                    sum: (Number(data.stock.length) - minusQuantity) * data.stock[0].value
                };

                setStockInfo(info);
            }
            else {
                const info = {
                    store: store.name,
                    colorCode: store.color_code_2,
                    type: data.stock[0].name,
                    value: data.stock[0].value,
                    quantity: Number(data.stock.length),
                    sum: Number(data.stock.length)* data.stock[0].value
                };

                setStockInfo(info);
            }
        }
    };

    return (
        <tr className={TicketStockInfoStyle.row}>
            <td>
                <StoreBubble
                    text={stockInfo.store}
                    colorCode={stockInfo.colorCode}
                />
            </td>

            <td>
                {stockInfo.type}
            </td>

            <td>
                <CurrencyText
                    val={stockInfo.value}
                /> HUF
            </td>

            <td style={{fontWeight: "400"}}>
                {stockInfo.quantity}
            </td>

            <td style={{fontWeight: "400"}}>
                <CurrencyText
                    val={stockInfo.sum}
                /> HUF
            </td>
        </tr>
    );
};

export default TicketStockInfo;