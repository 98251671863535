import ProfilePicStyle from "./ProfilePic.module.css";

import ProfileImg from "../../assets/header/profile.svg";

const ProfilePic = ({ colorCode, onHover, onUnHover, choosen, filter }) => {
    if(filter) {
        return (
            <div
                className={ProfilePicStyle.profile_img}
                style={choosen ? {borderColor: colorCode} : {borderColor: colorCode, opacity: "0.3"}}
                onMouseEnter={onHover}
                onMouseLeave={onUnHover}
            >
                <img
                    src={ProfileImg}
                    alt="Profile"
                />
            </div>
        );
    }
    else {
        return (
            <div
                className={ProfilePicStyle.profile_img}
                style={{borderColor: colorCode}}
                onMouseEnter={onHover}
                onMouseLeave={onUnHover}
            >
                <img
                    src={ProfileImg}
                    alt="Profile"
                />
            </div>
        );
    }
    
};

export default ProfilePic;