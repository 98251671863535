import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryStyle from "../Summary.module.css";

const SummaryInTickets = ({ id, log, info }) => {
    const {setMsg} = useContext(DataContext);

    const [logData, setLogData] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log) {
            getLog();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/tickets/logid/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        return setLogData(data.tickets.reduce((x, y) => {
            (x[y.ticket] = x[y.ticket] || []).push(y);
            return x;
        }, {}));
    };

    const renderQuantity = (ticket) => {
        const from = ticket.from.split("-");
        const to = ticket.to.split("-");

        return (Number(to[to.length-1]) - Number(from[from.length-1])) + 1;
    };

    const renderTickets = () => {
        if(log) {
            return Object.keys(logData).map((key) => {
                return (
                    <tr key={key}>
                        <th style={{padding: "10px"}}>{logData[key][0].name}</th>
                        <td style={{padding: "10px"}}>{logData[key][0].serial_num}</td>
                        <td style={{padding: "10px"}}>{logData[key][logData[key].length-1].serial_num}</td>
                        <td style={{padding: "10px"}}>{logData[key].length-1} DB</td>
                    </tr>
                );
            })
        }
        else {
            return info.map((t, i) => {
                return (
                    <tr key={i}>
                        <th style={{padding: "10px"}}>{t.name}</th>
                        <td style={{padding: "10px"}}>{t.from}</td>
                        <td style={{padding: "10px"}}>{t.to}</td>
                        <td style={{padding: "10px"}}>{renderQuantity(t)} DB</td>
                    </tr>
                );
            });
        }
    };

    return (
        <table className={SummaryStyle.summary_table}>
            <tbody>
                {renderTickets()}
            </tbody>
        </table>
    );
};

export default SummaryInTickets;