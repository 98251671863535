import { useContext } from "react";

import { useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import HeaderStyle from "./Header.module.css";

import Profile from "../../components/profile/Profile";
import StorePic from "../../components/storepic/StorePic";

const Header = ({ user, yearColor, monthDayColor, nameColor }) => {
    const {stores} =  useContext(DataContext);

    const params = useParams();

    return (
        <div className={HeaderStyle.content_header}>
            <div className={HeaderStyle.content_left}>
                {params.store ?
                    <StorePic
                        store={stores.filter(s => s.name === params.store)[0]}
                    />
                :
                    <></>
                }
                <div className={HeaderStyle.content_date}>
                    <span
                        className={HeaderStyle.date_year}
                        style={{color: yearColor}}
                    >
                        {new Date().getFullYear()}
                    </span>

                    <span
                        className={HeaderStyle.date_month_day}
                        style={{color: monthDayColor}}
                    >
                        {new Date().toLocaleString("hu-HU", { month: "long" })} {new Date().toLocaleString("hu-HU", { day: "2-digit"})}.
                    </span>
                </div>
            </div>

            <Profile
                color={user.profile_color}
                name={user.first_name}
                nameColor={nameColor}
            />
        </div>
    );
};

export default Header;