import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useDebounce from "../../../hooks/useDebounce";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";
import * as TrafficCalc from "../../../services/TrafficCalc";

import CashoutTicketsStyle from "./CashoutTickets.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import Ticket from "../../../components/ticket/Ticket";
import DefaultInput from "../../../components/defaultinput/DefaultInput";

const CashoutTickets = () => {
    const {user, stores, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [showTickets, setShowTickets] = useState([]);

    const [search, setSearch] = useState("");
    const [showSearch, setShowSearch] = useState("");

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getTickets();

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');
            setShowTickets(tickets.filter(item => rx.test(item.serial_num)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [showSearch]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    const getTickets = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/tickets/itemized/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setTickets(data.tickets);
    };

    const handleCashout = async(item, prize) => {
        if(prize.value === 0){
            return setMsg({
                color: "#F58E8E",
                message: "Hibás kód!"
            });
        }

        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/tickets/cashout/verify/"+params.store, {state: {item: item, prize: prize, page: location.state}});
        }, 400);
        return () => clearTimeout(timer);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={12}
                    store={params.store}
                />

                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={CashoutTicketsStyle.ops_ticket_body}>
                                <span className={CashoutTicketsStyle.ops_ticket_text}>KERESÉS</span>

                                <DefaultInput
                                    val={search}
                                    change={(e) => setSearch(e.target.value)}
                                    ph="Sorozatszám"
                                    width="100%"
                                />

                                <span className={CashoutTicketsStyle.ops_ticket_text}>KERESÉS EREDMÉNYE</span>
                            
                                <div className={CashoutTicketsStyle.ops_ticket_table}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Típus</th>
                                                <th>Sorozatszám</th>
                                                <th>Nyeremény kód</th>
                                                <th>Összeg</th>
                                                <th>Műveletek</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {showTickets.length === 0 || search === "" ?
                                                <tr>
                                                    <td
                                                        colSpan={5}
                                                        style={{textAlign: "center"}}
                                                    >
                                                        A keresésnek nincs eredménye. Sorozatszám hibás vagy nem nálunk vásárolt.
                                                    </td>
                                                </tr>
                                            :
                                                showTickets.map(item => {
                                                    return (
                                                        <Ticket
                                                            key={item.id}
                                                            item={item}
                                                            colorcode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                                            onCashout={handleCashout}
                                                        />
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CashoutTickets;