import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import TrafficTicketsStyle from "./TrafficTickets.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import TicketTraffic from "../../../components/tickettraffic/TicketTraffic";
import DefaultInput from "../../../components/defaultinput/DefaultInput";
import CurrencyText from "../../../components/currencytext/CurrencyText";

const TrafficTickets = () => {
    const {user, loading, setLoading, msg, setMsg, homeRef} = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [row, setRow] = useState({
        type: 0,
        quantity: ""
    });
    const [traffic, setTraffic] = useState([]);
    const [sum, setSum] = useState(0);

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getTicketTypes();

        if(location.state?.back) {
            setTraffic(location.state.traffic);
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        setSum(traffic.reduce((a, b) => {
            return a + (b.value*b.quantity);
        }, 0));
    }, [traffic]);

    const getTicketTypes = async() => {
        const res = await fetch(apiUrl+"/tickets", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setTickets(data.tickets);
    };

    const handleAddRow = () => {
        if(row.type === 0 || row.quantity === "") {
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező!"
            });
        }

        const ticket = tickets.filter(item => item.id === Number(row.type))[0];

        setTraffic(prevTraffic => [
            ...prevTraffic,
            {
                type: row.type,
                name: ticket.name,
                quantity: row.quantity,
                value: ticket.value
            }
        ]);

        setRow({
            type: 0,
            quantity: ""
        });

        setMsg({
            color: "#C7FFEB",
            message: "Sor hozzáadva!"
        });
    };

    const handleDeleteRow = (item) => {
        setTraffic(prevTraffic => prevTraffic.filter(t => t !== item));
        setMsg({
            color: "#C7FFEB",
            message: "Sor törölve!"
        })
    };

    const handleEditRow = (item, newItem) => {
        setTraffic(prevTraffic => [...prevTraffic.filter(t => t !== item), newItem]);
        setMsg({
            color: "#C7FFEB",
            message: "Sor módosítva!"
        })
    };

    const handleAddTraffic = async() => {
        if(traffic.length === 0){
            return setMsg({
                color: "#F58E8E",
                message: "Először adj hozzá sorokat!"
            });
        }

        setMsg({});
        navigate("/tickets/traffic/verify/"+params.store, {state: traffic});
    };

    const handleBack = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/operations/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={13}
                    store={params.store}
                />

                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={TrafficTicketsStyle.ops_ticket_body}>
                                <span className={TrafficTicketsStyle.ops_ticket_text}>SORSJEGYEK HOZZÁADÁSA</span>
                            
                                <div className={TrafficTicketsStyle.ops_ticket_inputs}>
                                    <select
                                        value={row.type}
                                        onChange={(e) => setRow(prevRow => ({...prevRow, type: e.target.value}))}
                                    >
                                        <option value={0}>Sorsjegy típusa</option>
                                        
                                        {tickets.map(item => {
                                            return (
                                                <option
                                                    key={item.id}
                                                    value={item.id}
                                                >
                                                    {item.name}
                                                </option>
                                            );
                                        })}
                                    </select>

                                    <DefaultInput
                                        val={row.quantity}
                                        change={(e) => {
                                            if(!isNaN(+e.target.value)) {
                                                setRow(prevRow => ({...prevRow, quantity: e.target.value}))
                                            }
                                        }}
                                        keyDown={(e) => {
                                            if(e.key === "Enter") {
                                                handleAddRow();
                                            }
                                        }}
                                        ph="Darab"
                                        width="20%"
                                        height="7vh"
                                    />

                                    <button onClick={handleAddRow}>+</button>
                                </div>

                                <span className={TrafficTicketsStyle.ops_ticket_text}>ELADOTT SORSJEGYEK</span>
                            
                                <div className={TrafficTicketsStyle.ops_ticket_table}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Típus</th>
                                                <th>Sorsjegy értéke</th>
                                                <th>Darabszám</th>
                                                <th>Forgalmi érték</th>
                                                <th>Műveletek</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {traffic.map((item, i) => {
                                                return (
                                                    <TicketTraffic
                                                        key={i}
                                                        row={item}
                                                        tickets={tickets}
                                                        onEdit={handleEditRow}
                                                        onDelete={handleDeleteRow}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className={TrafficTicketsStyle.ops_ticket_sum}>
                                    <span style={{fontWeight: "600"}}>Összesen</span>
                                    <span>
                                        <CurrencyText
                                            val={sum}
                                        /> HUF
                                    </span>
                                </div>

                                <div className={TrafficTicketsStyle.ops_ticket_btns}>
                                    <button onClick={handleBack}>Vissza</button>
                                    <button onClick={handleAddTraffic}>Véglegesítés</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default TrafficTickets;