import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import NewTicketVerifyStyle from "./NewTicketVerify.module.css";
import HomeStyle from "../../../home/Home.module.css";
import OperationsStyle from "../../../operations/Operations.module.css";

import Nav from "../../../../layouts/nav/Nav";
import OpsHeader from "../../../../layouts/opsheader/OpsHeader";
import Header from "../../../../layouts/header/Header";

import Feedback from "../../../../components/feedback/Feedback";
import Spinner from "../../../../components/spinner/Spinner";
import SummaryNewTicket from "../../../../components/summary/summarynewticket/SummaryNewTicket";
import Btn from "../../../../components/btn/Btn";

const NewTicketVerify = () => {
    const {user, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const location = useLocation();

    const [ticket] = useState(location.state.ticket);
    const [prizes] = useState(location.state.prizes);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleBack = () => {
        navigate("/ticketinfo/prize/"+params.store, {state: {ticket: ticket, prizes: prizes}});
    };

    const handleNext = async() => {
        const res = await fetch(apiUrl+"/ticket/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                ticket: ticket,
                prizes: prizes
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet!"
        });

        navigate("/ticketinfo/manage/"+params.store, {state: {back: true}})
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={18}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={NewTicketVerifyStyle.ops_ticket_body}>
                                <span className={NewTicketVerifyStyle.ops_ticket_text}>
                                    Rögzíted az alábbi sorsjegyet?
                                </span>
                                
                                <SummaryNewTicket
                                    log={false}
                                    info={ticket}
                                />

                                <div className={NewTicketVerifyStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Véglegesítés"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNext}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default NewTicketVerify;