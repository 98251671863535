import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryStyle from "../Summary.module.css";

const SummaryPartner = ({ id, log, info }) => {
    const {setMsg} = useContext(DataContext);

    const [logData, setLogData] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log){
            getLog();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/partner/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
        return setLogData(data.partner);
    };

    return (
        <table className={SummaryStyle.summary_table}>
            <tbody>
                <tr>
                    <th style={{textAlign: "right"}}>Cégnév</th>
                    <td style={{textAlign: "left"}}>{log ? logData.name : info.name}</td>
                </tr>

                <tr>
                    <th style={{textAlign: "right"}}>Cím</th>
                    <td style={{textAlign: "left"}}>{log ? logData.address : info.address}</td>
                </tr>

                <tr>
                    <th style={{textAlign: "right"}}>Adószám</th>
                    <td style={{textAlign: "left"}}>{log ? logData.tax_number : info.tax_1+"-"+info.tax_2+"-"+info.tax_3}</td>
                </tr>

                <tr>
                    <th style={{textAlign: "right"}}>Cégjegyzék szám</th>
                    <td style={{textAlign: "left"}}>{log ? logData.check_mark : info.check_mark}</td>
                </tr>

                <tr>
                    <th style={{textAlign: "right"}}>Bankszámlaszám</th>
                    <td style={{textAlign: "left"}}>{log ? logData.bank_account : info.bank_1+"-"+info.bank_2+"-"+info.bank_3}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default SummaryPartner;