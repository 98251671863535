import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useDebounce from "../../hooks/useDebounce";

import { DataContext } from "../../context/DataContext";
import { getStoreStatus } from "../../services/Api";

import LogsStyle from "./Logs.module.css";
import OperationsStyle from "../operations/Operations.module.css";
import HomeStyle from "../home/Home.module.css";

import Nav from "../../layouts/nav/Nav";
import Header from "../../layouts/header/Header";

import LogItem from "../../components/logitem/LogItem";
import Feedback from "../../components/feedback/Feedback";
import Spinner from "../../components/spinner/Spinner";
import DefaultInput from "../../components/defaultinput/DefaultInput";

const Logs = () => {
    const {
        user,
        stores,
        msg, setMsg,
        loading, setLoading,
        homeRef,
        search, setSearch,
        showSearch, setShowSearch
    } = useContext(DataContext);

    const [logs, setLogs] = useState([]);
    const [showLogs, setShowLogs] = useState([]);

    const navigate = useNavigate();

    const params = useParams();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/");

        getStoreStatus(params.store).then(data => {
            if(user.stores.filter(store => store.id === data.id).length === 0 || Number(data.operation) === 2) {
                return navigate("/home");
            }
        });

        setSearch("");
        setShowSearch("");

        getStoreLog();

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');
            setShowLogs(logs.filter(item => rx.test(item.op_name) || rx.test(item.last_name+" "+item.first_name)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [showSearch, logs]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    const getStoreLog = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/dailylog", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setLogs(data.logs);
    };

    const handleGoToOps = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/operations/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header 
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <div className={OperationsStyle.ops_content_header}>
                    <button
                        onClick={handleGoToOps}
                    >
                        Új esemény hozzáadása
                    </button>

                    <button
                        className={OperationsStyle.ops_nav_active}
                        onClick={() => navigate("/logs/"+params.store)}
                    >
                        Esemény napló
                    </button>
                </div>

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.ops_content}>
                            <div className={LogsStyle.ops_logs_body}>
                                <DefaultInput
                                    ph="Keresés" 
                                    val={search}
                                    change={(e) => setSearch(e.target.value)}
                                    style={{width: "100%"}}
                                />

                                <table className={LogsStyle.ops_list_table}>
                                    <thead>
                                        <tr>
                                            <th>Dátum</th>
                                            <th>Idő</th>
                                            <th style={{textAlign: "center"}}>Művelet</th>
                                            <th>Leírás</th>
                                            <th>Telephely</th>
                                            <th style={{textAlign: "center"}}>Felhasználó</th>
                                            <th style={{textAlign: "center"}}>Művelet</th>
                                        </tr>
                                    </thead>
                                    {showLogs.length === 0 ?
                                        <tbody>
                                            <tr>
                                                <td
                                                    className={LogsStyle.ops_list_table_none}
                                                    colSpan={5}
                                                >
                                                    Nem található ilyen esemény
                                                </td>
                                            </tr>
                                        </tbody>
                                    :
                                        <tbody>
                                            {showLogs.map((l, i) => {
                                                return (
                                                    <LogItem
                                                        key={i}
                                                        log={l}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>   
                    </div>
                }
            </div>
        </div>
    );
};

export default Logs;