import { useContext, useEffect, useRef, useState } from "react";

import { DataContext } from "../../context/DataContext";

import TicketStyle from "./Ticket.module.css";

import CurrencyText from "../currencytext/CurrencyText";

const Ticket = ({ item, colorcode, onCashout }) => {
    const {setMsg} = useContext(DataContext);

    const [prizeCode, setPrizeCode] = useState({
        a: "",
        b: "",
        c: ""
    });
    const [prize, setPrize] = useState({
        id: 0,
        code: "",
        value: 0
    });

    const [prizeList, setPrizeList] = useState([]);

    const aRef = useRef();
    const bRef = useRef();
    const cRef = useRef();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getPrizeList();
    }, []);

    useEffect(() => {
        const code = prizeCode.a + prizeCode.b + prizeCode.c;
        const row = prizeList.filter(p => p.prize_code === code);

        if(row.length !== 0){
            return setPrize({
                id: row[0].id,
                code: code,
                value: row[0].prize_value
            });
        }

        setPrize({
            id: 0,
            code: "",
            value: 0
        });
    }, [prizeCode]);

    const getPrizeList = async() => {
        const res = await fetch(apiUrl+"/tickets/prize/"+item.ticket, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPrizeList(data.prize);
    };

    const renderTicket = () => {
        if(item.prize_code === null) {
            return (
                <tr className={TicketStyle.ticket_row}>
                    <td>{item.name}</td>
                    <td>{item.serial_num}</td>
                    <td>
                        <input
                            type="text"
                            style={{borderBottom: "2px solid "+colorcode}}
                            ref={aRef}
                            value={prizeCode.a}
                            onChange={(e) => {
                                if(prizeCode.a.length === 0){
                                    setPrizeCode(prevCode => ({...prevCode, a: e.target.value.toUpperCase()}));
                                    bRef.current.focus();
                                }
                                else if(e.target.value === ""){
                                    setPrizeCode(prevCode => ({...prevCode, a: e.target.value.toUpperCase()}));
                                }
                            }}
                        />
        
                        <input
                            type="text"
                            style={{borderBottom: "2px solid "+colorcode}}
                            ref={bRef}
                            value={prizeCode.b}
                            onChange={(e) => {
                                if(prizeCode.b.length === 0){
                                    setPrizeCode(prevCode => ({...prevCode, b: e.target.value.toUpperCase()}));
                                    cRef.current.focus();
                                }
                                else if(e.target.value === ""){
                                    setPrizeCode(prevCode => ({...prevCode, b: e.target.value.toUpperCase()}));
                                    aRef.current.focus();
                                }
                            }}
                        />
        
                        <input
                            type="text"
                            style={{borderBottom: "2px solid "+colorcode}}
                            ref={cRef}
                            value={prizeCode.c}
                            onChange={(e) => {
                                if(prizeCode.c.length === 0){
                                    setPrizeCode(prevCode => ({...prevCode, c: e.target.value.toUpperCase()}));
                                }
                                else if(e.target.value === ""){
                                    setPrizeCode(prevCode => ({...prevCode, c: e.target.value.toUpperCase()}));
                                    bRef.current.focus();
                                }
                            }}
                            onKeyDown={(e) => {
                                if(e.key === "Enter") {
                                    onCashout(item, prize);
                                }
                            }}
                        />
                    </td>
                    <td>
                        <CurrencyText
                            val={prize.value}
                        /> HUF
                    </td>
                    <td>
                        <button
                            onClick={() => onCashout(item, prize)}
                        >
                            KIFIZETÉS
                        </button>
                    </td>
                </tr>
            );
        }
        else {
            return (
                <tr className={TicketStyle.ticket_row}>
                    <td>{item.name}</td>
                    <td>{item.serial_num}</td>
                    <td>{item.pcText}</td>
                    <td>
                        <CurrencyText
                            val={item.pValue}
                        /> HUF
                    </td>
                    <td>KIFIZETVE</td>
                </tr>
            );
        }
    };

    return (
        <>{renderTicket()}</>
    );
};

export default Ticket;