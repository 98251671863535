import { useEffect, useState } from "react";

import StoreFilterStyle from "./StoreFilter.module.css";

import StorePic from "../../storepic/StorePic";
import Btn from "../../btn/Btn";

const StoreFilter = ({ stores, onChoose }) => {
    const [choosen, setChoosen] = useState([]);

    useEffect(() => {
        setChoosen(stores.filter(s => s.ok));
    }, [])

    const handleOK = (store) => {
        if(choosen.includes(store)) {
            setChoosen(prevChoosen => prevChoosen.filter(s => s.id !== store.id));
        }
        else {
            setChoosen(prevChoosen => ([...prevChoosen, store]));
        }
    };

    const handleChoose = () => {
        onChoose(choosen);
    }

    return (
        <div className={StoreFilterStyle.stores}>
            {stores.map(s => {
                return (
                    <div
                        key={s.id}
                        className={StoreFilterStyle.store}
                        style={{cursor: "pointer"}}
                    >
                        <StorePic
                            store={s}
                            click={() => handleOK(s)}
                            filter={true}
                            choosen={choosen.includes(s)}
                        />
                    </div>
                );
            })}

            <Btn
                text="Alkalmaz"
                bg="#2E89CC"
                color="#FFFFFF"
                click={handleChoose}
            />
        </div>
    );
};

export default StoreFilter;