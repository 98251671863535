import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DataContext } from "../../context/DataContext";
import { getStoreStatus } from "../../services/Api";

import NavStyle from "./Nav.module.css";
import HomeStyle from "../../pages/home/Home.module.css";

import LogoImg from "../../assets/logo.svg";
import MenuImg from "../../assets/nav/menu.svg";
import HomeImg from "../../assets/nav/home.svg";
import ShopImg from "../../assets/nav/shop.svg";
import DashboardImg from "../../assets/nav/dashboard.svg";
import OperationsImg from "../../assets/nav/operations.svg";
import SettingsImg from "../../assets/nav/settings.svg";
import LogoutImg from "../../assets/nav/logout.svg";

const Nav = ({ active }) => {
    const {user, setUser, stores, close, setClose, homeRef, setMsg} = useContext(DataContext);

    const [anim, setAnim] = useState(false);

    const navigate = useNavigate();

    // useEffect with useRef (this way) => FLICKERING (don't use it)

    /* useEffect(() => {
        if(open){
            navRef.current.className = NavStyle.navbar;
            logoRef.current.className = NavStyle.nav_logo;
            textRef.current.className = NavStyle.nav_text;
        }
        else {
            navRef.current.className = NavStyle.navbar_closed;
            logoRef.current.className = NavStyle.nav_logo_closed;
        }
    }, []); */

    const handleLogout = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setUser();
            setMsg({});
            navigate("/");
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleGoHome = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/home");
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleGoToShop = () => {
        user.stores.map(store => {
            const storeName = stores.filter(s => s.id === store.id)[0]?.name;

            if(storeName){
                getStoreStatus(storeName).then(data => {
                    if(Number(data.uid) === user.id && Number(data.operation) !== 2){
                        homeRef.current.className = HomeStyle.content_body_exit;
    
                        const timer = setTimeout(() => {
                            setMsg({});
                            navigate("/operations/"+storeName);
                        }, 400);
                        return () => clearTimeout(timer);
                    }
                });
            }
        });
    };

    const handleAnimateNav = () => {
        setClose(prevClose => !prevClose);
        setAnim(true);
    };

    const renderNavBar = () => {
        if(anim) {
            return (
                <div className={close ? NavStyle.navbar_closed_anim : NavStyle.navbar_anim}>
                    <img
                        src={MenuImg}
                        alt="Menu"
                        className={NavStyle.navimg}
                        onClick={handleAnimateNav}
                    />

                    <img
                        src={LogoImg}
                        alt="Logo"
                        className={close ? NavStyle.nav_logo_closed_anim : NavStyle.nav_logo_anim}
                    />

                    <div className={NavStyle.nav_body}>
                        <div className={NavStyle.nav_menu_content}>
                            <div
                                className={active === "home" ? NavStyle.nav_item_active : NavStyle.nav_item}
                                onClick={handleGoHome}
                            >
                                <img 
                                    src={HomeImg}
                                    alt="Home"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text_anim}>HOME</span>}
                            </div>

                            <div
                                className={active === "shop" ? NavStyle.nav_item_active : NavStyle.nav_item}
                                onClick={handleGoToShop}
                            >
                                <img 
                                    src={ShopImg}
                                    alt="Shop"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text_anim}>ELADÓTÉR</span>}
                            </div>

                            <div
                                className={active === "dashboard" ? NavStyle.nav_item_active : NavStyle.nav_item}
                                onClick={() => navigate("/home")}
                            >
                                <img
                                    src={DashboardImg}
                                    alt="Dashboard"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text_anim}>DASHBOARD</span>}
                            </div>

                            <div
                                className={active === "operations" ? NavStyle.nav_item_active : NavStyle.nav_item}
                                onClick={() => navigate("/home")}
                            >
                                <img
                                    src={OperationsImg}
                                    alt="Operations"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text_anim}>MŰVELETEK</span>}
                            </div>
                        </div>
                        
                        <div className={NavStyle.nav_settings_content}>
                            <div
                                className={active === "settings" ? NavStyle.nav_item_active : NavStyle.nav_item}
                                onClick={() => navigate("/home")}
                            >
                                <img
                                    src={SettingsImg}
                                    alt="Settings"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text_anim}>BEÁLLÍTÁSOK</span>}
                            </div>

                            <div
                                className={NavStyle.nav_item}
                                onClick={handleLogout}
                            >
                                <img
                                    src={LogoutImg}
                                    alt="Logout"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text_anim}>KIJELENTKEZÉS</span>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className={close ? NavStyle.navbar_closed : NavStyle.navbar}>
                    <img
                        src={MenuImg}
                        alt="Menu"
                        className={NavStyle.navimg}
                        onClick={handleAnimateNav}
                    />

                    <img
                        src={LogoImg}
                        alt="Logo"
                        className={close ? NavStyle.nav_logo_closed : NavStyle.nav_logo}
                    />

                    <div className={NavStyle.nav_body}>
                        <div className={NavStyle.nav_menu_content}>
                            <div
                                className={active === "home" ? NavStyle.nav_item_active : NavStyle.nav_item}
                                onClick={handleGoHome}
                            >
                                <img 
                                    src={HomeImg}
                                    alt="Home"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text}>HOME</span>}
                            </div>

                            <div
                                className={active === "shop" ? NavStyle.nav_item_active : NavStyle.nav_item}
                                onClick={handleGoToShop}
                            >
                                <img 
                                    src={ShopImg}
                                    alt="Shop"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text}>ELADÓTÉR</span>}
                            </div>

                            <div
                                className={active === "dashboard" ? NavStyle.nav_item_active : NavStyle.nav_item}
                                onClick={() => navigate("/home")}
                            >
                                <img
                                    src={DashboardImg}
                                    alt="Dashboard"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text}>DASHBOARD</span>}
                            </div>

                            <div
                                className={active === "operations" ? NavStyle.nav_item_active : NavStyle.nav_item}
                                onClick={() => navigate("/home")}
                            >
                                <img
                                    src={OperationsImg}
                                    alt="Operations"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text}>MŰVELETEK</span>}
                            </div>
                        </div>
                        
                        <div className={NavStyle.nav_settings_content}>
                            <div
                                className={active === "settings" ? NavStyle.nav_item_active : NavStyle.nav_item}
                                onClick={() => navigate("/home")}
                            >
                                <img
                                    src={SettingsImg}
                                    alt="Settings"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text}>BEÁLLÍTÁSOK</span>}
                            </div>

                            <div
                                className={NavStyle.nav_item}
                                onClick={handleLogout}
                            >
                                <img
                                    src={LogoutImg}
                                    alt="Logout"
                                />

                                {close ? <></> : <span className={NavStyle.nav_text}>KIJELENTKEZÉS</span>}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    return renderNavBar();
};

export default Nav;