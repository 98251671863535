import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus, getUserCompanies } from "../../../services/Api";

import CreateUserStyle from "../createuser/CreateUser.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import ProfileImg from "../../../assets/header/profile.svg";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import UserColor from "../../../components/usercolor/UserColor";

import Header from "../../../layouts/header/Header";
import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Btn from "../../../components/btn/Btn";

const ModifyUser = () => {
    const {
        user,
        stores,
        msg, setMsg,
        loading, setLoading,
        profile, setProfile,
        homeRef
    } = useContext(DataContext);

    const [colors] = useState([
        "#FF0000",
        "#00FF00",
        "#0000FF",
        "#4287F5",
        "#DDFF00",
        "#FF00E6",
        "#FF7300",
        "#00FFE1",
        "#22FF00"
    ]);
    const [ranks, setRanks] = useState([]);
    const [companies, setCompanies] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getRanks();
        getUserCompanies(user.id).then(data => {
            setCompanies(data);
        });

        setLoading(false);
    }, []);

    const getRanks = async() => {
        const res = await fetch(apiUrl+"/ranks", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setRanks(data.ranks.filter(r => user.rank >= r.id));
    };

    const handleColorChoose = (color) => {
        setProfile(prevProfile => ({...prevProfile, color: color}));
    };

    const handleModify = async() => {
        if(Object.values(profile).some(prop => prop === "0" || prop === "")) {
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        const store = stores.filter(s => s.name = params.store)[0];

        const res = await fetch(apiUrl+"/user/modify", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id,
                profile: profile
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet!"
        });
        navigate("/user/manage/"+params.store, {state: {back: true}});
    };

    const handleBack = () => {
        setMsg({});
        navigate("/user/manage/"+params.store, {state: {back: true}});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={14}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={CreateUserStyle.ops_create_body}>
                                <span className={CreateUserStyle.ops_create_text}>Személyes adatok</span>
                            
                                <div className={CreateUserStyle.ops_create_stats}>
                                    <div className={CreateUserStyle.ops_stats_profile}>
                                        <div
                                            className={CreateUserStyle.ops_profile_img}
                                            style={{borderColor: profile.color}}
                                        >
                                            <img
                                                src={ProfileImg}
                                                alt="Profile"
                                            />
                                        </div>

                                        <button>Feltöltés</button>

                                        <div className={CreateUserStyle.ops_profile_colors}>
                                            {colors.map((col, i) => {
                                                return (
                                                    <UserColor
                                                        key={i}
                                                        color={col}
                                                        onChoose={handleColorChoose}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <div className={CreateUserStyle.ops_stats_inputs}>
                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <select
                                                value={profile.rank}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, rank: e.target.value}))}
                                            >
                                                <option value={0}>Rang</option>
                                                
                                                {ranks.map(item => {
                                                    return (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>

                                            <select
                                                value={profile.company}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, company: e.target.value}))}
                                            >
                                                <option value={0}>Szervezet</option>
                                                
                                                {companies.map(item => {
                                                    return (
                                                        <option
                                                            key={item.id}
                                                            value={item.id}
                                                        >
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>

                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <input
                                                type="text"
                                                placeholder="Vezetéknév"
                                                value={profile.lastName}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, lastName: e.target.value}))}
                                            />

                                            <input
                                                type="text"
                                                placeholder="Keresztnév"
                                                value={profile.firstName}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, firstName: e.target.value}))}
                                            />

                                            <input
                                                type="text"
                                                placeholder="Becenév"
                                                value={profile.nickName}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, nickName: e.target.value}))}
                                            />
                                        </div>

                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <input
                                                type="text"
                                                placeholder="Születési hely"
                                                value={profile.birthPlace}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, birthPlace: e.target.value}))}
                                            />

                                            <input
                                                type="text"
                                                placeholder="Születési idő"
                                                style={{cursor: "default"}}
                                                onFocus={(e) => e.target.type = "date"}
                                                onBlur={(e) => e.target.type = "text"}
                                                value={profile.birthDate}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, birthDate: e.target.value}))}
                                            />

                                            <input
                                                type="text"
                                                placeholder="Névnap"
                                                style={{cursor: "default"}}
                                                onFocus={(e) => e.target.type = "date"}
                                                onBlur={(e) => e.target.type = "text"}
                                                value={profile.nameDay}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, nameDay: e.target.value}))}
                                            />
                                        </div>

                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <input
                                                type="text"
                                                placeholder="Irányítószám"
                                                value={profile.zip}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, zip: e.target.value}))}
                                            />

                                            <input
                                                type="text"
                                                placeholder="Város"
                                                value={profile.city}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, city: e.target.value}))}
                                            />

                                            <input
                                                type="text"
                                                placeholder="Cím"
                                                value={profile.address}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, address: e.target.value}))}
                                            />
                                        </div>

                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <input
                                                type="text"
                                                placeholder="TAJ szám"
                                                value={profile.social}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, social: e.target.value}))}
                                            />

                                            <input
                                                type="text"
                                                placeholder="Személyi igazolvány szám"
                                                value={profile.personalId}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, personalId: e.target.value}))}
                                            />

                                            <input
                                                type="text"
                                                placeholder="Adószám"
                                                value={profile.tax}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, tax: e.target.value}))}
                                            />
                                        </div>

                                        <input
                                            type="text"
                                            placeholder="Bankszámlaszám"
                                            style={{width: "100%"}}
                                            value={profile.bank}
                                            onChange={(e) => setProfile(prevProfile => ({...prevProfile, bank: e.target.value}))}
                                        />

                                        <input
                                            type="text"
                                            placeholder="Email cím"
                                            style={{width: "100%"}}
                                            value={profile.email}
                                            onChange={(e) => setProfile(prevProfile => ({...prevProfile, email: e.target.value}))}
                                        />

                                        <div className={CreateUserStyle.ops_inputs_row}>
                                            <input
                                                type="text"
                                                placeholder="Orvosi alkamassági"
                                                style={{cursor: "default"}}
                                                onFocus={(e) => e.target.type = "date"}
                                                onBlur={(e) => e.target.type = "text"}
                                                value={profile.medical}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, medical: e.target.value}))}
                                            />

                                            <input
                                                type="text"
                                                placeholder="Tüdőszűrő"
                                                style={{cursor: "default"}}
                                                onFocus={(e) => e.target.type = "date"}
                                                onBlur={(e) => e.target.type = "text"}
                                                value={profile.lungScreen}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, lungScreen: e.target.value}))}
                                            />

                                            <input
                                                type="text"
                                                placeholder="Munkaszerződés kezdete"
                                                style={{cursor: "default"}}
                                                onFocus={(e) => e.target.type = "date"}
                                                onBlur={(e) => e.target.type = "text"}
                                                value={profile.companyIn}
                                                onChange={(e) => setProfile(prevProfile => ({...prevProfile, companyIn: e.target.value}))}
                                            />
                                        </div>

                                        <div className={CreateUserStyle.btns}>
                                            <Btn
                                                text="Vissza"
                                                bg="#FFFFFF"
                                                color="#7D7D7D"
                                                hoverBg="#DBDBDB"
                                                hoverColor="#7D7D7D"
                                                click={handleBack}
                                            />

                                            <Btn
                                                text="Véglegesítés"
                                                bg="#2E89CC"
                                                color="#FFFFFF"
                                                hoverBg="#0D2646"
                                                hoverColor="#FFFFFF"
                                                click={handleModify}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ModifyUser;