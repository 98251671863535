import UserColorStyle from "./UserColor.module.css";

const UserColor = ({ color, onChoose }) => {
    return (
        <div
            className={UserColorStyle.user_color}
            style={{background: color}}
            onClick={() => onChoose(color)}
        >
        </div>
    );
};

export default UserColor;