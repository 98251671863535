import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";
import * as TrafficCalc from "../../../services/TrafficCalc";

import ChangeOpsStyle from "../../change/changeops/ChangeOps.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";
import DailyProgress from "../../../layouts/dailyprogress/DailyProgress";

import TrafficItem from "../../../components/trafficitem/TrafficItem";
import Spinner from "../../../components/spinner/Spinner";
import Btn from "../../../components/btn/Btn";
import TrafficOpsTable from "../../../components/trafficopstable/TrafficOpsTable";
import Feedback from "../../../components/feedback/Feedback";
import TrafficLogTable from "../../../components/trafficlogtable/TrafficLogTable";

const CloseOps = () => {
    const {user, stores, traffic, loading, setLoading, homeRef, msg, setMsg} = useContext(DataContext);

    const [showTraffic, setShowTraffic] = useState([]);
    const [itemizedNav, setItemizedNav] = useState(1);

    const [cashIn, setCashIn] = useState([]);
    const [cashOut, setCashOut] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getCashIn();
        getCashOut();

        setLoading(false);
    }, []);

    useEffect(() => {
        if(itemizedNav === 1) {
            setShowTraffic(traffic.filter(row => row.receipt_num_1.split("/")[0] === "BE"));
        }
        else if(itemizedNav === 2) {
            setShowTraffic(traffic.filter(row => row.receipt_num_1.split("/")[0] === "KI"));
        }
        else if(itemizedNav === 3) {
            setShowTraffic(traffic.filter(row => row.receipt_num_1.split("/")[0] === "G"));
        }
    }, [itemizedNav]);

    const getCashIn = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/cashin/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setCashIn(data.cashIn);
    };

    const getCashOut = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/cashout/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setCashOut(data.cashOut);
    };

    const renderLogItems = () => {
        if(itemizedNav === 1) {
            return (
                cashIn.map((item, i) => {
                    return (
                        <TrafficLogTable
                            key={i}
                            item={item}
                        />
                    );
                })
            );
        }
        else if(itemizedNav === 2) {
            return(
                cashOut.map((item, i) => {
                    return (
                        <TrafficLogTable
                            key={i}
                            item={item}
                        />
                    );
                })
            );
        }
    };

    const handleBack = () => {
        navigate("/closesum/"+params.store, {state: {back: true}});
    };

    const handleNext = () => {
        navigate("/closeinput/"+params.store);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                <OpsHeader
                    operation={2}
                    store={params.store}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={ChangeOpsStyle.ops_change_body}>
                                <DailyProgress
                                    close={true}
                                    active={3}
                                />

                                <div className={ChangeOpsStyle.ops_change_header}>
                                    <span>Ellenőrízd le a pénzmozgásokat és a hozzájuk tartozó műveleteket, amennyiben rendben van mehetsz tovább</span>

                                    <div className={ChangeOpsStyle.ops_change_btns}>
                                        <Btn
                                            text="Vissza"
                                            bg="#FFFFFF"
                                            color="#7D7D7D"
                                            hoverBg="#DBDBDB"
                                            hoverColor="#7D7D7D"
                                            click={handleBack}
                                        />

                                        <Btn
                                            text="Tovább"
                                            bg="#2E89CC"
                                            color="#FFFFFF"
                                            hoverBg="#0D2646"
                                            hoverColor="#FFFFFF"
                                            click={handleNext}
                                        />
                                    </div>
                                </div>

                                <div className={ChangeOpsStyle.ops_change_sum}>
                                    <TrafficItem
                                        colorCode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                        value={TrafficCalc.getTrafficCashIn(traffic)}
                                    />

                                    <TrafficItem
                                        colorCode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                        value={TrafficCalc.getTrafficCashOut(traffic)}
                                    />

                                    <TrafficItem
                                        colorCode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                        value={TrafficCalc.getReturnSum(traffic)}
                                    />
                                </div>

                                <div className={ChangeOpsStyle.nav_itemized}>
                                    <button
                                        onClick={() => setItemizedNav(1)}
                                        style={itemizedNav === 1 ? {borderBottom: "1px solid #959595", fontWeight: "700"} : {}}
                                    >
                                        Bevételek összesen
                                    </button>

                                    <button
                                        onClick={() => setItemizedNav(2)}
                                        style={itemizedNav === 2 ? {borderBottom: "1px solid #959595", fontWeight: "700"} : {}}
                                    >
                                        Kiadások összesen
                                    </button>

                                    <button
                                        onClick={() => setItemizedNav(3)}
                                        style={itemizedNav === 3 ? {borderBottom: "1px solid #959595", fontWeight: "700"} : {}}
                                    >
                                        Visszaárú összesen
                                    </button>
                                </div>
                                
                                <div className={ChangeOpsStyle.ops_change_itemized}>
                                    <span className={ChangeOpsStyle.itemized_quantity}>
                                        Tételek: {itemizedNav === 1 ? cashIn.length : itemizedNav === 2 ? cashOut.length : showTraffic.length}/{showTraffic.length}
                                    </span>

                                    <div className={ChangeOpsStyle.itemized}>
                                        <div className={ChangeOpsStyle.itemized_log}>
                                            {renderLogItems()}
                                        </div>

                                        <div className={ChangeOpsStyle.itemized_table}>
                                            {showTraffic.map((item, i) => {
                                                return (
                                                    <TrafficOpsTable
                                                        key={i}
                                                        item={item}
                                                        page={itemizedNav}
                                                    />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CloseOps;