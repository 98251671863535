import { useState } from "react";

import ProfileStyle from "./Profile.module.css";

import ProfilePic from "../profilepic/ProfilePic";
import Bubble from "../bubble/Bubble";

const Profile = ({ color, name, beforeColor, beforeName, nameColor, bubble, click, choosen, filter }) => {
    const [hover, setHover] = useState(false);

    if(beforeName) {
        return (
            <div className={ProfileStyle.profiles}>
                <div className={ProfileStyle.profile}>
                    <ProfilePic
                        colorCode={color}
                    />
        
                    <span style={{color: nameColor}}>{name}</span>
                </div>

                <div className={ProfileStyle.profile}>
                    <ProfilePic
                        colorCode={beforeColor}
                    />

                    <span style={{color: nameColor}}>{beforeName}</span>
                </div>
            </div>
        );
    }
    else {
        if(bubble) {
            return (
                <div
                    className={ProfileStyle.profile}
                    onClick={click}
                >
                    <ProfilePic
                        colorCode={color}
                        onHover={() => setHover(true)}
                        onUnHover={() => setHover(false)}
                        filter={filter}
                        choosen={choosen}
                    />
        
                    {hover ? 
                        <Bubble
                            mode={2}
                            text={name}
                        />
                    :
                        <></>
                    }
                </div>
            );
        }
        else {
            return (
                <div
                    className={ProfileStyle.profile}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={click}
                >
                    <ProfilePic
                        colorCode={color}
                        filter={filter}
                        choosen={choosen}
                    />
        
                    <span style={{color: nameColor}}>{name}</span>
                </div>
            );
        }
    }
};

export default Profile;