import ValueInputStyle from "./ValueInput.module.css";

const ValueInput = ({ before, after, width, colorCode, val, selfRef, nextRef, onValueChange }) => {
    return (
        <div className={ValueInputStyle.pc_input}>
            <span>{before}</span>
            <input
                type="text"
                style={{width: width, color: colorCode}}
                ref={selfRef}
                value={val}
                onChange={(e) => {
                    if(!isNaN(+e.target.value)) {
                        onValueChange(e.target.value);
                    }
                }}
                onKeyDown={(e) => {
                    if(e.key === "Enter") {
                        nextRef.current.focus();
                    }
                }}
                onFocus={(e) => e.target.select()}
            />
            <span>{after}</span>
        </div>
    );
};

export default ValueInput;