import { useEffect, useState } from "react";

import ProfileFilterStyle from "./ProfileFilter.module.css";

import Profile from "../../profile/Profile";
import Btn from "../../btn/Btn";

const ProfileFilter = ({ profiles, onChoose }) => {
    const [choosen, setChoosen] = useState([]);

    useEffect(() => {
        setChoosen(profiles.filter(p => p.ok));
    }, [])

    const handleOK = (profile) => {
        if(choosen.includes(profile)) {
            setChoosen(prevChoosen => prevChoosen.filter(p => p.id !== profile.id));
        }
        else {
            setChoosen(prevChoosen => ([...prevChoosen, profile]));
        }
    };

    const handleChoose = () => {
        onChoose(choosen);
    }

    return (
        <div className={ProfileFilterStyle.profiles}>
            {profiles.map(p => {
                return (
                    <div
                        key={p.id}
                        className={ProfileFilterStyle.profile}
                    >
                        <Profile
                            color={p.profile_color}
                            name={p.last_name+" "+p.first_name}
                            nameColor={"#939393"}
                            bubble={true}
                            click={() => handleOK(p)}
                            filter={true}
                            choosen={choosen.includes(p)}
                        />
                    </div>
                );
            })}

            <Btn
                text="Alkalmaz"
                bg="#2E89CC"
                color="#FFFFFF"
                click={handleChoose}
            />      
        </div>
    );
};

export default ProfileFilter;