import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useDebounce from "../../../../hooks/useDebounce";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import TicketManageStyle from "./TicketManage.module.css";
import HomeStyle from "../../../home/Home.module.css";
import OperationsStyle from "../../../operations/Operations.module.css";

import Nav from "../../../../layouts/nav/Nav";
import OpsHeader from "../../../../layouts/opsheader/OpsHeader";
import Header from "../../../../layouts/header/Header";
import TicketInfoNav from "../../../../layouts/ticketinfonav/TicketInfoNav";

import Feedback from "../../../../components/feedback/Feedback";
import Spinner from "../../../../components/spinner/Spinner";
import DefaultInput from "../../../../components/defaultinput/DefaultInput";
import TicketCard from "../../../../components/ticketcard/TicketCard";
import Btn from "../../../../components/btn/Btn";

const TicketManage = () => {
    const {
        user,
        msg, setMsg,
        loading, setLoading,
        homeRef,
        search, setSearch,
        showSearch, setShowSearch
    } = useContext(DataContext);

    const [tickets, setTickets] = useState([]);
    const [showTickets, setShowTickets] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setSearch("");
        setShowSearch("");

        getTickets();

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');
            setShowTickets(tickets.filter(item => rx.test(item.name)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [tickets, showSearch]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    const getTickets = async() => {
        const res = await fetch(apiUrl+"/tickets", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setTickets(data.tickets);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={17}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={TicketManageStyle.ops_tickets}>
                                <div className={TicketManageStyle.ops_tickets_header}>
                                    <TicketInfoNav
                                        active={1}
                                    />

                                    <div className={TicketManageStyle.ops_tickets_search}>
                                        <DefaultInput
                                            value={search}
                                            change={(e) => setSearch(e.target.value)}
                                            ph="Keresés"
                                            width="100%"
                                        />

                                        <Btn
                                            text="+ Új sorsjegy rögzítése"
                                            click={() => navigate("/ticketinfo/new/info/"+params.store)}
                                            color="#FFFFFF"
                                            bg="#2E89CC"
                                            hoverColor="#FFFFFFF"
                                            hoverBg="#0D2646"
                                        />
                                    </div>
                                </div>

                                <div className={TicketManageStyle.ops_tickets_body}>
                                    {showTickets.length === 0 ?
                                        <span className={TicketManageStyle.ops_tickets_none}>
                                            A keresésnek nincs eredménye.
                                        </span>
                                    :
                                        showTickets.map(item => {
                                            return (
                                                <TicketCard
                                                    key={item.id}
                                                    ticket={item}
                                                />
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default TicketManage;