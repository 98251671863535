import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryStyle from "../Summary.module.css";

import CurrencyText from "../../currencytext/CurrencyText";

const SummaryTicketTraffic = ({ id, log, info }) => {
    const {setMsg} = useContext(DataContext);

    const [logData, setLogData] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log) {
            getLog();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/ticket/traffic/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
        return setLogData(data.traffic);
    };

    const renderSum = () => {
        if(log) {
            return logData.reduce((accumulator, object) => {
                return accumulator + (object.quantity * object.value);
            }, 0);
        }
        else {
            return info.reduce((accumulator, object) => {
                return accumulator + (object.quantity * object.value);
            }, 0);
        }
    };

    const renderTraffic = () => {
        if(log) {
            return (
                <tbody>
                    {logData.map(t => {
                        return (
                            <tr>
                                <th style={{padding: "10px"}}>{t.name}</th>
                                <td style={{padding: "10px"}}>{t.quantity} DB</td>
                                <td style={{padding: "10px"}}>
                                    <CurrencyText
                                        val={(t.quantity * t.value)}
                                    /> HUF
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td
                            colSpan={1.5}
                            style={{textAlign: "right"}}
                        >
                            Összeg
                        </td>
                        <th
                            colSpan={1.5}
                            style={{textAlign: "left"}}
                        >
                            <CurrencyText
                                val={renderSum()}
                            /> HUF
                        </th>
                    </tr>
                </tbody>
            );
        }
        else {
            return (
                <tbody>
                    {info.map(t => {
                        return (
                            <tr>
                                <th style={{padding: "10px"}}>{t.name}</th>
                                <td style={{padding: "10px"}}>{t.quantity} DB</td>
                                <td style={{padding: "10px"}}>
                                    <CurrencyText
                                        val={(t.quantity * t.value)}
                                    /> HUF
                                </td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td
                            colSpan={1.5}
                            style={{textAlign: "right"}}
                        >
                            Összeg
                        </td>
                        <th
                            colSpan={1.5}
                            style={{textAlign: "left"}}
                        >
                            <CurrencyText
                                val={renderSum()}
                            /> HUF
                        </th>
                    </tr>
                </tbody>
            );
        }
    };

    return (
        <table className={SummaryStyle.summary_table}>
            {renderTraffic()}
        </table>
    );
};

export default SummaryTicketTraffic;