import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import NewPartnerStyle from "./NewPartner.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import Header from "../../../layouts/header/Header";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";

import Spinner from "../../../components/spinner/Spinner";
import Feedback from "../../../components/feedback/Feedback";
import DefaultInput from "../../../components/defaultinput/DefaultInput";
import Btn from "../../../components/btn/Btn";

const NewPartner = () => {
    const {
        user,
        loading, setLoading,
        homeRef,
        msg, setMsg
    } = useContext(DataContext);

    const [newPart, setNewPart] = useState({
        name: "",
        short_name: "",
        address: "",
        tax_1: "",
        tax_2: "",
        tax_3: "",
        check_mark: "",
        bank_1: "",
        bank_2: "",
        bank_3: ""
    });

    const refs = {
        name: useRef(),
        shortName: useRef(),
        addr: useRef(),
        tax_1: useRef(),
        tax_2: useRef(),
        tax_3: useRef(),
        checkMark: useRef(),
        bank_1: useRef(),
        bank_2: useRef(),
        bank_3: useRef()
    };

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        if(location.state?.back) {
            setNewPart(location.state.newPart);
        }

        setLoading(false);
    }, []);

    const handleBack = () => {
        navigate("/partners/"+params.store);
    };

    const handleNext = () => {
        if(newPart.name === "" || newPart.short_name === "" || newPart.address === "" || newPart.tax_1 === "" || newPart.tax_2 === "" || newPart.tax_3 === "" || newPart.check_mark === "" || newPart.bank_1 === "" || newPart.bank_2 === "" || newPart.bank_3 === "") {
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        navigate("/newpartner/verify/"+params.store, {state: newPart});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header 
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={7}
                    store={params.store}
                />

                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={NewPartnerStyle.ops_partner_content}>
                                <div className={NewPartnerStyle.ops_partner_body}>
                                    <DefaultInput
                                        val={newPart.name}
                                        inputRef={refs.name}
                                        change={(e) => setNewPart(prevPart => ({...prevPart, name: e.target.value}))}
                                        keyDown={(e) => {
                                            if(e.key === "Enter"){
                                                refs.shortName.current.focus();
                                            }
                                        }}
                                        ph="Cégnév"
                                        width="60%"
                                    />

                                    <DefaultInput
                                        val={newPart.short_name}
                                        inputRef={refs.shortName}
                                        change={(e) => setNewPart(prevPart => ({...prevPart, short_name: e.target.value}))}
                                        ph="Rövid megnevezés"
                                        width="60%"
                                    />

                                    <DefaultInput
                                        val={newPart.address}
                                        inputRef={refs.addr}
                                        change={(e) => setNewPart(prevPart => ({...prevPart, address: e.target.value}))}
                                        keyDown={(e) => {
                                            if(e.key === "Enter"){
                                                refs.tax_1.current.focus();
                                            }
                                        }}
                                        ph="Cím"
                                        width="60%"
                                    />

                                    <div className={NewPartnerStyle.ops_partner_row}>
                                        <DefaultInput
                                            val={newPart.tax_1}
                                            inputRef={refs.tax_1}
                                            change={(e) => {
                                                if(!isNaN(+e.target.value) && e.target.value.length <= 8) {
                                                    if(e.target.value.length === 8){
                                                        refs.tax_2.current.focus();
                                                    }
                                                    setNewPart(prevPart => ({...prevPart, tax_1: e.target.value}));
                                                }
                                            }}
                                            keyDown={(e) => {
                                                if(e.key === "Enter"){
                                                    refs.tax_2.current.focus();
                                                }
                                            }}
                                            ph="Adószám 1"
                                        />

                                        <DefaultInput
                                            val={newPart.tax_2}
                                            inputRef={refs.tax_2}
                                            change={(e) => {
                                                if(!isNaN(+e.target.value) && e.target.value.length <= 1) {
                                                    if(e.target.value.length === 1){
                                                        refs.tax_3.current.focus();
                                                    }
                                                    setNewPart(prevPart => ({...prevPart, tax_2: e.target.value}));
                                                }
                                            }}
                                            keyDown={(e) => {
                                                if(e.key === "Enter"){
                                                    refs.tax_3.current.focus();
                                                }
                                            }}
                                            ph="Adószám 2"
                                        />

                                        <DefaultInput
                                            val={newPart.tax_3}
                                            inputRef={refs.tax_3}
                                            change={(e) => {
                                                if(!isNaN(+e.target.value) && e.target.value.length <= 2) {
                                                    setNewPart(prevPart => ({...prevPart, tax_3: e.target.value}));
                                                }
                                            }}
                                            keyDown={(e) => {
                                                if(e.key === "Enter"){
                                                    refs.checkMark.current.focus();
                                                }
                                            }}
                                            ph="Adószám 3"
                                        />
                                    </div>

                                    <DefaultInput
                                        val={newPart.check_mark}
                                        inputRef={refs.checkMark}
                                        change={(e) => {
                                            if(!isNaN(+e.target.value)) {
                                                setNewPart(prevPart => ({...prevPart, check_mark: e.target.value}));
                                            }
                                        }}
                                        keyDown={(e) => {
                                            if(e.key === "Enter"){
                                                refs.bank_1.current.focus();
                                            }
                                        }}
                                        ph="Cégjegyzék szám"
                                        width="60%"
                                    />

                                    <div className={NewPartnerStyle.ops_partner_row}>
                                        <DefaultInput
                                            val={newPart.bank_1}
                                            inputRef={refs.bank_1}
                                            change={(e) => {
                                                if(!isNaN(+e.target.value) && e.target.value.length <= 8) {
                                                    if(e.target.value.length === 8){
                                                        refs.bank_2.current.focus();
                                                    }
                                                    setNewPart(prevPart => ({...prevPart, bank_1: e.target.value}));
                                                }
                                            }}
                                            keyDown={(e) => {
                                                if(e.key === "Enter"){
                                                    refs.bank_2.current.focus();
                                                }
                                            }}
                                            ph="Bankszámla 1"
                                        />

                                        <DefaultInput
                                            val={newPart.bank_2}
                                            inputRef={refs.bank_2}
                                            change={(e) => {
                                                if(!isNaN(+e.target.value) && e.target.value.length <= 8) {
                                                    if(e.target.value.length === 8){
                                                        refs.bank_3.current.focus();
                                                    }
                                                    setNewPart(prevPart => ({...prevPart, bank_2: e.target.value}));
                                                }
                                            }}
                                            keyDown={(e) => {
                                                if(e.key === "Enter"){
                                                    refs.bank_3.current.focus();
                                                }
                                            }}
                                            ph="Bankszámla 2"
                                        />

                                        <DefaultInput
                                            val={newPart.bank_3}
                                            inputRef={refs.bank_3}
                                            change={(e) => {
                                                if(!isNaN(+e.target.value) && e.target.value.length <= 8) {
                                                    setNewPart(prevPart => ({...prevPart, bank_3: e.target.value}));
                                                }
                                            }}
                                            keyDown={(e) => {
                                                if(e.key === "Enter"){
                                                    handleNext();
                                                }
                                            }}
                                            ph="Bankszámla 3"
                                        />
                                    </div>

                                    <div className={NewPartnerStyle.btns}>
                                        <Btn
                                            text="Vissza"
                                            bg="#FFFFFF"
                                            color="#7D7D7D"
                                            hoverBg="#DBDBDB"
                                            hoverColor="#7D7D7D"
                                            click={handleBack}
                                        />

                                        <Btn
                                            text="Tovább"
                                            bg="#2E89CC"
                                            color="#FFFFFF"
                                            hoverBg="#0D2646"
                                            hoverColor="#FFFFFF"
                                            click={handleNext}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default NewPartner;