import { useContext, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import DateFilterStyle from "./DateFilter.module.css";

import DefaultInput from "../../defaultinput/DefaultInput";
import Btn from "../../btn/Btn";

const DateFilter = ({ onFilter }) => {
    const {setMsg} = useContext(DataContext);

    const [date, setDate] = useState({
        from: "",
        to: ""
    });

    const handleClick = () => {
        if(Object.values(date).some(prop => prop === "")) {
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        onFilter(date)
        setDate({
            from: "",
            to: ""
        })
    };

    return (
        <div className={DateFilterStyle.date_form}>
            <DefaultInput
                ph={"Ettől"}
                val={date.from}
                change={(e) => setDate(prevDate => ({...prevDate, from: e.target.value}))}
                focus={(e) => e.target.type="date"}
                blur={(e) => e.target.type="text"}
            />

            <DefaultInput
                ph={"Eddig"}
                val={date.to}
                change={(e) => setDate(prevDate => ({...prevDate, to: e.target.value}))}
                focus={(e) => e.target.type="date"}
                blur={(e) => e.target.type="text"}
            />
            
            <Btn
                text="Alkalmaz"
                bg="#2E89CC"
                color="#FFFFFF"
                click={handleClick}
            />
        </div>
    );
};

export default DateFilter;