import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import useDebounce from "../../../hooks/useDebounce";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus, getUserOperations } from "../../../services/Api";

import CreateUserPermStyle from "./CreateUserPerm.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Header from "../../../layouts/header/Header";
import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import UserStore from "../../../components/userstore/UserStore";
import UserOperation from "../../../components/useroperation/UserOperation";
import Btn from "../../../components/btn/Btn";

const CreateUserPerm = () => {
    const {
        user,
        msg, setMsg,
        loading, setLoading,
        search, setSearch,
        showSearch, setShowSearch,
        profile,
        homeRef
    } = useContext(DataContext);

    const [stores, setStores] = useState([]);
    const [ops, setOps] = useState([]);
    const [showOps, setShowOps] = useState([]);

    const [storePerms, setStorePerms] = useState([]);
    const [opPerms, setOpPerms] = useState([]);

    const params = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setSearch("");
        setShowSearch("");

        getStores();

        if(location.state){
            setOpPerms(location.state.opPerms);
            setStorePerms(location.state.storePerms);
        }
        else {
            getUserOperations(user.id).then(data => {
                setOps(data);
    
                const opArr = data.map(op => {
                    if(profile.rank >= op.op_rank){
                        return op;
                    }
                });
    
                setOpPerms(opArr.filter(data => data));
            });
        }

        setLoading(false);
    }, []);

    useEffect(() => {
        const rx = new RegExp(`${showSearch}`,'i');
        setShowOps(ops.filter(item => rx.test(item.name) || rx.test(item.description) || rx.test(item.group)));
    }, [showSearch, ops]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    const getStores = async() => {
        const res = await fetch(apiUrl+"/stores", {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setStores(data.stores.filter(s => Number(s.company) === Number(profile.company)));
    };

    const handleSelectStore = (store, ok) => {
        if(ok) {
            setStorePerms(prevPerms => [...prevPerms, store]);
        }
        else {
            setStorePerms(prevPerms => prevPerms.filter(p => p !== store));
        }
    };

    const handleSelectOperation = (op, ok) => {
        if(ok) {
            setOpPerms(prevPerms => [...prevPerms, op]);
        }
        else {
            setOpPerms(prevPerms => prevPerms.filter(p => p !== op));
        }
    };

    const handleBack = async() => {
        setMsg({});
        navigate("/user/create/"+params.store, {state: {back: true}});
    };

    const handleNext = async() => {
        setMsg({});
        navigate("/user/create/verify/"+params.store, {state: {opPerms: opPerms, storePerms: storePerms}});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={15}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={CreateUserPermStyle.ops_perm_body}>
                                <span>Telephely hozzáférés</span>

                                <div className={CreateUserPermStyle.ops_perm_stores}>
                                    {stores.map(s => {
                                        return (
                                            <UserStore
                                                key={s.id}
                                                store={s}
                                                onChoose={handleSelectStore}
                                                modify={false}
                                            />
                                        );
                                    })}
                                </div>

                                <span>Jogosultságok</span>

                                <input
                                    type="text"
                                    placeholder="Keresés"
                                    className={CreateUserPermStyle.ops_perm_search}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />

                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{textAlign: "center"}}>Ikon</th>
                                            <th>Megnevezés</th>
                                            <th>Leírás</th>
                                            <th>Műveleti csoport</th>
                                            <th>Státusz</th>
                                        </tr>
                                    </thead>
                                   <tbody>
                                        {showOps.map(op => {
                                            return (
                                                <UserOperation
                                                    key={op.operation}
                                                    op={op}
                                                    onChoose={handleSelectOperation}
                                                    initial={profile.rank >= op.op_rank}
                                                    modify={false}
                                                />
                                            );
                                        })}
                                   </tbody>
                                </table>

                                <div className={CreateUserPermStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Tovább"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNext}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CreateUserPerm;