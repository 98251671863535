import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import NewBillStyle from "../../../pages/billoperations/newbill/NewBill.module.css";
import PartnerStyle from "../../partner/Partner.module.css";

import CashImg from "../../../assets/body/ops_bill_cash_1.svg";
import TransferImg from "../../../assets/body/ops_bill_transfer_1.svg";
import CurrencyText from "../../currencytext/CurrencyText";

const SummaryBill = ({ id, log, info, partner }) => {
    const {setMsg} = useContext(DataContext);

    const [logData, setLogData] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log){
            getLog();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/bill/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
        return setLogData(data.bill);
    };

    return (
        <>
            <div className={NewBillStyle.ops_bill_choosen}>
                <div className={NewBillStyle.ops_partner_active}>
                    <div
                        className={PartnerStyle.ops_partner_icon}
                        style={log ? {color: logData.color_code, borderColor: logData.color_code} : {color: partner.color_code, borderColor: partner.color_code}}
                    >
                        <span>{log ? logData.name?.split("")[0] : partner.name[0]}</span>
                    </div>

                    <div className={NewBillStyle.ops_partner_texts}>
                        <span>Cégnév</span>
                        <span>{log ? logData.name : partner.name}</span>
                    </div>

                    <div className={NewBillStyle.ops_partner_texts}>
                        <span>Kedvezményezett számlaszáma</span>
                        <span>{log ? logData.bank_account : partner.bank_account}</span>
                    </div>

                    <div className={NewBillStyle.ops_modify_pay}>
                        <img
                            src={CashImg}
                            className={log ? (Number(logData.cash) === 1 ? PartnerStyle.ops_bill_cash_choosen : PartnerStyle.ops_bill_cash) : (info.cash === "1" ? PartnerStyle.ops_bill_cash_choosen : PartnerStyle.ops_bill_cash)}
                            alt="Cash"
                        />

                        <img
                            src={TransferImg}
                            className={log ? (Number(logData.cash) === 0 ? PartnerStyle.ops_bill_transfer_choosen : PartnerStyle.ops_bill_transfer) : (info.cash === "2" ? PartnerStyle.ops_bill_cash_choosen : PartnerStyle.ops_bill_cash)}
                            alt="Transfer"
                        />
                    </div>
                </div>
            </div>

            <div className={NewBillStyle.ops_bill_stats}>
                <div className={NewBillStyle.ops_bill_stat}>
                    <span>Teljesítés dátuma:</span>

                    <span>{log ? logData.completion_date : info.completion_date}</span>
                </div>

                <div className={NewBillStyle.ops_bill_stat}>
                    <span>Fizetési határidő:</span>

                    <span>{log ? logData.payment_deadline : info.payment_deadline}</span>
                </div>

                <div className={NewBillStyle.ops_bill_stat}>
                    <span>Számla sorszáma:</span>

                    <span>{log ? logData.bill_serial_number : info.bill_serial_number}</span>
                </div>

                <div className={NewBillStyle.ops_bill_stat}>
                    <span>Fizetendő összeg:</span>

                    <span>
                        {log ?
                            <>
                                <CurrencyText
                                    val={logData.amount}
                                /> HUF
                            </>
                        :
                            <>
                                <CurrencyText
                                    val={info.amount}
                                /> HUF
                            </>
                        }
                    </span>
                </div>
            </div>

            <div
                className={NewBillStyle.ops_bill_stat}
                style={{width: "100%", boxSizing: "border-box"}}
            >
                <span>Megjegyzés:</span>

                <span>{log ? logData.comment : info.comment}</span>
            </div>
        </>
    );
};

export default SummaryBill;