import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";
import { getStoreStatus } from "../../services/Api";
import { isPcsEmpty } from "../../services/Pcs";

import DayopenStyle from "./Dayopen.module.css";
import HomeStyle from "../home/Home.module.css";

import DayopenImg from "../../assets/body/ops_dayopen.svg";
import DiffImg from "../../assets/body/dayopen_diff.svg";

import Nav from "../../layouts/nav/Nav";
import Header from "../../layouts/header/Header";

import DailyTable from "../../components/dailytable/DailyTable";
import Spinner from "../../components/spinner/Spinner";
import Feedback from "../../components/feedback/Feedback";
import CurrencyText from "../../components/currencytext/CurrencyText";

const Dayopen = () => {
    const {user, stores, pcs, setPcs, loading, setLoading, homeRef, msg, setMsg} = useContext(DataContext);

    const [prevSum, setPrevSum] = useState(0);

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 1 || Number(store.operation) === 3) {
                return navigate("/home");
            }

            setPrevSum(store.sum);
        });

        setLoading(false);
    }, []);

    useEffect(() => {
        calculateSum();
    }, [pcs.huf5, pcs.huf10, pcs.huf20, pcs.huf50, pcs.huf100, pcs.huf200, pcs.huf500, pcs.huf1000,
        pcs.huf2000, pcs.huf5000, pcs.huf10000, pcs.huf20000]);


    const calculateSum = () => {
        const sum = ((20000*pcs.huf20000) + (10000*pcs.huf10000) + (5000*pcs.huf5000) + (2000*pcs.huf2000) + 
        (1000*pcs.huf1000) + (500*pcs.huf500) + (200*pcs.huf200) + (100*pcs.huf100) + 
        (50*pcs.huf50) + (20*pcs.huf20) + (10*pcs.huf10) + (5*pcs.huf5));

        setPcs(prevPcs => ({...prevPcs, sum: sum}));
    };

    const calculateDiff = () => {
        return pcs.sum-prevSum;
    }

    const handleGetDayClose = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/prevday", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                store: store.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        if(data.prev){
            setPcs({
                huf5: data.prev.huf_5_pcs,
                huf10: data.prev.huf_10_pcs,
                huf20: data.prev.huf_20_pcs,
                huf50: data.prev.huf_50_pcs,
                huf100: data.prev.huf_100_pcs,
                huf200: data.prev.huf_200_pcs,
                huf500: data.prev.huf_500_pcs,
                huf1000: data.prev.huf_1000_pcs,
                huf2000: data.prev.huf_2000_pcs,
                huf5000: data.prev.huf_5000_pcs,
                huf10000: data.prev.huf_10000_pcs,
                huf20000: data.prev.huf_20000_pcs,
                sum: data.prev.sum
            });
        }
    };

    const handleBack = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/home");
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleNext = () => {
        if(isPcsEmpty(pcs)){
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        setMsg({});
        navigate("/dayopen/verify/"+params.store);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="home"
            />

            <div className={HomeStyle.content}>
                <Header 
                    user={user}
                    yearColor={"#FFFFFF"}
                    monthDayColor={"#FFFFFF"}
                    nameColor={"#FFFFFF"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                {loading ? 
                    <Spinner
                        color="#FFFFFF"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={DayopenStyle.day_open_header}>
                            <div className={DayopenStyle.day_open_name}>
                                <div className={DayopenStyle.day_open_img}>
                                    <img
                                        src={DayopenImg}
                                        alt="Dayopen"
                                    />
                                </div>

                                <span className={DayopenStyle.day_open_text}>NAP NYITÁS</span>
                            </div>

                            <span
                                className={DayopenStyle.money_sum}
                                style={{color: stores.filter(s => s.name === params.store)[0].color_code_2}}
                            >
                                <CurrencyText
                                    val={pcs.sum}
                                /> <span style={{fontSize: "28px"}}>HUF</span>
                            </span>
                        </div>

                        <div className={DayopenStyle.day_open_subheader}>
                            <div className={DayopenStyle.day_open_prev} onClick={handleGetDayClose}>
                                <img 
                                    src={DiffImg}
                                    alt="Diff"
                                />
                                <span className={DayopenStyle.day_open_prev_text}>Előző záró értékek másolása</span>
                            </div>
                        
                            <span className={DayopenStyle.day_open_diff}>
                                Záró eltérés: <CurrencyText val={calculateDiff()} /> HUF
                            </span>
                        </div>
                        
                        <div className={DayopenStyle.daily}>
                            <DailyTable
                                colorcode={stores.filter(s => s.name === params.store)[0].color_code_2}
                                onEnter={handleNext}
                            />
                        </div>
                        

                        <div className={DayopenStyle.day_open_cta_content}>
                            <button
                                className={DayopenStyle.btn_back}
                                onClick={handleBack}
                            >
                                MÉGSE
                            </button>

                            <button
                                className={DayopenStyle.btn_save}
                                onClick={handleNext}
                            >
                                MENTÉS
                            </button>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Dayopen;