import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import useDebounce from "../../hooks/useDebounce";

import { io } from "socket.io-client";

import { DataContext } from "../../context/DataContext";
import { getStoreStatus, getUserOperations } from "../../services/Api";

import OperationsStyle from "./Operations.module.css";
import HomeStyle from "../home/Home.module.css";

import Nav from "../../layouts/nav/Nav";
import Header from "../../layouts/header/Header";

import Operation from "../../components/operation/Operation";
import Feedback from "../../components/feedback/Feedback";
import Spinner from "../../components/spinner/Spinner";
import DefaultInput from "../../components/defaultinput/DefaultInput";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Operations = () => {
    const {
        user, 
        msg, setMsg, 
        loading, setLoading, 
        homeRef, 
        search, setSearch,
        showSearch, setShowSearch
    } = useContext(DataContext);

    const [ops, setOps] = useState([]);
    const [showOps, setShowOps] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/");

        getStoreStatus(params.store).then(data => {
            if(user.stores.filter(store => store.id === data.id).length === 0 || Number(data.operation) === 2 || Number(data.uid) !== user.id) {
                return navigate("/home");
            }
        });

        socket.emit("join", ["uid:"+user.id, "rid:"+user.rank]);

        getUserOperations(user.id).then(data => {
            setOps(data);
        });

        setSearch("");
        setShowSearch("");

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');
            setShowOps(ops.filter(item => rx.test(item.name) || rx.test(item.description)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [showSearch, ops]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    useEffect(() => {
        socket.on("update_perm", () => {
            getUserOperations(user.id).then(data => {
                setOps(data);
            });
        });
    }, [socket]);

    const handleToOperation = (oper) => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate(oper.path+"/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    }

    const handleGoToLog = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/logs/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header 
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <div className={OperationsStyle.ops_content_header}>
                    <button
                        className={OperationsStyle.ops_nav_active}
                        onClick={() => navigate("/operations/"+params.store)}
                    >
                        Új esemény hozzáadása
                    </button>

                    <button
                        onClick={handleGoToLog}
                    >
                        Esemény napló
                    </button>
                </div>

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.ops_content}>
                            <DefaultInput
                                ph="Keresés"
                                val={search}
                                change={(e) => setSearch(e.target.value)}
                                width={"80vw"}
                            />

                            {showOps.length === 0 ?
                                <span style={{color: "#A6A6A6", marginTop: "24px"}}>Nem található ilyen művelet</span>
                            :
                                <div className={OperationsStyle.ops_list_body}>
                                    {showOps.map(op => {
                                        if(op.seq !== null){
                                            return (
                                                <Operation
                                                    key={op.id}
                                                    oper={op}
                                                    onChoose={(oper) => handleToOperation(oper)}
                                                />
                                            );
                                        }
                                    })}
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Operations;