import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import NewTicketStyle from "./NewTicket.module.css";
import HomeStyle from "../../../home/Home.module.css";
import OperationsStyle from "../../../operations/Operations.module.css";

import Nav from "../../../../layouts/nav/Nav";
import OpsHeader from "../../../../layouts/opsheader/OpsHeader";
import Header from "../../../../layouts/header/Header";

import Feedback from "../../../../components/feedback/Feedback";
import Spinner from "../../../../components/spinner/Spinner";
import Btn from "../../../../components/btn/Btn";
import DefaultInput from "../../../../components/defaultinput/DefaultInput";

const NewTicket = () => {
    const {user, msg, setMsg, homeRef, loading, setLoading} = useContext(DataContext);

    const [ticket, setTicket] = useState({
        name: "",
        value: "",
        order_quantity: "",
        prize: "",
        last_sale_date: "",
        last_cashout_date: ""
    });

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        if(location.state?.ticket) {
            setTicket(location.state.ticket);
        }

        setLoading(false);
    }, []);

    const handleNext = () => {
        if(ticket.name === "" || ticket.value === "" || ticket.order_quantity === "" || ticket.prize === "") {
            return setMsg({
                color: "#F58E8E",
                message: "Nincsenek kitöltve a kötelező mezők"
            });
        }

        navigate("/ticketinfo/new/prize/"+params.store, {state: {ticket: ticket}})
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={18}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={NewTicketStyle.ops_ticket_body}>
                                <div className={NewTicketStyle.head_title}>
                                    Alapadatok
                                </div>

                                <div className={NewTicketStyle.info_content}>
                                    <div className={NewTicketStyle.image}>
                                        <div className={NewTicketStyle.no_img}>
                                        </div>
                                        <Btn
                                            text="Kép feltöltése"
                                            color="#FFFFFF"
                                            bg="#2E89CC"
                                        />
                                    </div>
                                    

                                    <div className={NewTicketStyle.info}>
                                        <div className={NewTicketStyle.info_row}>
                                            <span>Megnevezés</span>

                                            <DefaultInput
                                                val={ticket.name}
                                                change={(e) => setTicket(prevTicket => ({...prevTicket, name: e.target.value}))}
                                                style={{width: "70%"}}
                                            />
                                        </div>

                                        <div className={NewTicketStyle.info_row}>
                                            <span>Ár</span>

                                            <DefaultInput
                                                val={ticket.value}
                                                change={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setTicket(prevTicket => ({...prevTicket, value: e.target.value}));
                                                    }
                                                }}
                                                style={{width: "40%"}}
                                            />
                                        </div>

                                        <div className={NewTicketStyle.info_row}>
                                            <span>Rendelési egység</span>

                                            <DefaultInput
                                                val={ticket.order_quantity}
                                                change={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setTicket(prevTicket => ({...prevTicket, order_quantity: e.target.value}));
                                                    }
                                                }}
                                                style={{width: "40%"}}
                                            />
                                        </div>

                                        <div className={NewTicketStyle.info_row}>
                                            <span>Főnyeremény</span>

                                            <DefaultInput
                                                val={ticket.prize}
                                                change={(e) => {
                                                    if(!isNaN(+e.target.value)) {
                                                        setTicket(prevTicket => ({...prevTicket, prize: e.target.value}));
                                                    }
                                                }}
                                                style={{width: "40%"}}
                                            />
                                        </div>

                                        <div className={NewTicketStyle.info_row}>
                                            <span>Értékesítés utolsó napja</span>

                                            <DefaultInput
                                                type="date"
                                                val={ticket.last_sale_date}
                                                change={(e) => setTicket(prevTicket => ({...prevTicket, last_sale_date: e.target.value}))}
                                                style={{width: "40%"}}
                                            />
                                        </div>

                                        <div className={NewTicketStyle.info_row}>
                                            <span>Nyeremény beváltás utolsó napja</span>

                                            <DefaultInput
                                                type="date"
                                                val={ticket.last_cashout_date}
                                                change={(e) => setTicket(prevTicket => ({...prevTicket, last_cashout_date: e.target.value}))}
                                                style={{width: "40%"}}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={NewTicketStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={() => navigate("/ticketinfo/manage/"+params.store, {state: {back: true}})}
                                    />

                                    <Btn
                                        text="Tovább"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNext}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default NewTicket;