import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus, getUserCompanies, getUserStores } from "../../../services/Api";
import * as TrafficCalc from "../../../services/TrafficCalc";

import ChangeLoginStyle from "./ChangeLogin.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Header from "../../../layouts/header/Header";
import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";

import LoginProfile from "../../../components/loginprofile/LoginProfile";
import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import Btn from "../../../components/btn/Btn";
import DailyProgress from "../../../layouts/dailyprogress/DailyProgress";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const ChangeLogin = () => {
    const {
        user, setUser, 
        stores,
        pcs, 
        traffic, 
        msg, setMsg, 
        loading, setLoading,
        homeRef
    } = useContext(DataContext);

    const [users, setUsers] = useState([]);
    const [selected, setSelected] = useState({});
    const [pw, setPw] = useState("");

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });
        
        getStoreUsers();

        setLoading(false);
    }, []);

    const getStoreUsers = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/users", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setUsers(data.users);
    };

    const handleLogin = async() => {
        if(pw === ""){
            return setMsg({
                color: "#F58E8E",
                message: "A jelszó megadása kötelező"
            });
        }

        const res = await fetch(apiUrl+"/user/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: selected.email,
                pass: pw
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        if(!location.state) {
            handleChange(data.user.id);
            handleSaveTrafficItemized();
            handleSaveTraffic();
        }
        else {
            handleFastChange(data.user.id);
        }

        getUserStores(data.user.id).then(userStores => {
            getUserCompanies(data.user.id).then(companies => {
                const store = stores.filter(s => s.name === params.store)[0];

                setUser({...data.user, store: store.id, stores: userStores, companies: companies});
            });
        });

        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            const store = stores.filter(s => s.name === params.store)[0];
            socket.emit("store_op", {id: store.id});
            
            setMsg({
                color: "#C7FFEB",
                message: "Sikeres műszak váltás!"
            });
            navigate("/operations/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleChange = async(id) => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/daily", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: id,
                user_before: user.id,
                store: store.id,
                operation: 3,
                huf5: Number(pcs.huf5),
                huf10: Number(pcs.huf10),
                huf20: Number(pcs.huf20),
                huf50: Number(pcs.huf50),
                huf100: Number(pcs.huf100),
                huf200: Number(pcs.huf200),
                huf500: Number(pcs.huf500),
                huf1000: Number(pcs.huf1000),
                huf2000: Number(pcs.huf2000),
                huf5000: Number(pcs.huf5000),
                huf10000: Number(pcs.huf10000),
                huf20000: Number(pcs.huf20000),
                sum: Number(pcs.sum)
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
    };

    const handleFastChange = async(id) => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/daily", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: id,
                user_before: user.id,
                store: store.id,
                operation: 3,
                huf5: 0,
                huf10: 0,
                huf20: 0,
                huf50: 0,
                huf100: 0,
                huf200: 0,
                huf500: 0,
                huf1000: 0,
                huf2000: 0,
                huf5000: 0,
                huf10000: 0,
                huf20000: 0,
                sum: 0
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
    };

    const handleSaveTrafficItemized = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/traffic/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id,
                traffic: traffic
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
    };

    const handleSaveTraffic = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/traffic/sum/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id,
                from: traffic[0].date,
                to: traffic[traffic.length-1].date,
                daily_sum: TrafficCalc.getTrafficSum(traffic),
                cash_in: TrafficCalc.getTrafficCashIn(traffic),
                cash_out: TrafficCalc.getTrafficCashOut(traffic),
                sum: (TrafficCalc.getTrafficSum(traffic) + TrafficCalc.getTrafficCashIn(traffic) - TrafficCalc.getTrafficCashOut(traffic)),
                return_sum: TrafficCalc.getReturnSum(traffic),
                canceled: 0,
                a_tax: TrafficCalc.getVatRateSum(traffic, 5),
                b_tax: TrafficCalc.getVatRateSum(traffic, 18),
                c_tax: TrafficCalc.getVatRateSum(traffic, 27),
                d_tax: TrafficCalc.getVatRateSum(traffic, 0),
                e_tax: 0
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
    };

    const handleBack = () => {
        if(location.state?.fast){
            setMsg({});
            navigate("/changefile/"+params.store);
        }
        else {
            setMsg({});
            navigate("/changepcs/"+params.store, {state: {back: true, corrItems: location.state.corrItems}});
        }
        
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={3}
                    store={params.store}
                />

                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={ChangeLoginStyle.ops_change_body}>
                                <DailyProgress
                                    active={6}
                                />

                                <div className={ChangeLoginStyle.ops_change_header}>
                                    <span>Válaszd ki a kollégát a listából majd írja be a jelszavát.</span>

                                    <div className={ChangeLoginStyle.ops_change_btns}>
                                        <Btn
                                            text="Vissza"
                                            bg="#FFFFFF"
                                            color="#7D7D7D"
                                            hoverBg="#DBDBDB"
                                            hoverColor="#7D7D7D"
                                            click={handleBack}
                                        />

                                        <Btn
                                            text="Váltás"
                                            bg="#2E89CC"
                                            color="#FFFFFF"
                                            hoverBg="#0D2646"
                                            hoverColor="#FFFFFF"
                                            click={handleLogin}
                                        />
                                    </div>
                                </div>
                            
                                <div className={ChangeLoginStyle.ops_change_profiles}>
                                    {users.map((u, i) => {
                                        return (
                                            <LoginProfile
                                                key={i}
                                                profile={u}
                                                isActive={selected === u}
                                                onSelected={() => setSelected(u)}
                                            />
                                        );
                                    })}
                                </div>

                                <div className={ChangeLoginStyle.ops_change_login}>
                                    <span>Jelszó megadása</span>
                                    <input
                                        type="password"
                                        value={pw}
                                        onChange={e => setPw(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ChangeLogin;