import CheckBoxStyle from "./CheckBox.module.css";

import CheckedImg from "../../assets/body/ops_checked.svg";

const CheckBox = ({ isChecked, onCheck, style }) => {
    if(isChecked) {
        return (
            <div
                className={CheckBoxStyle.checkbox_active}
                onClick={onCheck}
                style={style}
            >
                <img
                    src={CheckedImg}
                    alt="Icon"
                />
            </div>
        );
    }
    else {
        return (
            <div
                className={CheckBoxStyle.checkbox}
                onClick={onCheck}
                style={style}
            >
            </div>
        );
    }
};

export default CheckBox;