import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";
import * as XLSX from "xlsx";

import useDebounce from "../../../../hooks/useDebounce";

import { DataContext } from "../../../../context/DataContext";
import { getStoreStatus } from "../../../../services/Api";

import ManageTicketsStyle from "../ManageTickets.module.css";
import HomeStyle from "../../../home/Home.module.css";
import OperationsStyle from "../../../operations/Operations.module.css";

import Nav from "../../../../layouts/nav/Nav";
import OpsHeader from "../../../../layouts/opsheader/OpsHeader";
import Header from "../../../../layouts/header/Header";

import Feedback from "../../../../components/feedback/Feedback";
import Spinner from "../../../../components/spinner/Spinner";
import DefaultInput from "../../../../components/defaultinput/DefaultInput";
import TicketPackRow from "../../../../components/ticketpackrow/TicketPackRow";
import FilterItem from "../../../../components/filteritem/FilterItem";
import DateFilter from "../../../../components/filters/datefilter/DateFilter";
import ProfileFilter from "../../../../components/filters/profilefilter/ProfileFilter";
import ValueFilter from "../../../../components/filters/valuefilter/ValueFilter";
import TicketsNav from "../../../../layouts/ticketsnav/TicketsNav";
import CurrencyText from "../../../../components/currencytext/CurrencyText";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const WaitTickets = () => {
    const {
        user,
        stores,
        msg, setMsg,
        loading, setLoading,
        search, setSearch,
        showSearch, setShowSearch,
        homeRef
    } = useContext(DataContext);

    const [packs, setPacks] = useState([]);
    const [showPacks, setShowPacks] = useState([]);

    const [choosen, setChoosen] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const [filter, setFilter] = useState(0)

    const [profiles, setProfiles] = useState([]);
    const [profFilter, setProfFilter] = useState([]);

    const [valueFilter, setValueFilter] = useState({
        from: "",
        to: ""
    });

    const [dateFilter, setDateFilter] = useState({
        from: "",
        to: ""
    });

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        socket.emit("join", ["cid:"+stores.filter(s => s.id === user.store)[0].company]);

        setSearch("");
        setShowSearch("");

        getPackages();

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');

            const searched = packs.filter(item => rx.test(item.name) || rx.test(item.comment));
            
            let filtered = [...searched];

            if(profFilter.length !== 0) {
                const filter = profFilter.map(p => p.id);
                filtered = filtered.filter(item => filter.includes(item.user));
            }

            if(Object.values(dateFilter).some(prop => prop !== "")) {
                const from = new Date(dateFilter.from).toLocaleString("hu-HU", {year: "numeric", month: "2-digit", day: "2-digit"});
                const to = new Date(dateFilter.to).toLocaleString("hu-HU", {year: "numeric", month: "2-digit", day: "2-digit"});

                filtered = filtered.filter(item => {
                    const itemDate = new Date(item.date).toLocaleString("hu-HU", {year: "numeric", month: "2-digit", day: "2-digit"});

                    if(itemDate >= from && itemDate <= to) {
                        return item;
                    }
                });
            }

            if(Object.values(valueFilter).some(prop => prop !== "")) {
                filtered = filtered.filter(item => {
                    if(Number(item.sum) >= Number(valueFilter.from) && Number(item.sum) <= Number(valueFilter.to)) {
                        return item;
                    }
                });
            }

            setShowPacks(filtered);
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [showSearch, packs, profFilter, dateFilter, valueFilter]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    useEffect(() => {
        socket.on("update_wait", () => {
            getPackages();
        })
    }, [socket]);

    const getPackages = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/tickets/manage/packs/"+4+"/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPacks(data.packs);

        getProfiles(data.packs);
    };

    const getProfiles = async(profData) => {
        const ids = [...new Set(profData.map(t => t.user))];

        if(ids.length === 0) return;

        const res = await fetch(apiUrl+"/users", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ids: ids
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setProfiles(data.users.map(u => ({...u, ok: false})));
    };

    const handleAddPack = (pack) => {
        setChoosen(prevChoosen => [...prevChoosen, pack]);
    };

    const handleRemovePack = (pack) => {
        setChoosen(prevChoosen => prevChoosen.filter(item => item.id !== pack.id));
    };

    const handleCreditPacks = async() => {
        if(choosen.length === 0) {
            return setMsg({
                color: "#F58E8E",
                message: "Válaszd ki a csomagokat"
            });
        }

        setMsg({});
        navigate("/tickets/credit/"+params.store, {state: choosen});
    };

    const handleSelectAll = () => {
        if(selectAll) {
            setChoosen([]);
        }
        else {
            setChoosen([...showPacks]);
        }

        setSelectAll(prevSelect => !prevSelect);
    };

    const handleExportExcel = async() => {
        if(choosen.length === 0) {
            return setMsg({
                color: "#F58E8E",
                message: "Válaszd ki a csomagokat"
            });
        }

        const info = [];

        for(let i = 0; i < packs.length; i++) {
            const res = await fetch(apiUrl+"/tickets/manage/pack/info/"+packs[i].id, {
                method: "GET"
            });

            const data = await res.json();

            if(!data.success) {
                return setMsg({
                    color: "#F58E8E",
                    message: data.message
                });
            }

            const modifyLength = data.pack.filter(item => item.fault_type === 0 || item.fault_type === 1).length;

            const sum = data.pack.reduce((acc, object) => {
                return acc + object.prize_value;
            }, 0);

            const modifySum = sum - data.pack.filter(p => p.bad_cashout).reduce((acc, object) => {
                return acc + object.prize_value;
            }, 0);

            info.push({
                first_name: packs[i].first_name,
                name: packs[i].name,
                comment: packs[i].comment,
                date: packs[i].date,
                store: params.store,
                quantity: data.pack.length,
                value: sum,
                modify_length: modifyLength,
                modify_value: modifySum
            });
        }

        const format = info.map(item => {
            return {
                "Felhasználónév": item.first_name,
                "Csomagnév": item.name,
                "Megjegyzés": item.comment,
                "Dátum": item.date,
                "Telephely": params.store,
                "Darabszám": item.quantity+" db",
                "Leadási érték": item.value+" huf",
                "Módosított tételek": item.modify_length+" db",
                "Módosított érték": item.modify_value+" huf"
            };
        });

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(format);

        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, "Sorsjegy_csomagok.xlsx");
    };

    const handleFilter = (f) => {
        if(filter === f) {
            return setFilter(0);
        }
        setFilter(f);
    };

    const chooseProfileFilter = (prof) => {
        setProfiles(prevProfiles => prevProfiles.map(p => {
            if(p.id === prof.id) {
                if(p.ok) {
                    setProfFilter(prevFilters => prevFilters.filter(pFilter => pFilter.id !== p.id));
                }
                else {
                    setProfFilter(prevFilters => [...new Set([...prevFilters, p])])
                }
                return {...p, ok: !p.ok};
            }

            return p;
        }));
    };

    const handleFilterDate = (date) => {
        setDateFilter(date);
        setFilter(0);
    };

    const handleFilterValue = (val) => {
        setValueFilter(val);
        setFilter(0);
    };

    const renderFilterList = () => {
        if(filter === 0) {
            if(profFilter.length === 0 && Object.values(dateFilter).some(prop => prop === "") && Object.values(valueFilter).some(prop => prop === "")) {
                return (
                    <>
                        <span className={ManageTicketsStyle.ops_tickets_filters_title}>Szűrők</span>
                        <span className={ManageTicketsStyle.ops_tickets_filters_none}>Nincs kiválasztott szűrés. A szűrés kiválasztásához kattintson egy mezőre.</span>
                    </>
                );
            }
            else {
                return (
                    <>
                        <span className={ManageTicketsStyle.ops_tickets_filters_title}>Szűrők</span>
                        
                        <FilterItem
                            type="text"
                            text="Profil"
                            visible={profFilter.length !== 0}
                            items={profFilter.map(p => p.first_name)}
                            onRemove={() => {
                                setProfiles(prevProfiles => prevProfiles.map(p => ({...p, ok: false})));
                                setProfFilter([]);
                            }}
                        />
        
                        <FilterItem
                            type="date"
                            text="Dátum"
                            visible={Object.values(dateFilter).some(prop => prop !== "")}
                            items={dateFilter}
                            onRemove={() => {
                                setDateFilter({
                                    from: "",
                                    to: ""
                                });
                            }}
                        />
        
                        <FilterItem
                            type="cash"
                            text="Összeg"
                            visible={Object.values(valueFilter).some(prop => prop !== "")}
                            items={valueFilter}
                            onRemove={() => {
                                setValueFilter({
                                    from: "",
                                    to: ""
                                });
                            }}
                        />
                    </>
                );
            }
        }
        else if(filter === 1) {
            return (
                <>
                    <span className={ManageTicketsStyle.ops_tickets_filters_title}>Profil</span>
                    <ProfileFilter
                        profiles={profiles}
                        onChoose={chooseProfileFilter}
                    />
                </>
            );
        }
        else if(filter === 2) {
            return (
                <>
                    <span className={ManageTicketsStyle.ops_tickets_filters_title}>Dátum</span>
                    <DateFilter
                        onFilter={handleFilterDate}
                    />
                </>
            );
        }
        else if(filter === 3) {
            return (
                <>
                    <span className={ManageTicketsStyle.ops_tickets_filters_title}>Összeg</span>
                    <ValueFilter
                        onFilter={handleFilterValue}
                    />
                </>
            );
        }
    };

    const calcSum = () => {
        const sum = showPacks.reduce((acc, object) => {
            return acc + object.sum;
        }, 0);

        const bad = showPacks.reduce((acc, object) => {
            return acc + object.bad_value;
        }, 0);
        
        return sum-bad;
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={16}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={ManageTicketsStyle.ops_tickets_body}>
                                <TicketsNav
                                    active={4}
                                />

                                <div className={ManageTicketsStyle.ops_tickets_ops}>
                                    <DefaultInput
                                        value={search}
                                        change={(e) => setSearch(e.target.value)}
                                        ph="Keresés"
                                        width="50%"
                                    />

                                    <div className={ManageTicketsStyle.operations}>
                                        <span>Műveletek</span>

                                        <button onClick={handleCreditPacks}>Befizetem</button>
                                        <button onClick={handleExportExcel}>XLS report</button>
                                    </div>
                                </div>

                                <div className={ManageTicketsStyle.ops_tickets_filters}>
                                    {renderFilterList()}

                                    <div className={ManageTicketsStyle.ops_tickets_info}>
                                        <span className={ManageTicketsStyle.info_quantity}>
                                            {showPacks.length} <span>CSOMAG</span>
                                        </span>
                                        
                                        <span className={ManageTicketsStyle.info_value}>
                                            <CurrencyText
                                                val={calcSum()}
                                            /> <span>HUF</span>
                                        </span>
                                    </div>
                                </div>

                                <div className={ManageTicketsStyle.ops_tickets_table}>
                                    <table>
                                    <thead>
                                            <tr>
                                                <th style={{textAlign: "center"}}>Részletek</th>
                                                <th
                                                    style={{textAlign: "center"}}
                                                    onClick={() => handleFilter(1)}
                                                >
                                                    Elhozta
                                                </th>
                                                <th onClick={() => handleFilter(2)}>Befizetésre vár</th>
                                                <th>Csomag megnevezése</th>
                                                <th>Darabszám</th>
                                                <th
                                                    style={{textAlign: "center"}}
                                                    onClick={() => handleFilter(3)}
                                                >
                                                    Leadási érték
                                                </th>
                                                <th>Módosított tételek</th>
                                                <th>Módosított érték</th>
                                                <th>Megjegyzés</th>
                                                <th>
                                                    <span
                                                        style={{cursor: "pointer"}}
                                                        onClick={handleSelectAll}
                                                    >
                                                        Összes kijelölés
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>

                                        {showPacks.length === 0 ?
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className={ManageTicketsStyle.tickets_none}
                                                        colSpan={10}
                                                    >
                                                        Nem található ilyen csomag
                                                    </td>
                                                </tr>
                                            </tbody>
                                        :
                                            <tbody>
                                                {showPacks.map(item => {
                                                    return (
                                                        <TicketPackRow
                                                            key={item.id}
                                                            pack={item}
                                                            onChoose={handleAddPack}
                                                            onRemove={handleRemovePack}
                                                            initial={false}
                                                            selectAll={selectAll}
                                                        />
                                                    );
                                                })}
                                            </tbody>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default WaitTickets;