import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { io } from "socket.io-client";

import useDebounce from "../../hooks/useDebounce";

import { DataContext } from "../../context/DataContext";
import { getUserCompaniesStores } from "../../services/Api";

import HomeStyle from "./Home.module.css";

import Nav from "../../layouts/nav/Nav";
import Header from "../../layouts/header/Header";

import Store from "../../components/store/Store";
import Spinner from "../../components/spinner/Spinner";
import Feedback from "../../components/feedback/Feedback";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Home = () => {
    const {
        user,
        stores, setStores,
        loading, setLoading,
        homeRef,
        msg, setMsg,
        search, setSearch,
        showSearch, setShowSearch
    } = useContext(DataContext);

    const [showStores, setShowStores] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);

        socket.emit("join", ["uid:"+user.id, "rid:"+user.rank]);

        if(Object.keys(user).length === 0) return navigate("/");

        setSearch("");
        setShowSearch("");

        getUserCompaniesStores(user.id).then(data => {
            setStores(data);
        });

        setLoading(false);
    }, []);

    useEffect(() => {
        try {
            const rx = new RegExp(`${showSearch}`,'i');
            setShowStores(stores.filter(item => rx.test(item.name) || rx.test(item.address)));
        }
        catch(err) {
            setSearch("");
            setMsg({
                color: "#F58E8E",
                message: "Erre a karakterre nem lehet keresni"
            });
        }
    }, [showSearch, stores]);

    useDebounce(() => {
        setShowSearch(search);
    }, [search], 500);

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            {loading ? 
                <Spinner
                    color="#FFFFFF"
                />
            :
                <>
                    <Nav
                        active="home"
                    />

                    <div className={HomeStyle.content}>
                        <Header 
                            user={user}
                            yearColor={"#FFFFFF"}
                            monthDayColor={"#FFFFFF"}
                            nameColor={"#FFFFFF"}
                        />

                        {
                            Object.keys(msg).length !== 0 ?
                                <Feedback
                                    color={msg.color}
                                    message={msg.message}
                                />
                                :
                                <></>
                        }

                        <div
                            className={HomeStyle.content_body_anim}
                            ref={homeRef}
                        >
                            <div className={HomeStyle.home_content}>
                                <input
                                    type="text"
                                    placeholder="KERESÉS"
                                    className={HomeStyle.store_search}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                />

                                <div className={HomeStyle.content_body}>
                                    <div className={HomeStyle.stores}>
                                        <div className={HomeStyle.stores_container}>
                                            {showStores.length === 0 ?
                                                <span style={{marginTop: "24px"}}>Nem található ilyen bolt</span>
                                            :
                                                showStores.map(store => {
                                                    return (
                                                        <Store 
                                                            key={store.id}
                                                            store={store}
                                                        />
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default Home;