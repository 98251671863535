import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";
import * as TrafficCalc from "../../../services/TrafficCalc";
import { isPcsEmpty } from "../../../services/Pcs";

import CashoutSafeStyle from "./CashoutSafe.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";
import TransitItemStyle from "../../../components/transititem/TransitItem.module.css";

import CheckedImg from "../../../assets/body/ops_checked.svg";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import PcsTable from "../../../components/pcstable/PcsTable";
import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import DefaultInput from "../../../components/defaultinput/DefaultInput";
import Btn from "../../../components/btn/Btn";

const CashoutSafe = () => {
    const {user, stores, pcs, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const [serialNum, setSerialNum] = useState("");
    const [envelope, setEnvelope] = useState(false);

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleCashOut = async() => {
        if(isPcsEmpty(pcs)){
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        if(!envelope && serialNum === ""){
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/safe/cashout/verify/"+params.store, {state: {env: envelope, serialNum: serialNum}});
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleBack = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/operations/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    const renderCheckbox = () => {
        if(envelope) {
            return (
                <div
                    className={TransitItemStyle.transit_check_active}
                    onClick={() => setEnvelope(prevCheck => !prevCheck)}
                >
                    <img
                        src={CheckedImg}
                        alt="Icon"
                    />
                </div>
            );
        }
        else {
            return (
                <div
                    className={TransitItemStyle.transit_check}
                    onClick={() => setEnvelope(prevCheck => !prevCheck)}
                >
                </div>
            );
        }
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={5}
                    store={params.store}
                />

                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={CashoutSafeStyle.ops_safe_body}>
                                <span className={CashoutSafeStyle.ops_safe_text}>CÍMLETJEGYZÉK</span>

                                <PcsTable
                                    sum={0}
                                    colorcode={TrafficCalc.getStoreColorCode(params.store, stores)}
                                    card={0}
                                    text={"Összeg"}
                                    initialValues={false}
                                />

                                <div className={CashoutSafeStyle.ops_safe_storage}>
                                    <span style={{fontWeight: "900"}}>TÁROLÁS MÓDJA</span>

                                    <DefaultInput
                                        ph="Add meg a biztonsági tasak sorozatszámát"
                                        val={serialNum}
                                        change={(e) => setSerialNum(e.target.value)}
                                        keyDown={(e) => {
                                            if(e.key === "Enter"){
                                                handleCashOut();
                                            }
                                        }}
                                        width={"100%"}
                                    />

                                    <div className={CashoutSafeStyle.ops_safe_check}>
                                        {renderCheckbox()}
                                        <span>Nem használók biztonsági tasakot. Borítékot használok.</span>
                                    </div>
                                </div>

                                <div className={CashoutSafeStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Tovább"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleCashOut}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CashoutSafe;