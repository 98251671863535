import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";

import { DataContext } from "../../context/DataContext";
import { getStoreStatus } from "../../services/Api";

import DayopenVerifyStyle from "./DayopenVerify.module.css";
import DayopenStyle from "../dayopen/Dayopen.module.css";
import HomeStyle from "../home/Home.module.css";

import DayopenImg from "../../assets/body/ops_dayopen.svg";

import Nav from "../../layouts/nav/Nav";
import Header from "../../layouts/header/Header";

import Spinner from "../../components/spinner/Spinner";
import Feedback from "../../components/feedback/Feedback";
import CurrencyText from "../../components/currencytext/CurrencyText";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const DayopenVerify = () => {
    const {
        user, setUser,
        stores,
        pcs,
        loading, setLoading,
        homeRef,
        msg, setMsg
    } = useContext(DataContext);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 1 || Number(store.operation) === 3) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleDayOpen = async(e) => {
        e.preventDefault();

        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/daily", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                user_before: null,
                store: store.id,
                operation: 1,
                huf5: Number(pcs.huf5),
                huf10: Number(pcs.huf10),
                huf20: Number(pcs.huf20),
                huf50: Number(pcs.huf50),
                huf100: Number(pcs.huf100),
                huf200: Number(pcs.huf200),
                huf500: Number(pcs.huf500),
                huf1000: Number(pcs.huf1000),
                huf2000: Number(pcs.huf2000),
                huf5000: Number(pcs.huf5000),
                huf10000: Number(pcs.huf10000),
                huf20000: Number(pcs.huf20000),
                sum: Number(pcs.sum)
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setUser(prevUser => ({...prevUser, store: store.id}));

        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            socket.emit("store_op", {id: store.id});
            setMsg({
                color: "#C7FFEB",
                message: "Sikeres művelet!"
            });
            navigate("/operations/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleBack = (e) => {
        e.preventDefault();
        
        setMsg({});
        navigate("/dayopen/"+params.store, {state: {back: true}});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="home"
            />

            <div className={HomeStyle.content}>
                <Header 
                    user={user}
                    yearColor={"#FFFFFF"}
                    monthDayColor={"#FFFFFF"}
                    nameColor={"#FFFFFF"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                {loading ?
                    <Spinner
                        color="#FFFFFF"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <form className={DayopenVerifyStyle.day_open_ok_content}>
                            <div className={DayopenStyle.day_open_img}>
                                <img
                                    src={DayopenImg}
                                    alt="Dayopen"
                                />
                            </div>

                            <span className={DayopenVerifyStyle.day_open_question}>
                                Biztos elvégzed a napnyitást az alábbi összeggel?
                            </span>
                            <span className={DayopenVerifyStyle.day_open_sum}>
                                <CurrencyText
                                    val={pcs.sum}
                                /> HUF
                            </span>
                            
                            <button
                                className={DayopenVerifyStyle.btn_save}
                                onClick={(e) => handleDayOpen(e)}
                            >
                                IGEN, INDULHAT A NAP
                            </button>

                            <button
                                className={DayopenVerifyStyle.btn_back}
                                onClick={(e) => handleBack(e)}
                            >
                                MÉGSE
                            </button>
                        </form>
                    </div>
                }
            </div>
        </div>
    );
};

export default DayopenVerify;