import { useContext, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { DataContext } from "../../context/DataContext";
import { getUserCompanies, getUserStores } from "../../services/Api";

import LoginStyle from "./Login.module.css";

import LogoImg from "../../assets/logo.svg";

import Feedback from "../../components/feedback/Feedback";

const Login = () => {
    const {setUser, setClose, msg, setMsg} = useContext(DataContext);

    const [input, setInput] = useState({
        email: "",
        pass: ""
    });

    const refs = {
        emailRef: useRef(),
        passRef: useRef()
    }

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleLogin = async(e) => {
        e.preventDefault();

        if(input.email === "" || input.pass === ""){
            /*return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });*/

            refs.emailRef.current.className = LoginStyle.login_email_err;
            refs.passRef.current.className = LoginStyle.login_pass_err;
            return;
        }

        const res = await fetch(apiUrl+"/user/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: input.email,
                pass: input.pass
            })
        });

        const data = await res.json();
        
        if(!data.success){
            /*return setMsg({
                color: "#F58E8E",
                message: data.message
            });*/
            refs.emailRef.current.className = LoginStyle.login_email_err;
            refs.passRef.current.className = LoginStyle.login_pass_err;
            return;
        }

        getUserStores(data.user.id).then(stores => {
            getUserCompanies(data.user.id).then(companies => {
                setUser({...data.user, stores: stores, companies: companies});
                setClose(false);
                setMsg({});
                navigate("/home");
            });
        });
    };

    return (
        <div className={LoginStyle.container}>
            {
                Object.keys(msg).length !== 0 ?
                    <Feedback
                        color={msg.color}
                        message={msg.message}
                    />
                :
                    <></>
            }

            <div className={LoginStyle.head}>
                <img
                    src={LogoImg}
                    className={LoginStyle.logo}
                    alt="Logo"
                />
            </div>

            <div className={LoginStyle.content}>
                <form
                    className={LoginStyle.login}
                    onSubmit={handleLogin}    
                >
                    <span className={LoginStyle.login_text}>BEJELENTKEZÉS</span>

                    <input
                        type="email"
                        placeholder="Email"
                        className={LoginStyle.login_email_anim}
                        ref={refs.emailRef}
                        value={input.email}
                        onChange={
                            (e) => {
                                if(refs.emailRef.current.className === LoginStyle.login_email_err || refs.passRef.current.className === LoginStyle.login_pass_err){
                                    refs.emailRef.current.className = LoginStyle.login_email;
                                    refs.passRef.current.className = LoginStyle.login_pass;
                                }
                                setInput(prevInput => ({...prevInput, email: e.target.value}));
                            }
                        }
                    />

                    <input
                        type="password"
                        placeholder="Jelszó"
                        className={LoginStyle.login_pass_anim}
                        ref={refs.passRef}
                        value={input.pass}
                        onChange={
                            (e) => {
                                if(refs.emailRef.current.className === LoginStyle.login_email_err || refs.passRef.current.className === LoginStyle.login_pass_err){
                                    refs.emailRef.current.className = LoginStyle.login_email;
                                    refs.passRef.current.className = LoginStyle.login_pass;
                                }
                                setInput(prevInput => ({...prevInput, pass: e.target.value}));
                            }
                        }
                    />

                    <div className={LoginStyle.loginctacontent}>
                        <Link
                            className={LoginStyle.login_remined_password}
                            to="#"
                        >
                            Elfelejtett jelszó
                        </Link>
                        
                        <button
                            className={LoginStyle.login_cta_button}
                            onClick={handleLogin}
                        >
                            OK
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;