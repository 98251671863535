// Cimletjegyzek tablazat

import { useContext, useEffect, useRef } from "react";

import { DataContext } from "../../context/DataContext";

import PcsTableStyle from "./PcsTable.module.css";

import ValueInput from "../valueinput/ValueInput";
import CurrencyText from "../currencytext/CurrencyText";

const PcsTable = ({ sum, colorcode, card, text, initialValues }) => {
    const {pcs, setPcs} = useContext(DataContext);

    const refs = {
        ref20000: useRef(), 
        ref10000: useRef(), 
        ref5000: useRef(), 
        ref2000: useRef(), 
        ref1000: useRef(), 
        ref500: useRef(), 
        ref200: useRef(), 
        ref100: useRef(), 
        ref50: useRef(), 
        ref20: useRef(), 
        ref10: useRef(), 
        ref5: useRef(), 
    };

    useEffect(() => {
        if(!initialValues){
            setPcs({
                huf5: "0",
                huf10: "0",
                huf20: "0",
                huf50: "0",
                huf100: "0",
                huf200: "0",
                huf500: "0",
                huf1000: "0",
                huf2000: "0",
                huf5000: "0",
                huf10000: "0",
                huf20000: "0",
                sum: 0
            });
        }

        // refs.ref20000.current.focus();
        // Ha ezt hasznaljuk akkor nem mukodik jol a slide animacio
    }, []);

    useEffect(() => {
        calculateSum();
    }, [pcs.huf5, pcs.huf10, pcs.huf20, pcs.huf50, pcs.huf100, pcs.huf200, pcs.huf500, pcs.huf1000,
        pcs.huf2000, pcs.huf5000, pcs.huf10000, pcs.huf20000]);

    const calculateSum = () => {
        const sum = ((20000*pcs.huf20000) + (10000*pcs.huf10000) + (5000*pcs.huf5000) + (2000*pcs.huf2000) + 
        (1000*pcs.huf1000) + (500*pcs.huf500) + (200*pcs.huf200) + (100*pcs.huf100) + 
        (50*pcs.huf50) + (20*pcs.huf20) + (10*pcs.huf10) + (5*pcs.huf5));

        setPcs(prevPcs => ({...prevPcs, sum: sum}));
    };

    const calculateDiff = () => {
        return pcs.sum - sum + Number(card);
    }

    return (
        <div className={PcsTableStyle.pcs_body}>
            <div className={PcsTableStyle.pcs_content}>
                <ValueInput
                    before="20 000 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf20000}
                    selfRef={refs.ref20000}
                    nextRef={refs.ref10000}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf20000: val}))}
                />

                <ValueInput
                    before="10 000 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf10000}
                    selfRef={refs.ref10000}
                    nextRef={refs.ref5000}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf10000: val}))}
                />

                <ValueInput
                    before="5 000 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf5000}
                    selfRef={refs.ref5000}
                    nextRef={refs.ref2000}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf5000: val}))}
                />
            </div>

            <div className={PcsTableStyle.pcs_content}>
                <ValueInput
                    before="2 000 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf2000}
                    selfRef={refs.ref2000}
                    nextRef={refs.ref1000}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf2000: val}))}
                />

                <ValueInput
                    before="1 000 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf1000}
                    selfRef={refs.ref1000}
                    nextRef={refs.ref500}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf1000: val}))}
                />

                <ValueInput
                    before="500 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf500}
                    selfRef={refs.ref500}
                    nextRef={refs.ref200}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf500: val}))}
                />
            </div>

            <div className={PcsTableStyle.pcs_content}>
                <ValueInput
                    before="200 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf200}
                    selfRef={refs.ref200}
                    nextRef={refs.ref100}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf200: val}))}
                />

                <ValueInput
                    before="100 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf100}
                    selfRef={refs.ref100}
                    nextRef={refs.ref50}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf100: val}))}
                />

                <ValueInput
                    before="50 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf50}
                    selfRef={refs.ref50}
                    nextRef={refs.ref20}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf50: val}))}
                />
            </div>

            <div className={PcsTableStyle.pcs_content}>
                <ValueInput
                    before="20 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf20}
                    selfRef={refs.ref20}
                    nextRef={refs.ref10}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf20: val}))}
                />

                <ValueInput
                    before="10 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf10}
                    selfRef={refs.ref10}
                    nextRef={refs.ref5}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf10: val}))}
                />

                <ValueInput
                    before="5 HUF"
                    width="25%"
                    colorCode={colorcode}
                    val={pcs.huf5}
                    selfRef={refs.ref5}
                    nextRef={refs.ref5}
                    onValueChange={(val) => setPcs(prevPcs => ({...prevPcs, huf5: val}))}
                />
            </div>

            <div className={PcsTableStyle.pcs_sum}>
                <span>{text}</span>

                <CurrencyText
                    val={calculateDiff()}
                    style={{color: colorcode}}
                />

                <span>HUF</span>
            </div>
        </div>
    );
};

export default PcsTable;