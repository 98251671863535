// Muvelet ikonjanak komponense

import { useContext } from "react";

import { DataContext } from "../../context/DataContext";

import OpIconStyle from "./OpIcon.module.css";

const OpIcon = ({ op }) => {
    const {icons} = useContext(DataContext);

    const renderOpIcon = () => {
        if(Array.isArray(icons[op.operation-1])){
            return (
                <div className={OpIconStyle.operation_imgs} style={{paddingLeft: "8px"}}>
                    <div
                        className={OpIconStyle.operation_img}
                        style={{background: op.color_code_1}}
                    >
                        <img
                            src={icons[op.operation-1][0]}
                            alt="Icon"
                        />
                    </div>

                    <div
                        className={OpIconStyle.operation_img}
                        style={{background: op.color_code_2}}
                    >
                        <img
                            src={icons[op.operation-1][1]}
                            alt="Icon"
                        />
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className={OpIconStyle.operation_imgs}>
                    <div
                        className={OpIconStyle.operation_img}
                        style={{background: op.color_code_1, margin: "0"}}
                    >
                        <img
                            src={icons[op.operation-1]}
                            alt="Icon"
                        />
                    </div>
                </div>
                
            );
        }
    };

    return renderOpIcon();
};

export default OpIcon;