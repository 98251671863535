import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import PackTicketsStyle from "./PackTickets.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import TicketManage from "../../../components/ticketmanage/TicketManage";
import DefaultInput from "../../../components/defaultinput/DefaultInput";
import Btn from "../../../components/btn/Btn";
import CurrencyText from "../../../components/currencytext/CurrencyText";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const PackTickets = () => {
    const {
        user,
        stores,
        msg, setMsg,
        loading, setLoading,
        homeRef
    } = useContext(DataContext);

    const location = useLocation();

    const [tickets, setTickets] = useState(location.state);
    const [pack, setPack] = useState({
        name: "",
        comment: ""
    });

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleRemoveTicket = (ticket) => {
        setTickets(prevTickets => prevTickets.filter(item => item.id !== ticket.id));
    };

    const handleBack = () => {
        setMsg({});
        navigate("/tickets/manage/control/"+params.store);
    };

    const handleNext = async() => {
        if(tickets.length === 0) {
            return setMsg({
                color: "#F58E8E",
                message: "Üres csomagot nem tudsz létrehozni"
            });
        }

        if(pack.name === "") {
            return setMsg({
                color: "#F58E8E",
                message: "Add meg a csomag nevét"
            });
        }

        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/tickets/manage/delivery", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id,
                pack: pack,
                tickets: tickets
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet"
        });

        socket.emit("control_tickets", {id: store.company});

        navigate("/tickets/manage/delivery/"+params.store);
    };

    const calcSum = () => {
        return tickets.reduce((acc, object) => {
            return acc + object.prize_value;
        }, 0);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={16}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={PackTicketsStyle.ops_tickets_body}>
                                <span className={PackTicketsStyle.ops_tickets_text}>
                                    Csomag leadása SZRT részére
                                </span>

                                <DefaultInput
                                    val={pack.name}
                                    change={(e) => setPack(prevPack => ({...prevPack, name: e.target.value}))}
                                    ph="Csomag elnevezése"
                                    width="70%"
                                />

                                <textarea
                                    className={PackTicketsStyle.ops_tickets_comment}
                                    value={pack.comment}
                                    onChange={(e) => setPack(prevPack => ({...prevPack, comment: e.target.value}))}
                                    placeholder="Megjegyzés"
                                />

                                <div className={PackTicketsStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Leadás"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNext}
                                    />
                                </div>

                                <div className={PackTicketsStyle.ops_tickets_pack}>
                                    <div className={PackTicketsStyle.ops_tickets_head}>
                                        <span
                                            className={PackTicketsStyle.ops_tickets_text}
                                            style={{marginRight: "auto", fontSize: "16px", fontWeight: "700"}}
                                        >
                                            Csomag tartalma
                                        </span>

                                        <div className={PackTicketsStyle.ops_tickets_info}>
                                            <span className={PackTicketsStyle.info_quantity}>
                                                {tickets.length} <span>DB</span>
                                            </span>
                                            
                                            <span className={PackTicketsStyle.info_value}>
                                                <CurrencyText
                                                    val={calcSum()}
                                                /> <span>HUF</span>
                                            </span>
                                        </div>
                                    </div>

                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{textAlign: "center"}}>Profil</th>
                                                <th>Telephely</th>
                                                <th>Dátum</th>
                                                <th>Típus</th>
                                                <th>Sorozatszám</th>
                                                <th style={{textAlign: "center"}}>Nyereménykód</th>
                                                <th>Összeg</th>
                                                <th style={{textAlign: "center"}}>
                                                    <span>
                                                        Kijelölés
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>

                                        {tickets.length === 0 ?
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className={PackTicketsStyle.tickets_none}
                                                        colSpan={8}
                                                    >
                                                        Nem található ilyen sorsjegy
                                                    </td>
                                                </tr>
                                            </tbody>
                                        :
                                            <tbody>
                                                {tickets.map(item => {
                                                    return (
                                                        <TicketManage
                                                            key={item.id}
                                                            ticket={item}
                                                            onRemove={handleRemoveTicket}
                                                            initial={true}
                                                            selectAll={true}
                                                        />
                                                    );
                                                })}
                                            </tbody>
                                        }
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default PackTickets;