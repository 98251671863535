import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryStyle from "../Summary.module.css";

import CurrencyText from "../../currencytext/CurrencyText";

const SummaryCashOutSafe = ({ id, log, info }) => {
    const {setMsg, pcs,} = useContext(DataContext);

    const [logData, setLogData] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log) {
            getLog();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/safe/package/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
        return setLogData(data.pack);
    };

    const renderSafe = () => {
        if(log) {
            return (
                <tbody>
                    <tr>
                        <td>Rögzítés dátuma</td>
                        <th>{logData.date_of_record}</th>
                    </tr>
                    <tr>
                        <td>Szállítás dátuma</td>
                        <th>{logData.date_of_transit ? logData.date_of_transit : "Nincs"}</th>
                    </tr>
                    <tr>
                        <td>Beérkezés dátuma</td>
                        <th>{logData.date_of_cashin ? logData.date_of_cashin : "Nincs"}</th>
                    </tr>
                    <tr>
                        <td>Biztonsági tasak sorozatszáma</td>
                        <th>{logData.serial_num}</th>
                    </tr>
                    <tr>
                        <td>Értékszállító kódja</td>
                        <th>{logData.transit_id ? logData.transit_id : "Nincs"}</th>
                    </tr>
                    <tr>
                        <td>20 000 HUF</td>
                        <th>{logData.huf_20000_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>10 000 HUF</td>
                        <th>{logData.huf_10000_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>5 000 HUF</td>
                        <th>{logData.huf_5000_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>2 000 HUF</td>
                        <th>{logData.huf_2000_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>1 000 HUF</td>
                        <th>{logData.huf_1000_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>500 HUF</td>
                        <th>{logData.huf_500_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>200 HUF</td>
                        <th>{logData.huf_200_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>100 HUF</td>
                        <th>{logData.huf_100_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>50 HUF</td>
                        <th>{logData.huf_50_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>20 HUF</td>
                        <th>{logData.huf_20_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>10 HUF</td>
                        <th>{logData.huf_10_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>5 HUF</td>
                        <th>{logData.huf_5_pcs} DB</th>
                    </tr>
                    <tr>
                        <td>Összesen</td>
                        <th>
                            <CurrencyText
                                val={logData.sum}
                            /> HUF
                        </th>
                    </tr>
                </tbody>
            );
        }
        else {
            return (
                <tbody>
                    <tr>
                        <td>Biztonsági tasak sorozatszáma</td>
                        <th>{info}</th>
                    </tr>
                    <tr>
                        <td>20 000 HUF</td>
                        <th>{pcs.huf20000} DB</th>
                    </tr>
                    <tr>
                        <td>10 000 HUF</td>
                        <th>{pcs.huf10000} DB</th>
                    </tr>
                    <tr>
                        <td>5 000 HUF</td>
                        <th>{pcs.huf5000} DB</th>
                    </tr>
                    <tr>
                        <td>2 000 HUF</td>
                        <th>{pcs.huf2000} DB</th>
                    </tr>
                    <tr>
                        <td>1 000 HUF</td>
                        <th>{pcs.huf1000} DB</th>
                    </tr>
                    <tr>
                        <td>500 HUF</td>
                        <th>{pcs.huf500} DB</th>
                    </tr>
                    <tr>
                        <td>200 HUF</td>
                        <th>{pcs.huf200} DB</th>
                    </tr>
                    <tr>
                        <td>100 HUF</td>
                        <th>{pcs.huf100} DB</th>
                    </tr>
                    <tr>
                        <td>50 HUF</td>
                        <th>{pcs.huf50} DB</th>
                    </tr>
                    <tr>
                        <td>20 HUF</td>
                        <th>{pcs.huf20} DB</th>
                    </tr>
                    <tr>
                        <td>10 HUF</td>
                        <th>{pcs.huf10} DB</th>
                    </tr>
                    <tr>
                        <td>5 HUF</td>
                        <th>{pcs.huf5} DB</th>
                    </tr>
                    <tr>
                        <td>Összesen</td>
                        <th>
                            <CurrencyText
                                val={pcs.sum}
                            /> HUF
                        </th>
                    </tr>
                </tbody>
            );
        }
    };

    return (
        <table className={SummaryStyle.summary_table}>
            {renderSafe()}
        </table>
    );
};

export default SummaryCashOutSafe;