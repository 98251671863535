import StoreBubbleStyle from "./StoreBubble.module.css";

const StoreBubble = ({ text, colorCode, click }) => {
    return (
        <div
            className={StoreBubbleStyle.bubble}
            style={{background: colorCode}}
            onClick={click}
        >
            <span>{text}</span>
        </div>
    );
};

export default StoreBubble;