import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import PartnerStyle from "./Partner.module.css";

const Partner = ({ info }) => {
    const {setChoosenPart} = useContext(DataContext);
 
    const params = useParams();

    const navigate = useNavigate();

    const handleChoose = () => {
        setChoosenPart(info);
        navigate("/newbill/pay/"+params.store);
    };
    
    return (
        <tr className={PartnerStyle.partner_row}>
            <td style={{fontWeight: "600"}}>{info.name}</td>

            <td>{info.address}</td>

            <td>{info.bank_account}</td>

            <td>{info.tax_number}</td>

            <td>
                <button onClick={handleChoose}>Kiválaszt</button>
            </td>
        </tr>
    );
};

export default Partner;