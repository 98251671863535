import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";
import * as TrafficCalc from "../../../services/TrafficCalc";

import CloseVerifyStyle from "./CloseVerify.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Header from "../../../layouts/header/Header";
import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import DailyProgress from "../../../layouts/dailyprogress/DailyProgress";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const CloseVerify = () => {
    const {
        user, setUser, 
        stores, 
        traffic, 
        pcs, 
        msg, setMsg, 
        loading, setLoading, 
        homeRef
    } = useContext(DataContext);

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleVerify = () => {
        handleClose();
        handleSaveTrafficItemized();
        handleSaveTraffic();

        setUser(prevUser => ({...prevUser, store: null}));

        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            const store = stores.filter(s => s.name === params.store)[0];
            socket.emit("store_op", {id: store.id});

            setMsg({});
            navigate("/closelogout/"+params.store);
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleClose = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/daily", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                user_before: null,
                store: store.id,
                operation: 2,
                huf5: Number(pcs.huf5),
                huf10: Number(pcs.huf10),
                huf20: Number(pcs.huf20),
                huf50: Number(pcs.huf50),
                huf100: Number(pcs.huf100),
                huf200: Number(pcs.huf200),
                huf500: Number(pcs.huf500),
                huf1000: Number(pcs.huf1000),
                huf2000: Number(pcs.huf2000),
                huf5000: Number(pcs.huf5000),
                huf10000: Number(pcs.huf10000),
                huf20000: Number(pcs.huf20000),
                sum: Number(pcs.sum)
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
    };

    const handleSaveTrafficItemized = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/traffic/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id,
                traffic: traffic
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
    };

    const handleSaveTraffic = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/traffic/sum/save", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id,
                from: traffic[0].date,
                to: traffic[traffic.length-1].date,
                daily_sum: TrafficCalc.getTrafficSum(traffic),
                cash_in: TrafficCalc.getTrafficCashIn(traffic),
                cash_out: TrafficCalc.getTrafficCashOut(traffic),
                sum: (TrafficCalc.getTrafficSum(traffic) + TrafficCalc.getTrafficCashIn(traffic) - TrafficCalc.getTrafficCashOut(traffic)),
                return_sum: TrafficCalc.getReturnSum(traffic),
                canceled: 0,
                a_tax: TrafficCalc.getVatRateSum(traffic, 5),
                b_tax: TrafficCalc.getVatRateSum(traffic, 18),
                c_tax: TrafficCalc.getVatRateSum(traffic, 27),
                d_tax: TrafficCalc.getVatRateSum(traffic, 0),
                e_tax: 0
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
    };

    const handleBack = () => {
        setMsg({});
        navigate("/closepcs/"+params.store, {state: {back: true, corrItems: location.state.corrItems}});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                <OpsHeader
                    operation={2}
                    store={params.store}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={CloseVerifyStyle.progbar}>
                                <DailyProgress
                                    close={true}
                                    active={6}
                                />
                            </div>

                            <div className={CloseVerifyStyle.ops_dayclose_body}>
                                <span>Biztos elvégzed a nap zárást? A lezárást követően további műveletek nem adhatóak hozzá a naphoz!</span>
                                
                                <button onClick={handleVerify}>IGEN, LEZÁROK. VÉGE A NAPNAK.</button>
                                <button onClick={handleBack}>Mégsem</button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CloseVerify;