import { useContext, useEffect, useState } from "react";

import { DataContext } from "../../../context/DataContext";

import SummaryStyle from "../Summary.module.css";

import CurrencyText from "../../currencytext/CurrencyText";

const SummaryNewTicket = ({ id, log, info }) => {
    const {setMsg} = useContext(DataContext);

    const [logData, setLogData] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        if(log){
            getLog();
        }
    }, []);

    const getLog = async() => {
        const res = await fetch(apiUrl+"/partner/"+id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }
        return setLogData(data.partner);
    };

    return (
        <table className={SummaryStyle.summary_table}>
            <tbody>
                <tr>
                    <td style={{padding: "10px"}}>Megnevezés</td>
                    <th style={{padding: "10px"}}>{log ? logData.name : info.name}</th>
                </tr>
                <tr>
                    <td style={{padding: "10px"}}>Érték</td>

                    <th style={{padding: "10px"}}>
                        <CurrencyText
                            val={log ? logData.value : info.value}
                        /> HUF
                    </th>
                </tr>
                <tr>
                    <td style={{padding: "10px"}}>Főnyeremény</td>

                    <th style={{padding: "10px"}}>
                        <CurrencyText
                            val={log ? logData.prize : info.prize}
                        /> HUF
                    </th>
                </tr>
                <tr>
                    <td style={{padding: "10px"}}>Értékesítés utolsó napja</td>
                    <th style={{padding: "10px"}}>{log ? logData.last_sale_date : info.last_sale_date}</th>
                </tr>
                <tr>
                    <td style={{padding: "10px"}}>Nyeremény beváltásának utolsó napja</td>
                    <th style={{padding: "10px"}}>{log ? logData.last_cashout_date : info.last_cashout_date}</th>
                </tr>
            </tbody>
        </table>
    );
};

export default SummaryNewTicket;