import BubbleStyle from "./Bubble.module.css";

const Bubble = ({ text, mode }) => {
    const renderBubble = () => {
        if(mode === 1) {
            return (
                <div className={BubbleStyle.comment_bubble}>
                    {text}
                </div>
            );
        }
        else if(mode === 2) {
            return (
                <div className={BubbleStyle.profile_bubble}>
                    {text}
                </div>
            );
        }
    };

    return renderBubble();
};

export default Bubble;