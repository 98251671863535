import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import TicketPackRowStyle from "./TicketPackRow.module.css";

import MoreImg from "../../assets/body/ops_more.svg";

import Profile from "../profile/Profile";
import CheckBox from "../checkbox/CheckBox";
import Comment from "../comment/Comment";
import CurrencyText from "../currencytext/CurrencyText";

const TicketPackRow = ({ pack, initial, onChoose, onRemove, credit, selectAll }) => {
    const {setMsg} = useContext(DataContext);

    const [packInfo, setPackInfo] = useState([]);

    const [isOK, setIsOK] = useState(initial);

    const navigate = useNavigate();

    const params = useParams();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getPackInfo();
    }, []);

    useEffect(() => {
        if(selectAll) {
            setIsOK(true);
        }
        else {
            setIsOK(false);
        }
    }, [selectAll]);

    const handleOK = () => {
        if(isOK) {
            onRemove(pack);
        }
        else {
            onChoose(pack);
        }

        setIsOK(prevOK => !prevOK);  
    };

    const getPackInfo = async() => {
        const res = await fetch(apiUrl+"/tickets/manage/pack/info/"+pack.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPackInfo(data.pack);
    };

    const calcSum = () => {
        return packInfo.reduce((acc, object) => {
            return acc + object.prize_value;
        }, 0);
    };

    const handlePackItemized = () => {
        setMsg({});
        navigate("/tickets/package/"+pack.id+"/"+params.store, {state: {pack: pack, credit: credit, packed: true}});
    };

    const calcEditQuantity = () => {
        return packInfo.filter(p => p.bad_cashout).length;
    };

    const calcEditValue = () => {
        if(calcEditQuantity() !== 0) {
            return calcSum() - packInfo.filter(p => p.bad_cashout).reduce((acc, object) => {
                return acc + object.prize_value;
            }, 0);
        }
        else return 0;
    };

    const handleFilterBad = () => {
        setMsg({});
        navigate("/tickets/package/"+pack.id+"/"+params.store, {state: {pack: pack, credit: credit, packed: true, bad: true}});
    };

    return (
        <tr className={isOK ? TicketPackRowStyle.pack_row_active : TicketPackRowStyle.pack_row}>
            <td style={{textAlign: "center"}}>
                <img
                    src={MoreImg}
                    alt="More"
                    style={{cursor: "pointer"}}
                    onClick={handlePackItemized}
                />
            </td>

            <td>
                <Profile
                    color={pack.profile_color}
                    name={pack.last_name+" "+pack.first_name}
                    nameColor={"#A6A0A0"}
                    bubble={true}
                />
            </td>

            <td>
                {new Date(pack.date).toLocaleString("hu-HU", { year: "numeric", month: "2-digit", day: "2-digit" })}
            </td>

            <td style={{fontWeight: "600"}}>
                {pack.name}
            </td>

            <td>
                {packInfo.length} db
            </td>

            <td>
                <CurrencyText
                    val={calcSum()}
                /> <span>HUF</span>
            </td>

            <td>
                <div
                    className={TicketPackRowStyle.quantity_bubble}
                    onClick={handleFilterBad}
                >
                    <span>{calcEditQuantity()}</span>
                    <span>db</span>
                </div>
            </td>

            <td>
                <CurrencyText
                    val={calcEditValue()}
                /> <span>HUF</span>
            </td>

            <td style={{textAlign: "center"}}>
                <Comment
                    text={pack.comment}
                />
            </td>

            <td>
                <CheckBox
                    isChecked={isOK}
                    onCheck={handleOK}
                    style={{margin: "0 auto"}}
                />
            </td>
        </tr>
    );
};

export default TicketPackRow;