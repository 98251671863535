import { useState, createContext, useRef } from "react";

import ProfileImg from "../assets/header/profile.svg";
import OpenImg from "../assets/body/ops_dayopen.svg"
import CloseImg from "../assets/body/ops_dayclose.svg"
import ChangeImg from "../assets/body/ops_change.svg"
import BillImg from "../assets/body/ops_bill.svg";
import CashOutImg from "../assets/body/ops_cashout.svg";
import CashInImg from "../assets/body/ops_cashin.svg";
import PartnerImg from "../assets/body/ops_partner.svg";
import SafeImg from "../assets/body/ops_safe.svg";
import TicketImg from "../assets/body/ops_ticket.svg";
import TransitImg from "../assets/body/ops_transit.svg";
import StoreImg from "../assets/body/ops_store.svg";
import ManageImg from "../assets/body/ops_tickets_manage.svg";

export const DataContext = createContext();

export const StateProvider = ({ children }) => {
    const [user, setUser] = useState("");
    const [stores, setStores] = useState([]);

    const [icons] = useState([
        OpenImg,
        CloseImg,
        ChangeImg,
        [StoreImg, BillImg],
        [SafeImg, CashOutImg],
        [SafeImg, TransitImg],
        PartnerImg,
        SafeImg,
        [StoreImg, CashOutImg],
        [StoreImg, CashInImg],
        [TicketImg, BillImg],
        [TicketImg, CashOutImg],
        [TicketImg, CashInImg],
        [StoreImg, ProfileImg],
        [StoreImg, ProfileImg],
        [TicketImg, ManageImg],
        TicketImg,
        TicketImg
    ]);

    const [pcs, setPcs] = useState({
        huf5: "",
        huf10: "",
        huf20: "",
        huf50: "",
        huf100: "",
        huf200: "",
        huf500: "",
        huf1000: "",
        huf2000: "",
        huf5000: "",
        huf10000: "",
        huf20000: "",
        sum: 0
    });

    const [newBillStats, setNewBillStats] = useState({
        completion_date: "",
        payment_deadline: "",
        bill_serial_number: "",
        cash: "",
        amount: "",
        pay: ""
    });
    const [traffic, setTraffic] = useState([]);

    const [choosenPart, setChoosenPart] = useState();

    const [msg, setMsg] = useState({});
    const [loading, setLoading] = useState(false);
    const [close, setClose] = useState(true);

    const homeRef = useRef();

    const [search, setSearch] = useState("");
    const [showSearch, setShowSearch] = useState("");

    const [profile, setProfile] = useState({
        color: "#FFFFFF",
        rank: 0,
        lastName: "",
        firstName: "",
        nickName: "",
        birthPlace: "",
        birthDate: "",
        nameDay: "",
        zip: "",
        city: "",
        address: "",
        social: "",
        personalId: "",
        tax: "",
        bank1: "",
        bank2: "",
        bank3: "",
        email: "",
        medical: "",
        lungScreen: "",
        companyIn: ""
    });

    const value = {
        user,
        setUser,
        stores,
        setStores,
        icons,
        pcs,
        setPcs,
        choosenPart,
        setChoosenPart,
        newBillStats,
        setNewBillStats,
        traffic,
        setTraffic,
        msg,
        setMsg,
        loading, 
        setLoading,
        close,
        setClose,
        homeRef,
        search,
        setSearch,
        showSearch,
        setShowSearch,
        profile,
        setProfile
    };

    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    );
};