import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import TicketCreditPackStyle from "./TicketCreditPack.module.css";

import MoreImg from "../../assets/body/ops_more.svg";

import Profile from "../profile/Profile";
import Comment from "../comment/Comment";
import CurrencyText from "../currencytext/CurrencyText";

const TicketCreditPack = ({ pack, onCheck }) => {
    const {setMsg} = useContext(DataContext);

    const [userInfo, setUserInfo] = useState({});
    const [checkUser, setCheckUser] = useState({});
    const [packInfo, setPackInfo] = useState([]);
    const [packSum, setPackSum] = useState(0);

    const navigate = useNavigate();

    const params = useParams();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        getUserInfo();
        getCheckUser();
        getPackInfo();
        getCreditPackSum();
    }, [pack]);

    const getUserInfo = async() => {
        const res = await fetch(apiUrl+"/user/"+pack.user, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setUserInfo(data.user);
    };

    const getCheckUser = async() => {
        const res = await fetch(apiUrl+"/user/"+pack.checked_user, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setCheckUser(data.user);
    };

    const getPackInfo = async() => {
        const res = await fetch(apiUrl+"/tickets/creditpack/"+pack.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPackInfo(data.pack);
    };

    const getCreditPackSum = async() => {
        const res = await fetch(apiUrl+"/tickets/creditpack/sum/"+pack.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPackSum(data.sum);
    };

    const handlePackItemized = () => {
        setMsg({});
        navigate("/tickets/package/"+pack.id+"/"+params.store, {state: {pack: pack, credit: true, packed: true}});
    };

    const calcEditQuantity = () => {
        return packInfo.filter(p => p.bad_cashout).length;
    };

    const calcEditValue = () => {
        return calcEditQuantity() === 0 ? 0 : packSum - pack.left_value - packInfo.filter(p => p.bad_cashout).reduce((acc, object) => {
            return acc + object.prize_value;
        }, 0);
    };

    const renderChecked = () => {
        if(checkUser?.first_name) {
            return (
                <div className={TicketCreditPackStyle.checked}>
                    <Profile
                        color={checkUser.profile_color}
                        name={checkUser.first_name}
                        nameColor={"#A6A0A0"}
                        bubble={true}
                    />
                    <span>{new Date(pack.checked_date).toLocaleString("hu-HU", { year: "numeric", month: "2-digit", day: "2-digit" })}</span>
                </div>
                
            );
        }
        else {
            return (
                <button onClick={() => onCheck(pack)}>
                    Ellenőrizve
                </button>
            );
        }
    };

    const handleFilterBad = () => {
        setMsg({});
        navigate("/tickets/package/"+pack.id+"/"+params.store, {state: {pack: pack, credit: true, packed: true, bad: true}});
    };

    return (
        <tr className={TicketCreditPackStyle.pack_row}>
            <td style={{textAlign: "center"}}>
                <img
                    src={MoreImg}
                    alt="More"
                    style={{cursor: "pointer"}}
                    onClick={handlePackItemized}
                />
            </td>

            <td>
                <Profile
                    color={userInfo.profile_color}
                    name={userInfo.last_name+" s"+userInfo.first_name}
                    nameColor={"#A6A0A0"}
                    bubble={true}
                />
            </td>

            <td>
                {new Date(pack.date).toLocaleString("hu-HU", { year: "numeric", month: "2-digit", day: "2-digit" })}
            </td>

            <td style={{fontWeight: "600"}}>
                {pack.name}
            </td>

            <td>
                {packInfo.length} db
            </td>

            <td>
                <CurrencyText
                    val={packSum}
                /> <span>HUF</span>
            </td>

            <td>
                <div
                    className={TicketCreditPackStyle.quantity_bubble}
                    onClick={handleFilterBad}
                >
                    <span>{calcEditQuantity()}</span>
                    <span>db</span>
                </div>
            </td>

            <td>
                <CurrencyText
                    val={pack.left_value ? pack.left_value : "0"}
                /> <span>HUF</span>
            </td>

            <td>
                <CurrencyText
                    val={calcEditValue()}
                /> <span>HUF</span>
            </td>

            <td style={{textAlign: "center"}}>
                <Comment
                    text={pack.comment}
                />
            </td>

            <td style={{textAlign: "center"}}>
                {renderChecked()}
            </td>
        </tr>
    );
};

export default TicketCreditPack;