import { useState } from "react";

import BtnStyle from "./Btn.module.css";

const Btn = ({ text, click, bg, color, hoverColor, hoverBg }) => {
    const [hover, setHover] = useState(false);

    return (
        <button
            className={BtnStyle.btn}
            style={hover ? {color: hoverColor, background: hoverBg} : {color: color, background: bg}}
            onClick={click}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {text}
        </button>
    );
};

export default Btn;