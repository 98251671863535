// Egy adott szamla adatainak strukturalt (tablazatos) megjelenitese

import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import BillStyle from "./Bill.module.css";

import Profile from "../profile/Profile";
import Comment from "../comment/Comment";
import CurrencyText from "../currencytext/CurrencyText";

const Bill = ({ info, onDelete }) => {
    const {setMsg} = useContext(DataContext);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const handleModifyBill = () => {
        navigate("/bill/modify/"+params.store+"/"+info.id, {state: info});
    };

    const handleDeleteBill = async() => {
        const res = await fetch(apiUrl+"/bill/delete/"+info.id, {
            method: "DELETE"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet!"
        });
        
        onDelete();
    };

    return (
        <tr
            className={BillStyle.bill_row}
            style={Number(info.cash) === 1 ? {color: "#CF2F2F"} : {}}
        >
            <td>
                <Profile
                    color={info.profile_color}
                    name={info.last_name+" "+info.first_name}
                    nameColor={"#939393"}
                    bubble={true}
                />
            </td>
            <td>{info.payment_deadline}</td>
            <td style={{fontWeight: "600"}}>{info.name}</td>
            <td>{info.bank_account}</td>
            <td style={{fontWeight: "600", color: "#2E89CC"}}>
                <CurrencyText
                    val={info.amount}
                /> <span>HUF</span>
            </td>
            <td>{info.bill_serial_number}</td>
            <td>{Number(info.cash) === 1 ? "Készpénz" : "Átutalás"}</td>
            <td>
                <Comment
                    text={info.comment}
                />
            </td>
            <td style={{textAlign: "right"}}>
                <button onClick={handleModifyBill}>Szerkesztés</button>
                <button onClick={handleDeleteBill}>Törlés</button>
            </td>
        </tr>
    );
};

export default Bill;