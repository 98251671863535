import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import NewBillStyle from "../newbill/NewBill.module.css";
import HomeStyle from "../../home/Home.module.css";
import PartnersStyle from "../partners/Partners.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import SummaryBill from "../../../components/summary/summarybill/SummaryBill";
import Btn from "../../../components/btn/Btn";

const NewBillVerify = () => {
    const {
        user, 
        stores, 
        choosenPart, 
        newBillStats, 
        msg, setMsg, 
        loading, setLoading,
        homeRef
    } = useContext(DataContext);

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    const handleNewBill = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/store/newbill", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id,
                partner: choosenPart.id,
                cd: newBillStats.completion_date,
                pd: newBillStats.payment_deadline,
                bsn: newBillStats.bill_serial_number,
                cash: newBillStats.cash === "1",
                amount: newBillStats.amount,
                comment: newBillStats.comment
            })
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setMsg({});
        navigate("/bills/"+params.store, {state: {back: true}});
    };

    const handleBack = () => {
        navigate("/newbill/"+params.store+"/"+newBillStats.cash, {state: true});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"    
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                <OpsHeader
                    operation={4}
                    store={params.store}
                />
                
                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={PartnersStyle.ops_bill_body}>
                                <span className={NewBillStyle.ops_bill_text}>
                                    Biztosan felvezeted ezt a számlát?
                                </span>

                                <SummaryBill
                                    log={false}
                                    info={newBillStats}
                                    partner={choosenPart}
                                />

                                <div className={NewBillStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Véglegesítés"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNewBill}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default NewBillVerify;