import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import NewBillStyle from "./NewBill.module.css";
import HomeStyle from "../../home/Home.module.css";
import PartnersStyle from "../partners/Partners.module.css";
import PartnerStyle from "../../../components/partner/Partner.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import CashImg from "../../../assets/body/ops_bill_cash_1.svg";
import TransferImg from "../../../assets/body/ops_bill_transfer_1.svg";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Spinner from "../../../components/spinner/Spinner";
import Feedback from "../../../components/feedback/Feedback";
import Btn from "../../../components/btn/Btn";
import CurrencyInput from "../../../components/currencyinput/CurrencyInput";

const NewBill = () => {
    const {
        user, 
        choosenPart, 
        newBillStats, setNewBillStats, 
        loading, setLoading,
        msg, setMsg,
        homeRef
    } = useContext(DataContext);

    const [comment, setComment] = useState("");

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        if(!location.state) {
            setNewBillStats({
                completion_date: "",
                payment_deadline: "",
                bill_serial_number: "",
                amount: ""
            });
        }
        
        setLoading(false);
    }, []);

    const handleSaveBill = () => {
        if(Object.values(newBillStats).filter(prop => prop === "").length !== 0){
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        setNewBillStats(prevStat => ({...prevStat, comment: comment, cash: params.pay}));

        navigate("/newbill/verify/"+params.store);
    };

    const handleBack = () => {
        navigate("/newbill/pay/"+params.store, {state: params.pay});
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                <OpsHeader
                    operation={4}
                    store={params.store}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={PartnersStyle.ops_bill_body}>
                                <div className={NewBillStyle.ops_bill_choosen}>
                                    <div className={NewBillStyle.ops_partner_active}>
                                        <div
                                            className={PartnerStyle.ops_partner_icon}
                                            style={{color: choosenPart.color_code, borderColor: choosenPart.color_code}}
                                        >
                                            <span>{choosenPart.name[0]}</span>
                                        </div>

                                        <div className={NewBillStyle.ops_partner_texts}>
                                            <span>Cégnév</span>
                                            <span>{choosenPart.name}</span>
                                        </div>

                                        <div className={NewBillStyle.ops_partner_texts}>
                                            <span>Kedvezményezett számlaszáma</span>
                                            <span>{choosenPart.bank_account}</span>
                                        </div>

                                        <div className={NewBillStyle.ops_partner_pay}>
                                            <img
                                                src={CashImg}
                                                className={params.pay === "1" ? PartnerStyle.ops_bill_cash_choosen : PartnerStyle.ops_bill_cash}
                                                alt="Cash"
                                            />

                                            <img
                                                src={TransferImg}
                                                className={params.pay === "2" ? PartnerStyle.ops_bill_transfer_choosen : PartnerStyle.ops_bill_transfer}
                                                alt="Transfer"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={NewBillStyle.ops_bill_stats}>
                                    <div className={NewBillStyle.ops_bill_stat}>
                                        <span>Teljesítés dátuma:</span>

                                        <input
                                            type="date"
                                            className={NewBillStyle.ops_bill_stat_input}
                                            value={newBillStats.completion_date}
                                            onChange={(e) => setNewBillStats(prevStat => ({...prevStat, completion_date: e.target.value}))}
                                        />
                                    </div>

                                    <div className={NewBillStyle.ops_bill_stat}>
                                        <span>Fizetési határidő:</span>

                                        <input
                                            type="date"
                                            className={NewBillStyle.ops_bill_stat_input}
                                            value={newBillStats.payment_deadline}
                                            onChange={(e) => setNewBillStats(prevStat => ({...prevStat, payment_deadline: e.target.value}))}
                                        />
                                    </div>

                                    <div className={NewBillStyle.ops_bill_stat}>
                                        <span>Számla sorszáma:</span>

                                        <input
                                            type="text"
                                            className={NewBillStyle.ops_bill_stat_input}
                                            value={newBillStats.bill_serial_number}
                                            onChange={(e) => setNewBillStats(prevStat => ({...prevStat, bill_serial_number: e.target.value}))}
                                        />
                                    </div>

                                    <div className={NewBillStyle.ops_bill_stat}>
                                        <span>Fizetendő összeg:</span>

                                        <CurrencyInput
                                            val={newBillStats.amount}
                                            change={(val) => {
                                                if(!isNaN(+val)) {
                                                    setNewBillStats(prevStat => ({...prevStat, amount: val}))
                                                }
                                            }}
                                        />
                                    </div>
                                </div>

                                <textarea
                                    className={NewBillStyle.ops_bill_comment}
                                    placeholder="Megjegyzés"
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />

                                <div className={NewBillStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Mentés"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleSaveBill}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default NewBill;