import { useContext, useState } from "react";

import { DataContext } from "../../context/DataContext";

import TicketTrafficStyle from "./TicketTraffic.module.css";

import MoreImg from "../../assets/body/ops_more.svg";
import MinusImg from "../../assets/body/ops_minus.svg";

import CurrencyText from "../currencytext/CurrencyText";

const TicketTraffic = ({ row, tickets, onDelete, onEdit }) => {
    const {setMsg} = useContext(DataContext);

    const [edit, setEdit] = useState(false);
    const [newRow, setNewRow] = useState(row);

    const handleEdit = () => {
        if(newRow.type === 0 || newRow.quantity === ""){
            return setMsg({
                color: "#F58E8E",
                message: "Minden mező kitöltése kötelező"
            });
        }

        onEdit(row, newRow);
        setEdit(false);
    };

    const handleChange = (e) => {
        setNewRow(prevRow => ({
            ...prevRow,
            type: e.target.value,
            name: tickets.filter(t => t.id === Number(e.target.value))[0].name,
            value: tickets.filter(t => t.id === Number(e.target.value))[0].value
        }));
    };

    if(edit){
        return (
            <tr className={TicketTrafficStyle.ticket_row}>
                <td>
                    <select
                        value={newRow.type}
                        onChange={handleChange}
                    >
                        <option value={0}>Sorsjegy típusa</option>
                        
                        {tickets.map(t => {
                            return (
                                <option
                                    key={t.id}
                                    value={t.id}
                                >
                                    {t.name}
                                </option>
                            );
                        })}
                    </select>
                </td>
                <td></td>
                <td>
                    <input
                        type="text"
                        value={newRow.quantity}
                        onChange={(e) => setNewRow(prevRow => ({...prevRow, quantity: e.target.value}))}
                    />
                </td>
                <td></td>
                <td>
                    <img
                        src={MoreImg}
                        alt="More"
                        onClick={handleEdit}
                    />
                    <img
                        src={MinusImg}
                        alt="Minus"
                        onClick={() => onDelete(row)}
                    />
                </td>
            </tr>
        );
    }
    else {
        return (
            <tr className={TicketTrafficStyle.ticket_row}>
                <td>{row.name}</td>
                <td>
                    <CurrencyText
                        val={row.value}
                    /> HUF
                </td>
                <td>{row.quantity} DB</td>
                <td>
                    <CurrencyText
                        val={row.value*row.quantity}
                    /> HUF
                </td>
                <td>
                    <img
                        src={MoreImg}
                        alt="More"
                        onClick={() => setEdit(true)}
                    />
                    <img
                        src={MinusImg}
                        alt="Minus"
                        onClick={() => onDelete(row)}
                    />
                </td>
            </tr>
        );
    }
    
};

export default TicketTraffic;