const apiUrl = process.env.REACT_APP_API_URL;

export const getStoreStatus = async(id) => {
    const res = await fetch(apiUrl+"/store/status", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            store: id
        })
    });

    const data = await res.json();

    if(!data.success) return alert(data.message);

    return data.stat;
};

export const getUserOperations = async(id) => {
    const res = await fetch(apiUrl+"/user/operations", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            id: id
        })
    });

    const data = await res.json();

    if(!data.success) return alert(data.message);

    return data.ops;
};

export const getUserCompanies = async(id) => {
    const res = await fetch(apiUrl+"/user/companies/"+id, {
        method: "GET"
    });

    const data = await res.json();

    if(!data.success) return alert(data.message);

    return data.companies;
};

export const getUserStores = async(id) => {
    const res = await fetch(apiUrl+"/user/stores/"+id, {
        method: "GET"
    });

    const data = await res.json();

    return data.stores;
};

export const getUserCompaniesStores = async(id) => {
    const res = await fetch(apiUrl+"/company/stores/"+id, {
        method: "GET"
    });

    const data = await res.json();

    if(!data.success) return alert(data.message);

    return data.stores;
};