import { useState } from "react";

import TransitItemStyle from "./TransitItem.module.css";

import CheckedImg from "../../assets/body/ops_checked.svg";

const TransitItem = ({ serialNum, onChoose, onRemove }) => {
    const [checked, setChecked] = useState(false);

    const handleSelect = () => {
        setChecked(prevCheck => !prevCheck);
        onChoose();
    };

    const handleRemove = () => {
        setChecked(prevCheck => !prevCheck);
        onRemove();
    };

    const renderCheckbox = () => {
        if(checked) {
            return (
                <div
                    className={TransitItemStyle.transit_check_active}
                    onClick={handleRemove}
                >
                    <img
                        src={CheckedImg}
                        alt="Icon"
                    />
                </div>
            );
        }
        else {
            return (
                <div
                    className={TransitItemStyle.transit_check}
                    onClick={handleSelect}
                >
                </div>
            );
        }
    };

    return (
        <div className={TransitItemStyle.transit_item}>
            {renderCheckbox()}
            <span>{serialNum}</span>
        </div>
    );
};

export default TransitItem;