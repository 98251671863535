// Egy muvelet adatainak tablazatos megjelenitese (esemenynaplo -> logs)

import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../context/DataContext";

import LogItemStyle from "./LogItem.module.css";
import HomeStyle from "../../pages/home/Home.module.css";

import OpIcon from "../opicon/OpIcon";
import Profile from "../profile/Profile";
import StoreBubble from "../storebubble/StoreBubble";

const LogItem = ({ log }) => {
    const {homeRef, setMsg, stores} = useContext(DataContext);

    const params = useParams();

    const navigate = useNavigate();

    const handleGoToLog = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/log/"+params.store, {state: log});
        }, 400);
        return () => clearTimeout(timer);
    };

    return (
        <tr className={LogItemStyle.log_row}>
            <td>{log.date.split(" ")[0]}</td>
            <td>{log.date.split(" ")[1].split(":")[0]+":"+log.date.split(" ")[1].split(":")[1]}</td>
            <td style={{width: "100px"}}>
                <OpIcon
                    op={log}
                />
            </td>
            <td>{log.op_name}</td>
            <td>
                <StoreBubble
                    text={params.store}
                    colorCode={stores.filter(s => s.name === params.store)[0].color_code_2}
                />
            </td>
            <td>
                <Profile
                    color={log.profile_color}
                    name={log.last_name+" "+log.first_name}
                    nameColor={"#A6A0A0"}
                    bubble={true}
                />
            </td>
            <td style={{textAlign: "center"}}>
                <button onClick={handleGoToLog}>Megtekintes</button>
            </td>
        </tr>
    );
};

export default LogItem;