import TrafficOpsStyle from "./TrafficOpsTable.module.css";

import CurrencyText from "../currencytext/CurrencyText";

const TrafficOpsTable = ({ item, page }) => {
    return (
        <div className={TrafficOpsStyle.item}>
            <div
                className={TrafficOpsStyle.item_receipt}
                style={page === 1 ? {background: "#48D7A3"} : {background: "#F6525C"}}
            >
                {item.receipt_num_1}
            </div>

            <span className={TrafficOpsStyle.item_date}>
                {item.date.split(" ")[3]}
            </span>

            <div className={TrafficOpsStyle.item_value}>
                <CurrencyText
                    val={item.unit_value}
                />
                <span>HUF</span>
            </div>
        </div>
    );
};

export default TrafficOpsTable;