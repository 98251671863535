import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { io } from "socket.io-client";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import CreditPackTicketsStyle from "./CreditPackTickets.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import DefaultInput from "../../../components/defaultinput/DefaultInput";
import CurrencyInput from "../../../components/currencyinput/CurrencyInput";
import TicketPackRow from "../../../components/ticketpackrow/TicketPackRow";
import Btn from "../../../components/btn/Btn";
import CurrencyText from "../../../components/currencytext/CurrencyText";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const CreditPackTickets = () => {
    const {
        user,
        stores,
        homeRef,
        msg, setMsg,
        loading, setLoading,
    } = useContext(DataContext);

    const location = useLocation();

    const [packs, setPacks] = useState(location.state);
    const [packSum, setPackSum] = useState(0);

    const [creditPack, setCreditPack] = useState({
        name: "",
        comment: "",
        left: ""
    });

    const params = useParams();

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        setLoading(false);
    }, []);

    useEffect(() => {
        if(packs.length !== 0) {
            getPackSum();
        }
        else {
            setPackSum(0);
        }
    }, [packs]);

    const getPackSum = async() => {
        const res = await fetch(apiUrl+"/tickets/delivery/sum/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                packs: packs
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        if(data.sum) {
            return setPackSum(data.sum);
        }

        return setPackSum(0);
    };

    const handleRemovePack = (pack) => {
        setPacks(prevPacks => prevPacks.filter(item => item.id !== pack.id));
    };

    const handleBack = () => {
        setMsg({});
        navigate("/tickets/manage/wait/"+params.store);
    };

    const handleNext = async() => {
        if(packs.length === 0) {
            return setMsg({
                color: "#F58E8E",
                message: "Üres csomagot nem tudsz létrehozni"
            });
        }

        if(creditPack.name === "") {
            return setMsg({
                color: "#F58E8E",
                message: "Add meg a csomag nevét"
            });
        }

        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/tickets/manage/credit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id,
                store: store.id,
                packs: packs,
                creditPack: creditPack
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setMsg({
            color: "#C7FFEB",
            message: "Sikeres művelet"
        });
        
        socket.emit("credit_tickets", {id: store.company});

        navigate("/tickets/manage/cashout/"+params.store);
    };

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                <OpsHeader
                    operation={16}
                    store={params.store}
                />

                {loading ? 
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={HomeStyle.content_body}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={CreditPackTicketsStyle.ops_tickets_body}>
                                <span className={CreditPackTicketsStyle.ops_tickets_text}>
                                    Csomag befizetve
                                </span>

                                <DefaultInput
                                    val={creditPack.name}
                                    change={(e) => setCreditPack(prevPack => ({...prevPack, name: e.target.value}))}
                                    ph="Csomag elnevezése"
                                    width="70%"
                                />

                                <div className={CreditPackTicketsStyle.ops_tickets_left}>
                                    <CurrencyInput
                                        val={creditPack.left}
                                        change={(val) => setCreditPack(prevPack => ({...prevPack, left: val}))}
                                        width="25%"
                                        ph="Kimaradt összeg"
                                    />
                                </div>

                                <textarea
                                    className={CreditPackTicketsStyle.ops_tickets_comment}
                                    value={creditPack.comment}
                                    onChange={(e) => setCreditPack(prevPack => ({...prevPack, comment: e.target.value}))}
                                    placeholder="Megjegyzés"
                                />

                                <div className={CreditPackTicketsStyle.btns}>
                                    <Btn
                                        text="Vissza"
                                        bg="#FFFFFF"
                                        color="#7D7D7D"
                                        hoverBg="#DBDBDB"
                                        hoverColor="#7D7D7D"
                                        click={handleBack}
                                    />

                                    <Btn
                                        text="Leadás"
                                        bg="#2E89CC"
                                        color="#FFFFFF"
                                        hoverBg="#0D2646"
                                        hoverColor="#FFFFFF"
                                        click={handleNext}
                                    />
                                </div>

                                <div className={CreditPackTicketsStyle.ops_tickets_head}>
                                    <span
                                        className={CreditPackTicketsStyle.ops_tickets_text}
                                        style={{marginRight: "auto", fontSize: "16px", fontWeight: "700"}}
                                    >
                                        Csomag tartalma
                                    </span>

                                    <div className={CreditPackTicketsStyle.ops_tickets_info}>
                                        <span className={CreditPackTicketsStyle.info_quantity}>
                                            {packs.length} <span>DB</span>
                                        </span>
                                        
                                        <span className={CreditPackTicketsStyle.info_value}>
                                            <CurrencyText
                                                val={packSum}
                                            /> <span>HUF</span>
                                        </span>
                                    </div>
                                </div>

                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{textAlign: "center"}}>Részletek</th>
                                            <th>Leadta</th>
                                            <th>Befizetésre vár</th>
                                            <th>Csomag megnevezése</th>
                                            <th>Darabszám</th>
                                            <th style={{textAlign: "center"}}>Leadási érték</th>
                                            <th>Módosított tételek</th>
                                            <th>Módosított érték</th>
                                            <th>Megjegyzés</th>
                                            <th>Kijelölés</th>
                                        </tr>
                                    </thead>

                                    {packs.length === 0 ?
                                        <tbody>
                                            <tr>
                                                <td
                                                    className={CreditPackTicketsStyle.tickets_none}
                                                    colSpan={8}
                                                >
                                                    Nem található ilyen sorsjegy
                                                </td>
                                            </tr>
                                        </tbody>
                                    :
                                        <tbody>
                                            {packs.map(item => {
                                                return (
                                                    <TicketPackRow
                                                        key={item.id}
                                                        pack={item}
                                                        onRemove={handleRemovePack}
                                                        initial={true}
                                                        selectAll={true}
                                                    />
                                                );
                                            })}
                                        </tbody>
                                    }
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default CreditPackTickets;