import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { io } from "socket.io-client";

import { DataContext } from "../../context/DataContext";
import { getUserOperations, getUserStores } from "../../services/Api";

import StoreStyle from "./Store.module.css";
import HeaderStyle from "../../layouts/header/Header.module.css";
import HomeStyle from "../../pages/home/Home.module.css";

import LockImg from "../../assets/body/lock.svg";

import StorePic from "../storepic/StorePic";
import Profile from "../profile/Profile";

const socket = io.connect(process.env.REACT_APP_SOCKET_IO_URL);

const Store = ({ store }) => {
    const {user, setUser, homeRef, setMsg} = useContext(DataContext);

    const [stat, setStat] = useState({});
    const [beforeStat, setBeforeStat] = useState({});
    const [ops, setOps] = useState([]);

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        socket.emit("join", ["uid:"+user.id, "sid:"+store.id]);

        getStoreStatus();

        getUserOperations(user.id).then(data => {
            setOps(data);
        });
    }, []);

    useEffect(() => {
        socket.on("update_store", () => {
            getStoreStatus();
        });

        socket.on("update_perm", () => {
            getUserStores(user.id).then(stores => {
                setUser(prevUser => ({...prevUser, stores: stores}));
            });
        });
    }, [socket]);

    const getStoreStatus = async() => {
        const res = await fetch(apiUrl+"/store/status", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                store: store.name
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setStat(data.stat);

        if(data.stat.operation === 3){
            getStoreBeforeStatus();
        }
    };

    const getStoreBeforeStatus = async() => {
        const res = await fetch(apiUrl+"/store/status/before/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setBeforeStat(data.stat);
    };

    const handleToDayOpen = async() => {
        const res = await fetch(apiUrl+"/user/store", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                user: user.id
            })
        });

        const data = await res.json();

        if(!data.success) {
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        const perm = ops.filter(item => item.operation === 1);

        if(perm.length === 0) {
            return setMsg({
                color: "#F58E8E",
                message: "Nincs jogosultságod ehhez a művelethez"
            });
        }

        if(data.store !== null) {
            return setMsg({
                color: "#F58E8E",
                message: "Nem léphetsz be két boltba"
            });
        }

        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/dayopen/"+store.name);
        }, 400);
        return () => clearTimeout(timer);
    };

    const handleGoToStore = () => {
        homeRef.current.className = HomeStyle.content_body_exit;

        const timer = setTimeout(() => {
            setMsg({});
            navigate("/operations/"+store.name);
        }, 400);
        return () => clearTimeout(timer);
    };

    const storeStatus = () => {
        if(stat.operation === 1 || stat.operation === 3){
            if(user.store === stat.id){
                return (
                    <div className={StoreStyle.store_item}>
                        <StorePic
                            store={store}
                        />

                        <div className={StoreStyle.store_detail}>
                            <span className={StoreStyle.store_info}>
                                {store.address}
                            </span>
                            <div className={HeaderStyle.content_profile}>
                                <Link
                                    onClick={handleGoToStore}
                                    className={StoreStyle.store_open}
                                >
                                    BEJELENTKEZVE
                                </Link>
                            </div>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div className={StoreStyle.store_item} style={{opacity: "0.4"}}>
                        <StorePic
                            store={store}
                        />

                        <div className={StoreStyle.store_detail}>
                            <span className={StoreStyle.store_info}>
                                {store.address}
                            </span>

                            <Profile
                                color={stat.color}
                                name={stat.user}
                                beforeColor={beforeStat.color}
                                beforeName={beforeStat.user}
                            />
                        </div>
                    </div>
                );
            }
        }
        else if(stat.operation === 2){
            if(user.stores.filter(s => s.id === store.id).length === 0){
                return (
                    <div className={StoreStyle.store_item} style={{opacity: "0.4"}}>
                        <StorePic
                            store={store}
                        />

                        <div className={StoreStyle.store_detail}>
                            <span className={StoreStyle.store_info}>
                                {store.address}
                            </span>
                        </div>

                        <img
                            src={LockImg}
                            alt="Lock"
                        />
                    </div>
                );
            }
            else {
                if(user.store === null){
                    return (
                        <div className={StoreStyle.store_item}>
                            <StorePic
                                store={store}
                            />

                            <div className={StoreStyle.store_detail}>
                                <span className={StoreStyle.store_info}>
                                    {store.address}
                                </span>
                                <div className={HeaderStyle.content_profile}>
                                    <Link
                                        onClick={handleToDayOpen}
                                        className={StoreStyle.store_open}
                                    >
                                        NAP NYITÁS
                                    </Link>
                                </div>
                            </div>
                        </div>
                    );
                }
                else {
                    return (
                        <div className={StoreStyle.store_item} style={{opacity: "0.4"}}>
                            <StorePic
                                store={store}
                            />

                            <div className={StoreStyle.store_detail}>
                                <span className={StoreStyle.store_info}>
                                    {store.address}
                                </span>
                            </div>
                        </div>
                    );
                }
            }
        }
    };

    return storeStatus();
};

export default Store;