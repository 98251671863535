// Profil valasztasnal (muszakvaltas) egy profil komponense

import LoginProfileStyle from "./LoginProfile.module.css";

import Profile from "../profile/Profile";

const LoginProfile = ({ profile, onSelected, isActive }) => {
    const renderProfile = () => {
        if(isActive){
            return (
                <div
                    className={LoginProfileStyle.ops_change_profile_active}
                    onClick={() => onSelected(profile)}
                >
                    <Profile
                        color={profile.colorcode}
                        name={profile.name}
                        nameColor={"#A6A0A0"}
                    />
                </div>
            );
        }
        else {
            return (
                <div
                    className={LoginProfileStyle.ops_change_profile}
                    onClick={() => onSelected(profile)}
                >
                    <Profile
                        color={profile.colorcode}
                        name={profile.name}
                        nameColor={"#A6A0A0"}
                    />
                </div>
            );
        }
    };

    return (
        <>{renderProfile()}</>
    );
};

export default LoginProfile;