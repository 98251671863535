import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { DataContext } from "../../../context/DataContext";
import { getStoreStatus } from "../../../services/Api";

import ManageSafeStyle from "./ManageSafe.module.css";
import HomeStyle from "../../home/Home.module.css";
import OperationsStyle from "../../operations/Operations.module.css";

import Nav from "../../../layouts/nav/Nav";
import OpsHeader from "../../../layouts/opsheader/OpsHeader";
import Header from "../../../layouts/header/Header";

import SafeItem from "../../../components/safeitem/SafeItem";
import Feedback from "../../../components/feedback/Feedback";
import Spinner from "../../../components/spinner/Spinner";
import CurrencyText from "../../../components/currencytext/CurrencyText";

const ManageSafe = () => {
    const {user, stores, msg, setMsg, loading, setLoading, homeRef} = useContext(DataContext);

    const [packeges, setPackeges] = useState([]);

    const params = useParams();

    const navigate = useNavigate();

    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        setLoading(true);

        if(Object.keys(user).length === 0) return navigate("/home");

        getStoreStatus(params.store).then(store => {
            if(user.stores.filter(s => s.id === store.id).length === 0 || Number(store.operation) === 2) {
                return navigate("/home");
            }
        });

        getPackeges();

        setLoading(false);
    }, []);

    const getPackeges = async() => {
        const store = stores.filter(s => s.name === params.store)[0];

        const res = await fetch(apiUrl+"/safe/"+store.id, {
            method: "GET"
        });

        const data = await res.json();

        if(!data.success){
            return setMsg({
                color: "#F58E8E",
                message: data.message
            });
        }

        setPackeges(data.safe.filter(s => s.date_of_transit === null));
    };

    const calculateSum = () => {
        return packeges.reduce((accumulator, p) => accumulator + Number(p.sum), 0);
    }

    return (
        <div style={{width: "100%", height: "100%", display: "flex"}}>
            <Nav
                active="shop"
            />

            <div className={HomeStyle.content} style={{backgroundColor: "#FFFFFF"}}>
                <Header
                    user={user}
                    yearColor={"#747474"}
                    monthDayColor={"#747474"}
                    nameColor={"#A6A0A0"}
                />

                <OpsHeader
                    operation={8}
                    store={params.store}
                />

                {
                    Object.keys(msg).length !== 0 ?
                        <Feedback
                            color={msg.color}
                            message={msg.message}
                        />
                        :
                        <></>
                }

                {loading ?
                    <Spinner
                        color="#747474"
                    />
                :
                    <div
                        className={location.state?.back ? HomeStyle.content_body : HomeStyle.content_body_anim}
                        ref={homeRef}
                    >
                        <div className={OperationsStyle.op_content}>
                            <div className={ManageSafeStyle.ops_safe_body}>
                                <span className={ManageSafeStyle.ops_safe_text}>SZÉF TARTALMA</span>

                                <table>
                                    <thead>
                                        <tr>
                                            <th>Azonosító</th>
                                            <th>Csomag értéke</th>
                                            <th>Módosítva</th>
                                            <th>Módosította</th>
                                            <th>Megjegyzés</th>
                                            <th>Műveletek</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {packeges.map(item => {
                                            return (
                                                <SafeItem
                                                    key={item.id}
                                                    item={item}
                                                />
                                            );
                                        })}
                                    </tbody>
                                </table>

                                <div className={ManageSafeStyle.ops_safe_sum}>
                                    <span>Összesen</span>
                                    <span>
                                        <CurrencyText
                                            val={calculateSum()}
                                        /> HUF
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ManageSafe;